<template>
  <div id="box-editor" class="drop-zone">
    <div
      id="box-zone"
      ref="boxZone"
      :class="{
        landscape: box.aspectRatio > 1,
        portrait: box.aspectRatio <= 1,
      }"
      @drop="drop($event)"
      @dragover.prevent
      @dragenter.prevent
    >
      <img
        ref="box"
        id="box"
        :src="box.src"
        alt=""
        draggable="false"
        @mousedown="setNullTarget($event)"
      />
      <component
        v-for="(item, index) in currentBoxItems"
        :is="item.type"
        :key="index"
        @mousedown="setTarget($event, item.id, item.type)"
        @touchstart="setTarget($event, item.id, item.type)"
        :item="item"
        :ref="`${item.type}_${item.id}`"
        :style="[
          item.position,
          item.style,
          item.aspect,
          item.style,
          item.customStyle,
        ]"
        :class="[item.type, item.classes]"
        class="target"
      ></component>
    </div>

    <div id="item-order">
      <p id="order-label">Disposition</p>
      <item-order> </item-order>
    </div>
    <BoxItemEditor id="item-editor" v-if="selected.id != -1"></BoxItemEditor>
    <div v-if="selected.id == -1" id="item-selector">
      <p id="selector-label">Ajout d'item (<a href="http://bdkreator.com/bandedessinee_onomatopees/" target="_blank">Comprendre les onomatopées</a>)</p>
      <ItemSelector
        :items="boxItemsArray"
        :active="false"
        @addNewItem="createItem($event)"
      ></ItemSelector>
    </div>
    <!-- <button class="cta-button" @click="goBack()">retour</button> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Moveable from "moveable";
import Bubble from "../components/Bubble.vue";
import Appendice from "../components/Appendice.vue";
import Onomatopee from "../components/Onomatopee.vue";
import BoxItemEditor from "../components/BoxItemEditor.vue";
import ItemSelector from "../components/ItemSelector.vue";
import ItemOrder from "../components/ItemOrder.vue";
export default {
  components: {
    Bubble,
    Appendice,
    Onomatopee,
    BoxItemEditor,
    ItemSelector,
    ItemOrder,
  },
  data() {
    return {
      // TODO : move to props or store/editors
      defaultBox: {
        id: 9,
        id_board: 1,
        number: 9,
        width: 1423,
        height: 1414,
        pos_x: 2,
        pos_y: 2,
        size: 1,
        // src: "./exemples/Vignette-test-fond.png",
        src: "/exemples/seif-test.png",
      },
      moveable: null,
    };
  },
  created() {
    // this.unwatch = this.$store.watch(
    //   //(state, getters) =>  getters.selected,
    //   //state => state.editors,
    //   () => this.$store.getters['editors/selected'],
    //   (newValue)     => {
    //     if (newValue.type != "none") {
    //       console.log("store.watch - Select changed : " + JSON.stringify(newValue.controls))
    //       this.moveable.setState(
    //         {
    //           draggable: newValue.controls.draggable,
    //           resizable: newValue.controls.resizable,
    //           rotatable: newValue.controls.rotatable,
    //         }
    //       )
    //     }
    //   },
    //   {deep: true},
    // )

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // console.log(mutation.type);
      if (mutation.type === "editors/SET_CURRENT_SELECTED_CONTROLS_PROPERTY") {
        this.moveable.setState({
          draggable: this.selected.controls.draggable,
          resizable: this.selected.controls.resizable,
          rotatable: this.selected.controls.rotatable,
        });
      }
      if (mutation.type === "editors/CLEAR_ITEM_SELECTION") {
        // this.moveable.setState({
        //   target: null,
        // });
        this.moveable.target = null;
      }
      if (mutation.type === "editors/SET_CURRENT_SELECTED_OBJECT") {
        this.$nextTick(() => {
          // console.log(
          //   "Affecting to moveable ref : " +
          //     `${state.editors.currentSelectedBoxItem.type}_${this.selected.id}`,
          //   this.$refs[
          //     `${state.editors.currentSelectedBoxItem.type}_${this.selected.id}`
          //   ][0].$el
          // );
          this.moveable.setState({
            target:
              this.$refs[
                `${state.editors.currentSelectedBoxItem.type}_${this.selected.id}`
              ][0].$el,
            draggable: this.selected.controls.draggable,
            resizable: this.selected.controls.resizable,
            rotatable: this.selected.controls.rotatable,
          });
        });
      }
    });
  },
  mounted() {
    // console.log(data)

    // console.log(this.testBox);
    this.moveable = new Moveable(this.$refs.boxZone, {
      bounds: {
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        position: "css",
      },
      draggable: true,
      dragArea: true,
      snappable: true,
      resizable: "true",
      rotationPosition: "top",
      rotatable: true,
    })
      // TODO : gérer les fin d'évenement pour 'dispatcher' au store une action permettant de 'poster' les modifications
      .on("rotate", this.handleRotation)
      .on("drag", this.handleDrag)
      .on("resize", this.handleResize);

    this.moveable.setState({
      bounds: {
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        position: "css",
      },
    });

    // Test pour mettre à jour l'objet moveable si l'image est redimensionnée (pour l'instant ne fonctionne pas)
    // this.resizeObserver = new ResizeObserver(() => {
    //   //TODO : save moveable target, unset, wait next tick, set again :
    //   console.log("resized");
    //   const target = this.moveable.target;
    //   this.$nextTick(() => {
    //     this.moveable.setState({
    //       target: null,
    //     });
    //     this.moveable.setState({
    //       target: target,
    //     });
    //   });
    //   this.$nextTick(() => {});
    // });
    // this.resizeObserver.observe(this.$refs.box);
  },
  methods: {
    createItem: function (event, position = { top: "10%", left: "10%" }) {
      // console.log("Create new Item ", event);
      const payload = { ...event, position: position };
      this.$store.dispatch("editors/addNewItemToCurrentBox", payload);
    },
    setTarget(event, id, type) {
      if (event) {
        this.$store.dispatch("editors/selectObject", { id, type });
      }
      // console.log("Set target on ");
      // console.log(event.target);
    },
    setNullTarget() {
      this.$store.commit("editors/CLEAR_HIGHLIGHT");
      this.$store.commit("editors/CLEAR_ITEM_SELECTION");
    },

    /* Gestion des évements sur les items (drag, resize, rotate) */
    handleDrag({ target, left, top }) {
      //console.log("handleDrag left, top", left, top);
      target.style.left = `${left}px`;
      target.style.top = `${top}px`;

      let cHeight = this.$refs.box.clientHeight;
      let cWidth = this.$refs.box.clientWidth;
      let l = `${(left / cWidth) * 100}%`;
      let t = `${(top / cHeight) * 100}%`;
      //console.log("!!!! Dragging datas ", datas)
      // console.log("!!!! Dragging target ", target);

      this.$store.dispatch("editors/setCurrentSelectedPositionProperty", {
        property: "top",
        value: t,
      });
      this.$store.dispatch("editors/setCurrentSelectedPositionProperty", {
        property: "left",
        value: l,
      });
    },
    handleResize({ target, width, height, delta, drag }) {
      //TODO : mettre la taille dans le store et le laisser gérer la dimension % à la case
      delta[0] && (target.style.width = `${width}px`);
      delta[1] && (target.style.height = `${height}px`);
      delta[0] && (target.style.left = `${drag.left}px`);
      delta[1] && (target.style.top = `${drag.top}px`);

      let cWidth = this.$refs.box.clientWidth;
      let w = (width / cWidth) * 100;
      let cHeight = this.$refs.box.clientHeight;
      let h = (height / cHeight) * 100;
      if (delta[0]) {
        this.$store.dispatch("editors/setCurrentSelectedPositionProperty", {
          property: "width",
          value: `${w}%`,
        });
      }
      if (delta[1]) {
        this.$store.dispatch("editors/setCurrentSelectedPositionProperty", {
          property: "height",
          value: `${h}%`,
        });
      }
      this.$store.dispatch("editors/setCurrentSelectedAspectProperty", {
        property: "aspectRatio",
        value: w / h,
      });
      this.handleDrag({ target: target, left: drag.left, top: drag.top });
    },

    handleRotation({ /*target,*/ beforeRotate }) {
      this.$store.dispatch("editors/setCurrentSelectedPositionProperty", {
        property: "transform",
        value: `rotate(${beforeRotate}deg)`,
      });
    },
    drop(e) {
      // console.log("drop", e);
      const action = e.dataTransfer.getData("action");
      if (!action || action !== "createNewItem") {
        return;
      }
      let cHeight = this.$refs.box.clientHeight;
      let cWidth = this.$refs.box.clientWidth;
      let cLeft = this.$refs.box.getClientRects()[0].x;
      let cTop = this.$refs.box.getClientRects()[0].y;
      const deltaX = parseInt(e.dataTransfer.getData("deltaX"));
      const deltaY = parseInt(e.dataTransfer.getData("deltaY"));

      let left = e.clientX + deltaX - cLeft;
      let top = e.clientY + deltaY - cTop;
      let l = `${(left / cWidth) * 100}%`;
      let t = `${(top / cHeight) * 100}%`;
      const position = { top: t, left: l };

      const type = e.dataTransfer.getData("type");
      const id = e.dataTransfer.getData("id");
      // console.debug("----------------- Debug drop position ----------------");

      // console.debug("box(top, left): ", cTop, cLeft);
      // console.debug("Item computed(top, left): ", e.clientY, e.clientX);
      // console.debug("Item computed(top, left): ", top, left);
      // console.debug("Item computed(top, left)%", position);
      // console.debug("Drop event", e);

      const payload = { type: type, id: id };

      this.createItem(payload, position);
    },
    goBack() {
      this.$router.push({ name: "ComicBoxesOrder" });
    },
  },
  computed: {
    ...mapGetters("editors", {
      // testBox: "currentBox",
      selected: "selectedBoxItem",
      currentBoxItems: "currentBoxItems",
    }),
    ...mapGetters("staticResources", {
      onomatopees: "onomatopees",
      bubbles: "bubbles",
      appendices: "appendices",
      // boxItemsArray: "boxItemsArray",
      boxItemsArray: "typedBoxItems",
    }),
    box() {
      return this.$store.getters["editors/currentBox"] ?? this.defaultBox;
    },
  },
  beforeDestroy() {
    if (this.moveable) this.setNullTarget();
    this.unsubscribe();
  },
};
</script>
<style></style>
<style scoped>
#box-editor {
  display: flex;
  flex-direction: row;
  amax-width: 90vw;
  abackground: #222;
  min-height: calc(100vh - 67px);
  height: calc(100vh - 67px);
  max-height: calc(100vh - 67px);
}

#box-zone {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
  awidth: -moz-fit-content;
  height: fit-content;
  aheight: -moz-fit-content;
  /* width: 70%; */
  position: relative;
  amax-width: calc(80vw - 320px);
  amax-height: calc(100vh - 320px);
  margin: 40px;
  border-radius: 20px;
  box-shadow: 5px 5px 50px #000000;
}

#box {
  max-width: 100%;
  max-height: 100%;
}
#box-zone.portrait {
  max-height: calc(100vh - 200px);
}
#box-zone.portrait #box {
  height: inherit;
  max-height: inherit;
  min-height: inherit;
}
#box-zone.landscape {
  max-width: calc(80vw - 200px);
  max-height: calc(100vh - 200px);
}
#box-zone.landscape #box {
  width: auto;
  height: auto;
  max-width: inherit;
  min-width: inherit;
  max-height: inherit;
}

#item-editor {
  max-height: 100%;
  width: 20%;
}

#item-order {
  min-width: 160px; /* TODO : ajuster. Pour l'instant, on gère dans ce container. Passer en % ? */
  width: 160px; /* TODO : ajuster. Pour l'instant, on gère dans ce container. Passer en % ? */
  /* height: 100%; */
  background: #111;
}

#item-selector {
  awidth: 20%;
  max-width: 40%;
  /* height: 100%; */
}

#order-label,
#selector-label {
  text-align: center;
  padding: 8px;
}
#order-label {
  color: white;
}
</style>
