<template>
  <div
    class="bubble"
    :style="[bgImgStyle]"
    @mousedown="$emit('mousedown', $event)"
    @touchstart="$emit('touchstart', $event)"
    @dblclick="$emit('dblclick', $event)"
  >
    <div class="bubble-text" :style="[textPosition, textStyle]" ref="display">
      {{ item.content }}
    </div>
  </div>
</template>
<script>
// import Moveable from "vue-moveable";
export default {
  // props: ["item", "textStyle"],
  props: {
    textStyle: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {
        return { src: "" };
      },
    },
  },
  watch: {
    item: {
      handler: function (oldVal, newVal) {
        if (oldVal.content != newVal.content) {
          this.changeFontSize();
        }
      },
      deep: true,
    },
    textStyle: {
      handler: function (oldVal, newVal) {
        if (oldVal != newVal) {
          this.changeFontSize();
        }
      },
      deep: true,
    },
  },
  components: {
    // Moveable,
  },
  data() {
    return {
      bubble: {
        id: 1,
        x: 10,
        y: 10,
        width: 100,
        height: 100,
        src: "./exemples/bulles/bulle-ronde.png",
        text: {
          id: 1,
          id_box: 1,
          id_bubble: 1,
          width: 68,
          height: 68,
          left: 16,
          top: 16,
          x: 16,
          y: 16,
          content: "Texte original de la première bulle",
        },
      },
      resizeObserver: null,
    };
  },
  methods: {
    changeFontSize() {
      //TODO: debounce
      // requestAnimationFrame(async () => {
      const el = this.$refs.display;
      // this.$nextTick(function(){

      const minFontSize = 0.5;
      const maxFontSize = 500;
      const incrementionRate = 0.5;

      // TODO : ajuster l'overflow en fonction du format (height sur paysage, width sur portrait) ??
      let isOverflowing = () =>
        // replaced clientWidth by getBoundingClientRect() (when highlighted)
        el.scrollWidth - el.clientWidth > 1 ||
        el.scrollHeight - el.clientHeight > 1;
      // el.scrollWidth - el.getBoundingClientRect().width > 1 ||
      // el.scrollHeight - el.getBoundingClientRect().height > 1;

      // console.log(
      //   "el.scrollWidth: " + el.scrollWidth
      //   + " el.clientWidth: " + el.clientWidth
      //   + " el.scrollHeight: " + el.scrollHeight
      //    + " el.clientHeight: " + el.clientHeight
      //   )

      let currentFontSize = parseFloat(
        window
          .getComputedStyle(el, null)
          .getPropertyValue("font-size")
          .slice(0, -2)
      );
      // console.log("fontSize : " + currentFontSize);
      while (!isOverflowing() && currentFontSize < maxFontSize) {
        // console.log("Not OverFlowing");
        currentFontSize += incrementionRate;
        let size = currentFontSize + "px";
        // console.log(size);
        el.style.fontSize = size;
        //await this.$nextTick();
      }
      while (isOverflowing() && currentFontSize > minFontSize) {
        // console.log("OverFlowing");
        currentFontSize -= incrementionRate;
        let size = currentFontSize + "px";
        // console.log(size);
        el.style.fontSize = size;
        //await this.$nextTick();
      }
    },
    htmlEntities: function (str) {
      return String(str)
        .replace(/&/g, "&")
        .replace(/</g, "<")
        .replace(/>/g, ">")
        .replace(/"/g, '"');
    },
  },
  computed: {
    textPosition() {
      if (this.item.textarea) {
        return this.item.textarea;
      }
      return {
        position: "absolute",
        top: (this.bubble.text.y / this.bubble.height) * 100 + "%",
        left: (this.bubble.text.x / this.bubble.width) * 100 + "%",
        width: (this.bubble.text.width / this.bubble.width) * 100 + "%",
        height: (this.bubble.text.height / this.bubble.height) * 100 + "%",
      };
    },
    bgImgStyle() {
      const url = this.item.src ? this.item.src : this.bubble.src;
      return {
        "background-image": "url(" + url + ")",
      };
    },
    textContent() {
      const txt = this.item.content ? this.item.content : this.bubble.content;
      return txt;
    },
  },
  mounted() {
    // Ajout d'un observer sur le dom pour voir si l'image est redimensionnée : (voir Uno)
    this.resizeObserver = new ResizeObserver(this.changeFontSize);
    this.resizeObserver.observe(this.$el);

    this.$nextTick(() => {
      this.changeFontSize(this.$el);
    });
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  unmounted() {
    this.resizeObserver.disconnect();
  },
};
</script>
<style scoped>
*,
.bubble,
.target {
  position: absolute;
}
.bubble-text {
  /* z-index: 10; */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  text-align: center;
}
.bubble {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  /* display: inline-block; */
  /* border-color: transparent;
  border-style: solid; */
  background-origin: border-box;
  background-clip: border-box;
  /* Impossible de faire fonctionner vertical-align middle */
  /* display: inline;
  vertical-align: middle; */
}

/** Pour alignement vertical du texte : https://stackoverflow.com/a/18966255 */
.bubble {
  /* W3C */
  /* display:box;
  box-pack:center;
  box-align:center; */

  /* Internet Explorer 10 */
  /* display:-ms-flexbox;
  -ms-flex-pack:center;
  -ms-flex-align:center; */

  /* Firefox */
  /* display:-moz-box;
  -moz-box-pack:center;
  -moz-box-align:center; */

  /* Safari, Opera, and Chrome */
  /* display:-webkit-box;
  -webkit-box-pack:center;
  -webkit-box-align:center; */
}

/* Test avec un display flex, mais je crains le pire sur l'ajout de text  => ça à l'air de tenir. */
/* TODO : choisir entre cette maéthode et celle de dessus */
.bubble-text {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
</style>
