<template>
  <div class="component">
    <h1 class="h1">Gestion des utilisateurs</h1>
    <table>
      <tr>
        <td>
          <input
            class="input"
            type="text"
            placeholder="Nom de l'utilisateur"
            v-model="newUser.pseudo"
          />
        </td>
        <td>
          <input
            class="input"
            type="text"
            placeholder="Mot de passe"
            v-model="newUser.password"
          />
        </td>
        <td>
          <button class="button" @click="createUser">Créer</button>
        </td>
      </tr>
    </table>
    <table>
      <thead>
        <tr>
          <th>Utilisateur</th>
          <th>Rôle</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.pseudo }}</td>
          <td>{{ user.role }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import API from "@/api/api";
export default {
  name: "UsersAdministration",
  data() {
    return {
      newUser: {
        pseudo: "",
        password: "",
      },
      users: [],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    createUser() {
      API.createAnimator(this.newUser.pseudo, this.newUser.password).then(
        (payload) => {
          console.log(payload);
          this.getUsers();
        }
      );
    },
    getUsers() {
      API.getUsers().then((payload) => {
        this.users.splice(0, this.users.length, ...payload);
      });
    },
  },
};
</script>

<style scoped>
.component {
  color: #333;
  font-family: "Roboto Light", Arial, sans-serif;
  margin: 20px;
}
.component .h1 {
  margin: 20px 0;
}
table {
  min-width: 800px;
  width: 90%;
  border-spacing: 0;
}
table th {
  text-align: left;
  background: #ddd;
  color: #555;
  padding: 10px;
}
table td {
  text-align: left;
  color: #555;
  padding: 10px;
}
</style>
