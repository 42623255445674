import resources from "../../api/resources-norm";
import comics from "../../api/comics"; //TODO : experimental
import Vue from "vue";

const defaultsBoxItems = {
  bubble: {
    id_box: -1,
    id_reference: -1,
    controls: {
      rotatable: true,
      draggable: true,
      resizable: true,
    },
    style: {
      /* fontFamily: "", */
      fontStyle: "normal",
      textDecoration: "none",
      fontWeight: "normal",
      color: "black",
    },
    position: {
      top: "0%",
      left: "0%",
      width: "40%",
      height: "10%",
      transform: "",
    },
    aspect: {
      aspectRatio: 2,
    },
    content: "",
  },
  onomatopee: {
    id_box: -1,
    id_reference: -1,
    controls: {
      draggable: true,
      rotatable: true,
      resizable: true,
    },
    position: {
      top: "0%",
      left: "0%",
      width: "30%",
      height: "7.5%",
      transform: "",
    },
    aspect: {
      aspectRatio: "auto",
    },
  },
  appendice: {
    id_box: -1,
    id_reference: -1,
    controls: {
      draggable: true,
      rotatable: true,
      resizable: true,
      transform: "",
    },
    position: {
      top: "0%",
      left: "0%",
      width: "15%",
      height: "20%",
    },
    aspect: {
      aspectRatio: "auto",
    },
  },
};

const state = () => ({
  comics: {},
  boxes: {},
  bubbles: {},
  onomatopees: [],
  appendices: {},
  layouts: [],
  fonts: [],
  themes: {},
});

const getters = {
  defaultsBoxItems: () => {
    return defaultsBoxItems;
  },
  comics: (state) => {
    //TODO : experimental
    return resources.denormalize(state.comics);
  },
  boxes: (state) => {
    //TODO : experimental
    return resources.denormalize(state.boxes);
  },
  bubbles: (state) => {
    return resources.denormalize(state.bubbles);
  },
  onomatopees: (state) => {
    return resources.denormalize(state.onomatopees);
  },
  appendices: (state) => {
    return resources.denormalize(state.appendices);
  },
  /**
   * Renvoi tous les items "vierges" (bubbles, appendices, onomatopees)
   * @param {*} state
   */
  boxItemsArray: (state) => {
    return getters
      .bubbles(state)
      .concat(getters.appendices(state))
      .concat(getters.onomatopees(state));
  },
  typedBoxItems: (state) => { // TODO : rendre ça un peu plus générique 
      return [
          {type: 'bubble', items: getters.bubbles(state), description:"bulles"}, 
          {type: 'appendices', items: getters.appendices(state), description:"appendices"}, 
          {type: 'onomatopeesPunct', items: getters.onomatopees(state).filter(i => i.lang=='ponctuation'), description: "Onomatopées : ponctuation"},
          {type: 'onomatopeesFr', items: getters.onomatopees(state).filter(i => i.lang=='francais'), description: "Onomatopées : français"},
          {type: 'onomatopeesIt', items: getters.onomatopees(state).filter(i => i.lang=='italien'), description: "Onomatopées : italien"},
        ]
  },
  layouts: (state) => {
    return resources.denormalize(state.layouts);
  },
  themes: (state) => {
    return resources.denormalize(state.themes);
  }
};

const actions = {
  loadBubbles({ commit }) {
    resources.loadBubbles((bubbles) => {
      commit("setBubbles", bubbles);
    });
  },
  loadAppendices({ commit }) {
    resources.loadAppendices((appendices) => {
      commit("setAppendices", appendices);
    });
  },
  loadOnomatopees({ commit }) {
    resources.loadOnomatopees((onomatopees) => {
      commit("setOnomatopees", onomatopees);
    });
  },
  loadFonts({ commit }) {
    resources.loadFonts((fonts) => {
      commit("setFonts", fonts);
    });
  },
  loadLayouts({ commit }) {
    resources.loadLayouts((layouts) => {
      commit("setLayouts", layouts);
    });
  },
  loadBoxes({ commit }) {
    //TODO : experimental
    comics.loadBoxes((boxes) => {
      commit("setBoxes", boxes);
    });
  },
  loadComics({ commit }) {
    comics.loadComics((comics) => {
      commit("setComics", comics);
    });
    // console.log(commit);
  },
  loadThemes({ commit }) {
    comics.loadThemes((themes) => {
      commit("setThemes", themes);
    })
  },
};

const mutations = {
  setBoxes(state, boxes) {
    //TODO : experimental
    Vue.set(state, "boxes", boxes);
  },
  setBubbles(state, bubbles) {
    //state.bubbles = { ...bubbles };
    Vue.set(state, "bubbles", bubbles);
  },
  setAppendices(state, appendices) {
    state.appendices = appendices;
  },
  setOnomatopees(state, onomatopees) {
    state.onomatopees = onomatopees;
  },
  setFonts(state, fonts) {
    state.fonts = fonts;
  },
  setLayouts(state, layouts) {
    Vue.set(state, "layouts", layouts);
  },
  setComics(state, comics) {
    Vue.set(state, "comics", comics);
  },
  setThemes(state, themes) {
    Vue.set(state, "themes", themes);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
