<template>
  <div
    class="appendice"
    :style="[bgImgStyle, computedStyle]"
    @mousedown="$emit('mousedown', $event)"
    @touchstart="$emit('touchstart', $event)"
    @dblclick="$emit('dblclick', $event)"
  >
    &nbsp;
  </div>
</template>
<script>
// import Moveable from "vue-moveable";
export default {
  props: {
    computedStyle: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {
        return { src: "" };
      },
    },
  },
  components: {
    // Moveable,
  },
  data() {
    return {
      dataItem: this.item ?? {
        id: 1,
        x: "10%",
        y: "10%",
        width: "5%",
        height: "5%",
        src: "/exemples/bulles/appendices/appendice-rond.png",
        resizeObserver: null,
      },
    };
  },
  computed: {
    bgImgStyle() {
      const url = this.item.src ? this.item.src : this.dataItem.src;
      return {
        "background-image": "url(" + url + ")",
      };
    },
  },
};
</script>
<style scoped>
.appendice {
  position: absolute;
  /* z-index: 20; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  background-origin: border-box;
  background-clip: border-box;
}
</style>
