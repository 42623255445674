<template>
  <draggable v-model="items" id="item-list" chosenClass="">
    <div
      class="item-container"
      :class="[item.type, item.classes]"
      v-for="item in items"
      :key="`${item.type}${item.id}`"
      :style="maxHeight"
      @mousedown="selectItem($event, item.id, item.type)"
      @touchstart="selectItem($event, item.id, item.type)"
    >
      <component
        :is="item.type"
        :item="item"
        :style="[item.style, item.customStyle]"
        :class="item.type"
        class="item"
      ></component>
    </div>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import Bubble from "./Bubble.vue";
import Appendice from "./Appendice.vue";
import Onomatopee from "./Onomatopee.vue";

export default {
  components: {
    draggable,
    Bubble,
    Appendice,
    Onomatopee,
  },
  computed: {
    items: {
      get() {
        return this.$store.getters["editors/currentBoxItemsSorted"];
      },
      set(value) {
        // console.log("***** DragEnd value : ", value);
        this.$store.dispatch(
          "editors/updateCurrentBoxItemOrder",
          value.reverse()
        );
      },
    },
    maxHeight() {
      return { height: 100 / (this.items.length * 2) + "%" };
    },
  },
  methods: {
    selectItem(event, id, type) {
      if (event) {
        this.$store.dispatch("editors/selectObject", { id, type });
      }
    },
  },
};
</script>
<style scoped>
#item-list {
  display: flex;
  flex-direction: column; /* ATTENTION ne surtout pas utiliser column reverse : https://github.com/SortableJS/Vue.Draggable/issues/604 https://github.com/SortableJS/Sortable/issues/1447 */
  align-items: center;
  /* width: 100px; /* TODO: Pour l'instant */
  /* height est géré par l'éditeur qui le contient */
  height: calc(100% - 20px); /* TODO : pour l'instant */
  ajustify-content: space-evenly;
  padding: 10px 0;
}
.item-container {
  width: 80%;
  max-height: 80px;
  background: #eee;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #555;
  border-radius: 5px;
  margin-bottom: 10px;
  /* flex-grow: 1;
  height: 100%; */
}

.item-container.bubble .item {
  height: 80%;
}

.item-container.appendice {
  height: 100%;
  awidth: auto;
}

.item-container.highlight {
  border-color: #e85e1c;
  background: #f5aaaa;
}

.item-container.appendice .item {
  background-position: center;
  background-size: auto 100%;
  height: 80%;
  aspect-ratio: 1;
}

.item-container.onomatopee {
  /* width:100%; */
}

.item-container.onomatopee .item {
  /* width:100%; */
  height: 80%;
}
.item {
  /* position: relative !important; */
  /*
    width: max-content !important;
    height: auto !important;
    top:0% !important;
    left: 0% !important;
  */

  /* max-height: var(--maxHeight); */
  height: inherit;
  width: inherit;
  max-height: 40px;
  /* width: 100px; */ /*TODO : pour l'instant obligé de width sur item  et pas sur le conteneur sinon ça ne fonctionne pas => à cuase de column-reverse */
}
/* .item.bubble{
  width:100%;
  height: 100%;

} */

.highlight .item {
  box-shadow: 3px yellow;
}
</style>
