var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drop-zone",attrs:{"id":"box-editor"}},[_c('div',{ref:"boxZone",class:{
      landscape: _vm.box.aspectRatio > 1,
      portrait: _vm.box.aspectRatio <= 1,
    },attrs:{"id":"box-zone"},on:{"drop":function($event){return _vm.drop($event)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('img',{ref:"box",attrs:{"id":"box","src":_vm.box.src,"alt":"","draggable":"false"},on:{"mousedown":function($event){return _vm.setNullTarget($event)}}}),_vm._l((_vm.currentBoxItems),function(item,index){return _c(item.type,{key:index,ref:`${item.type}_${item.id}`,refInFor:true,tag:"component",staticClass:"target",class:[item.type, item.classes],style:([
        item.position,
        item.style,
        item.aspect,
        item.style,
        item.customStyle,
      ]),attrs:{"item":item},on:{"mousedown":function($event){return _vm.setTarget($event, item.id, item.type)},"touchstart":function($event){return _vm.setTarget($event, item.id, item.type)}}})})],2),_c('div',{attrs:{"id":"item-order"}},[_c('p',{attrs:{"id":"order-label"}},[_vm._v("Disposition")]),_c('item-order')],1),(_vm.selected.id != -1)?_c('BoxItemEditor',{attrs:{"id":"item-editor"}}):_vm._e(),(_vm.selected.id == -1)?_c('div',{attrs:{"id":"item-selector"}},[_vm._m(0),_c('ItemSelector',{attrs:{"items":_vm.boxItemsArray,"active":false},on:{"addNewItem":function($event){return _vm.createItem($event)}}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{attrs:{"id":"selector-label"}},[_vm._v("Ajout d'item ("),_c('a',{attrs:{"href":"http://bdkreator.com/bandedessinee_onomatopees/","target":"_blank"}},[_vm._v("Comprendre les onomatopées")]),_vm._v(")")])
}]

export { render, staticRenderFns }