const API_URL = "/api";

function signin(login, password) {
  const payload = new FormData();
  payload.append("login", login);
  payload.append("password", password);
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/signin.php`, {
      method: "POST",
      credentials: "include",
      body: payload,
    })
      .then((response) => {
        console.log("API", response.status);
        if (response.status > 299) {
          reject("problem");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        resolve(data);
        // console.log(data);
      })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
}

function joinSession(sessionName, sessionCode, pseudo) {
  const payload = new FormData();
  payload.append("pseudo", pseudo);
  payload.append("cle", sessionCode);
  payload.append("session", sessionName);
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/join.php`, {
      method: "POST",
      credentials: "include",
      body: payload,
    })
      .then((response) => {
        // console.log("API", response.status);
        if (response.status > 299) {
          reject("problem");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        resolve(data);
        // console.log(data);
      })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
}
function getSessions() {
  return new Promise((resolve) => {
    fetch(`${API_URL}/sessions.php`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      });
  });
}

function getCurrentSessions() {
  return new Promise((resolve) => {
    fetch(`${API_URL}/current_sessions.php`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      });
  });
}

function createSession(session) {
  const payload = new FormData();
  payload.append("name", session.name);
  payload.append("cle", session.cle);
  payload.append("begin_date", session.beginDate);
  payload.append("begin_hour", session.beginHour);
  payload.append("end_date", session.endDate);
  payload.append("end_hour", session.endHour);
  payload.append("printable", session.printable);
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/create_session.php`, {
      method: "POST",
      credentials: "include",
      body: payload,
    })
      .then((response) => {
        // console.log("API", response.status);
        if (response.status > 299) {
          reject("problem");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        resolve(data);
        // console.log(data);
      })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
}

function getUsers() {
  return new Promise((resolve) => {
    fetch(`${API_URL}/animators.php`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      });
  });
}

function createAnimator(pseudo, password) {
  const payload = new FormData();
  payload.append("pseudo", pseudo);
  payload.append("password", password);
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/create_animator.php`, {
      method: "POST",
      credentials: "include",
      body: payload,
    })
      .then((response) => {
        // console.log("API", response.status);
        if (response.status > 299) {
          reject("problem");
        }
        return response.json() || "";
      })
      .then((data) => {
        // console.log(data);
        resolve(data);
        // console.log(data);
      })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
}

export default {
  signin,
  createAnimator,
  joinSession,
  getSessions,
  getCurrentSessions,
  createSession,
  getUsers,
};
