<template>
  <div
    :style="[boxStyle, customStyle]"
    class="box"
    :class="customClass"
    @click="$emit('click', box.id)"
    @dblclick="$emit('dblclick', box.id)"
    @mousedown="$emit('mousedown', $event)"
    @touchstart="$emit('touchstart', $event)"
  >
    <component
      v-for="item in items"
      :key="`${item.type}${item.id}`"
      :is="item.type"
      :item="item"
      :style="[item.style, item.position, item.aspect]"
      :class="[item.type]"
      class="item"
    ></component>
    &nbsp;
  </div>
</template>
<script>
import Bubble from "./Bubble.vue";
import Appendice from "./Appendice.vue";
import Onomatopee from "./Onomatopee.vue";

export default {
  name: "box",
  components: {
    Bubble,
    Appendice,
    Onomatopee,
  },
  data() {
    return {};
  },
  //props: ["box"], //TODO à remettre
  props: {
    box: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => [],
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    customClass: {
      default: "",
    },
  },
  created() {},
  computed: {
    boxStyle() {
      return {
        "background-image": "url(" + this.box.src + ")",
        "aspect-ratio": this.box.aspectRatio,
      };
    },
    // items() {
    //   //TODO : virer ça de là : sera une propriété de la props box
    //   return this.box.items || [];
    //   // ?? this.$store.getters["editors/currentBoxItemsSorted"]
    // },
  },
};
</script>
<style scoped>
.box {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  background-origin: border-box;
  background-clip: border-box;
  user-select: none;
}
</style>
