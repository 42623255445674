/**
 * 25/12/2021 : fichier contenant les différentes ressources 'éditables'
 * TODO : rajouter les bd/cases
 * TODO : mieux gérer les appendices
 *
 */

const _bubbles = {
  2: {
    id: 2,
    src: "/exemples/bulles/bulle-etoile.png",
    name: "etoile",
    textarea: {
      top: "16.5%",
      left: "16.5%",
      width: "67%",
      height: "67%",
    },
    type: "bubble",
  },
  1: {
    src: "/exemples/bulles/bulle-ronde.png",
    id: 1,
    name: "ronde",
    textarea: {
      top: "16%",
      left: "16%",
      width: "68%",
      height: "68%",
    },
    type: "bubble",
  },
  3: {
    id: 3,
    src: "/exemples/bulles/bulle-nuage.png",
    name: "nuage",
    textarea: {
      top: "18%",
      left: "18%",
      width: "64%",
      height: "64%",
    },
    type: "bubble",
  },
  4: {
    id: 4,
    src: "/exemples/bulles/bulle-carree.png",
    name: "carre",
    textarea: {
      top: "3%",
      left: "3%",
      width: "94%",
      height: "94%",
    },
    type: "bubble",
  },
  5: {
    id: 5,
    src: "/exemples/bulles/bulle-transparente.png",
    name: "onomatopee",
    textarea: {
      top: "0%",
      left: "0%",
      width: "100%",
      height: "100%",
    },
    type: "bubble",
    content: "Texte libre",
  },
  ids: [1, 2, 3, 4, 5],
};
const _onomatopees = {
	1: {
		id: 1,
		src: "/exemples/onomatopees/onomatopées_0000_Houu.png",
		name: "Houu",
		type: "onomatopee",
	  },
	  2: {
		id: 2,
		src: "/exemples/onomatopees/onomatopées_0001_TapTap.png",
		name: "TapTap",
		type: "onomatopee",
	  },
	  3: {
		id: 3,
		src: "/exemples/onomatopees/onomatopées_0002_HaHa.png",
		name: "HaHa",
		type: "onomatopee",
	  },
	  4: {
		id: 4,
		src: "/exemples/onomatopees/onomatopées_0003_Euuh.png",
		name: "Euuh",
		type: "onomatopee",
	  },
	  5: {
		id: 5,
		src: "/exemples/onomatopees/onomatopées_0004_Grrr.png",
		name: "Grrr",
		type: "onomatopee",
	  },
	  6: {
		id: 6,
		src: "/exemples/onomatopees/onomatopées_0005_pfff.png",
		name: "pfff",
		type: "onomatopee",
	  },
	  7: {
		id: 7,
		src: "/exemples/onomatopees/onomatopées_0006_Hmmm.png",
		name: "Hmmm",
		type: "onomatopee",
	  },
	  8: {
		id: 8,
		src: "/exemples/onomatopees/onomatopées_0007_ouuf.png",
		name: "ouuf",
		type: "onomatopee",
	  },
	  9: {
		id: 9,
		src: "/exemples/onomatopees/onomatopées_0008_Pshht.png",
		name: "Pshht",
		type: "onomatopee",
	  },
	  10: {
		id: 10,
		src: "/exemples/onomatopees/onomatopées_0009_Hey.png",
		name: "Hey",
		type: "onomatopee",
	  },
	  11: {
		id: 11,
		src: "/exemples/onomatopees/onomatopées_0010_ClapClap.png",
		name: "ClapClap",
		type: "onomatopee",
	  },
	  12: {
		id: 12,
		src: "/exemples/onomatopees/onomatopées_0011_Hourra.png",
		name: "Hourra",
		type: "onomatopee",
	  },
	  13: {
		id: 13,
		src: "/exemples/onomatopees/onomatopées_0012_Oust.png",
		name: "Oust",
		type: "onomatopee",
	  },
	  14: {
		id: 14,
		src: "/exemples/onomatopees/onomatopées_0013_Bam01.png",
		name: "Bam01",
		type: "onomatopee",
	  },
	  15: {
		id: 15,
		src: "/exemples/onomatopees/onomatopées_0014_Bam02.png",
		name: "Bam02",
		type: "onomatopee",
	  },
	  16: {
		id: 16,
		src: "/exemples/onomatopees/onomatopées_0015_Bam03.png",
		name: "Bam03",
		type: "onomatopee",
	  },
	  17: {
		id: 17,
		src: "/exemples/onomatopees/onomatopées_0016_Hein.png",
		name: "Hein",
		type: "onomatopee",
	  },
	  18: {
		id: 18,
		src: "/exemples/onomatopees/onomatopées_0017_Aie01.png",
		name: "Aie01",
		type: "onomatopee",
	  },
	  19: {
		id: 19,
		src: "/exemples/onomatopees/onomatopées_0018_Aie02.png",
		name: "Aie02",
		type: "onomatopee",
	  },
	  20: {
		id: 20,
		src: "/exemples/onomatopees/dingdong.png",
		name: "dingdong",
		type: "onomatopee",
	  },
	  21: {
		id: 21,
		src: "/exemples/onomatopees/pfff.png",
		name: "pfff",
		type: "onomatopee",
	  },
	  22: {
		id: 22,
		src: "/exemples/onomatopees/vroum.png",
		name: "vroum",
		type: "onomatopee",
	  },
	  23: {
		id: 23,
		src: "/exemples/onomatopees/EHI.png",
		name: "EHI",
		type: "onomatopee",
	  },
	  24: {
		id: 24,
		src: "/exemples/onomatopees/PSHHH.png",
		name: "PSHHH",
		type: "onomatopee",
	  },
	  25: {
		id: 25,
		src: "/exemples/onomatopees/HUHU.png",
		name: "HUHU",
		type: "onomatopee",
	  },
	"bff92e2773b3aabcc919a56cc91a4e78": {
		id:"bff92e2773b3aabcc919a56cc91a4e78",
		src:'/exemples/onomatopees/francais/Poc1.png',
		name:'Poc1',
		type:'onomatopee',
		lang:'francais',
	},
	"aebd770bce38161e2a273b89f665e969": {
		id:"aebd770bce38161e2a273b89f665e969",
		src:'/exemples/onomatopees/francais/Slam3.png',
		name:'Slam3',
		type:'onomatopee',
		lang:'francais',
	},
	"730656b34d81d70610996bf267e4544e": {
		id:"730656b34d81d70610996bf267e4544e",
		src:'/exemples/onomatopees/francais/Dingdong1.png',
		name:'Dingdong1',
		type:'onomatopee',
		lang:'francais',
	},
	"8b7462d4a7e8101a492b8150ca0775b2": {
		id:"8b7462d4a7e8101a492b8150ca0775b2",
		src:'/exemples/onomatopees/francais/Grrr2.png',
		name:'Grrr2',
		type:'onomatopee',
		lang:'francais',
	},
	"b1ba86ebcfb170b29d696c92e44e66a1": {
		id:"b1ba86ebcfb170b29d696c92e44e66a1",
		src:'/exemples/onomatopees/francais/Pshhht2.png',
		name:'Pshhht2',
		type:'onomatopee',
		lang:'francais',
	},
	"392ec1e33bc6daa988f3c114a5d97114": {
		id:"392ec1e33bc6daa988f3c114a5d97114",
		src:'/exemples/onomatopees/francais/Hey3.png',
		name:'Hey3',
		type:'onomatopee',
		lang:'francais',
	},
	"cbbff008999d05929ab5b3309e8b95b0": {
		id:"cbbff008999d05929ab5b3309e8b95b0",
		src:'/exemples/onomatopees/francais/Glouglou2.png',
		name:'Glouglou2',
		type:'onomatopee',
		lang:'francais',
	},
	"7c2fa452c2124201715bc3a12d955633": {
		id:"7c2fa452c2124201715bc3a12d955633",
		src:'/exemples/onomatopees/francais/Bam2.png',
		name:'Bam2',
		type:'onomatopee',
		lang:'francais',
	},
	"08bf204d0631d32d4668efeacb7fa84e": {
		id:"08bf204d0631d32d4668efeacb7fa84e",
		src:'/exemples/onomatopees/francais/Hmmm4.png',
		name:'Hmmm4',
		type:'onomatopee',
		lang:'francais',
	},
	"464a1461708aa293ca83ff2bb570f60c": {
		id:"464a1461708aa293ca83ff2bb570f60c",
		src:'/exemples/onomatopees/francais/Papapa2.png',
		name:'Papapa2',
		type:'onomatopee',
		lang:'francais',
	},
	"e199180a989979d5439285fb9f4eb9c8": {
		id:"e199180a989979d5439285fb9f4eb9c8",
		src:'/exemples/onomatopees/francais/Hein3.png',
		name:'Hein3',
		type:'onomatopee',
		lang:'francais',
	},
	"42e33af45b652d6aabede3dbbb923ff3": {
		id:"42e33af45b652d6aabede3dbbb923ff3",
		src:'/exemples/onomatopees/francais/Houu4.png',
		name:'Houu4',
		type:'onomatopee',
		lang:'francais',
	},
	"26fb065e79523f1a335ebd346105f7ab": {
		id:"26fb065e79523f1a335ebd346105f7ab",
		src:'/exemples/onomatopees/francais/Ouuf3.png',
		name:'Ouuf3',
		type:'onomatopee',
		lang:'francais',
	},
	"40cbe87fa157f6808e93293ce644b6a4": {
		id:"40cbe87fa157f6808e93293ce644b6a4",
		src:'/exemples/onomatopees/francais/Haha2.png',
		name:'Haha2',
		type:'onomatopee',
		lang:'francais',
	},
	"5cb264ab67ffdb43f2e4dace9f494643": {
		id:"5cb264ab67ffdb43f2e4dace9f494643",
		src:'/exemples/onomatopees/francais/Taptap2.png',
		name:'Taptap2',
		type:'onomatopee',
		lang:'francais',
	},
	"1993e0a806ac56929dec4a255e6bccda": {
		id:"1993e0a806ac56929dec4a255e6bccda",
		src:'/exemples/onomatopees/francais/Toctoc2.png',
		name:'Toctoc2',
		type:'onomatopee',
		lang:'francais',
	},
	"996901fe4f510a55995662258162bcbf": {
		id:"996901fe4f510a55995662258162bcbf",
		src:'/exemples/onomatopees/francais/Miaou2.png',
		name:'Miaou2',
		type:'onomatopee',
		lang:'francais',
	},
	"c6a94730bea814f6407b708bd2950a98": {
		id:"c6a94730bea814f6407b708bd2950a98",
		src:'/exemples/onomatopees/francais/Rrrr.png',
		name:'Rrrr',
		type:'onomatopee',
		lang:'francais',
	},
	"95f18894e01c5017e53d2083fd1243ef": {
		id:"95f18894e01c5017e53d2083fd1243ef",
		src:'/exemples/onomatopees/francais/Euh1.png',
		name:'Euh1',
		type:'onomatopee',
		lang:'francais',
	},
	"ffd1543bc06796af3b9b880a632a979b": {
		id:"ffd1543bc06796af3b9b880a632a979b",
		src:'/exemples/onomatopees/francais/Vrouum3.png',
		name:'Vrouum3',
		type:'onomatopee',
		lang:'francais',
	},
	"e4d0ce8b78ff488bba8524a4365509b4": {
		id:"e4d0ce8b78ff488bba8524a4365509b4",
		src:'/exemples/onomatopees/francais/Hourra2.png',
		name:'Hourra2',
		type:'onomatopee',
		lang:'francais',
	},
	"bb0951bfbbae7dc8c00c959237331dac": {
		id:"bb0951bfbbae7dc8c00c959237331dac",
		src:'/exemples/onomatopees/francais/Hihi4.png',
		name:'Hihi4',
		type:'onomatopee',
		lang:'francais',
	},
	"65868d4f7d7fc196a2c025cf43754513": {
		id:"65868d4f7d7fc196a2c025cf43754513",
		src:'/exemples/onomatopees/francais/PFFF1.png',
		name:'PFFF1',
		type:'onomatopee',
		lang:'francais',
	},
	"ad32085963385e722526f473277273f8": {
		id:"ad32085963385e722526f473277273f8",
		src:'/exemples/onomatopees/francais/Aie1.png',
		name:'Aie1',
		type:'onomatopee',
		lang:'francais',
	},
	"583513720cd08112d4d24ebffef87543": {
		id:"583513720cd08112d4d24ebffef87543",
		src:'/exemples/onomatopees/francais/Clapclap3.png',
		name:'Clapclap3',
		type:'onomatopee',
		lang:'francais',
	},
	"781edb41700aad6e5ed160513b9536d1": {
		id:"781edb41700aad6e5ed160513b9536d1",
		src:'/exemples/onomatopees/francais/Oust3.png',
		name:'Oust3',
		type:'onomatopee',
		lang:'francais',
	},
	"6e060dfeb4c9345d911fcb7828e142ae": {
		id:"6e060dfeb4c9345d911fcb7828e142ae",
		src:'/exemples/onomatopees/ponctuation/_!4.png',
		name:'_!4',
		type:'onomatopee',
		lang:'ponctuation',
	},
	"796205a43890789708dd8e95cc70e233": {
		id:"796205a43890789708dd8e95cc70e233",
		src:'/exemples/onomatopees/ponctuation/_2.png',
		name:'_2',
		type:'onomatopee',
		lang:'ponctuation',
	},
	"c00ed278f4f64cd9ddc30908548987bf": {
		id:"c00ed278f4f64cd9ddc30908548987bf",
		src:'/exemples/onomatopees/ponctuation/point1.png',
		name:'point1',
		type:'onomatopee',
		lang:'ponctuation',
	},
	"a9150f2664e55a69ab7d46ec296e8af6": {
		id:"a9150f2664e55a69ab7d46ec296e8af6",
		src:'/exemples/onomatopees/ponctuation/!2.png',
		name:'!2',
		type:'onomatopee',
		lang:'ponctuation',
	},
	"253ff8c63814e5ca3070af1c1429bf02": {
		id:"253ff8c63814e5ca3070af1c1429bf02",
		src:'/exemples/onomatopees/italien/Poc1.png',
		name:'Poc1',
		type:'onomatopee',
		lang:'italien',
	},
	"8f126ae9b97b24143fba93e50e8f412c": {
		id:"8f126ae9b97b24143fba93e50e8f412c",
		src:'/exemples/onomatopees/italien/BUM2.png',
		name:'BUM2',
		type:'onomatopee',
		lang:'italien',
	},
	"bbaa15c699bc63ad93f96b9f821f5ffe": {
		id:"bbaa15c699bc63ad93f96b9f821f5ffe",
		src:'/exemples/onomatopees/italien/SBAM1.png',
		name:'SBAM1',
		type:'onomatopee',
		lang:'italien',
	},
	"fc1f04ea2d27e2c45b86047cddbcde74": {
		id:"fc1f04ea2d27e2c45b86047cddbcde74",
		src:'/exemples/onomatopees/italien/Grrr2.png',
		name:'Grrr2',
		type:'onomatopee',
		lang:'italien',
	},
	"6d481e2cf764ee5e2a3d146614b10636": {
		id:"6d481e2cf764ee5e2a3d146614b10636",
		src:'/exemples/onomatopees/italien/Uff1.png',
		name:'Uff1',
		type:'onomatopee',
		lang:'italien',
	},
	"4edd7597ea2c650007742ba71756f55e": {
		id:"4edd7597ea2c650007742ba71756f55e",
		src:'/exemples/onomatopees/italien/Miao3.png',
		name:'Miao3',
		type:'onomatopee',
		lang:'italien',
	},
	"4b2fb2c9caed90089ca0070305995214": {
		id:"4b2fb2c9caed90089ca0070305995214",
		src:'/exemples/onomatopees/italien/Dindon4.png',
		name:'Dindon4',
		type:'onomatopee',
		lang:'italien',
	},
	"54c0e3ed8d4be538fea30d21274129f2": {
		id:"54c0e3ed8d4be538fea30d21274129f2",
		src:'/exemples/onomatopees/italien/Eheh3.png',
		name:'Eheh3',
		type:'onomatopee',
		lang:'italien',
	},
	"89c54dd3c7d4e588b92be992b6aae0d2": {
		id:"89c54dd3c7d4e588b92be992b6aae0d2",
		src:'/exemples/onomatopees/italien/Clapclap2.png',
		name:'Clapclap2',
		type:'onomatopee',
		lang:'italien',
	},
	"1d36eee203b0d6ad210e58cb065357c3": {
		id:"1d36eee203b0d6ad210e58cb065357c3",
		src:'/exemples/onomatopees/italien/Huhu2.png',
		name:'Huhu2',
		type:'onomatopee',
		lang:'italien',
	},
	"195eb40761f4d2a78d2ac36a09b75c01": {
		id:"195eb40761f4d2a78d2ac36a09b75c01",
		src:'/exemples/onomatopees/italien/pffft2.png',
		name:'pffft2',
		type:'onomatopee',
		lang:'italien',
	},
	"133fff19a940994f8646b809d0c028c7": {
		id:"133fff19a940994f8646b809d0c028c7",
		src:'/exemples/onomatopees/italien/Prrr1.png',
		name:'Prrr1',
		type:'onomatopee',
		lang:'italien',
	},
	"f43e19fc81278ae0803a89135f5f8b5b": {
		id:"f43e19fc81278ae0803a89135f5f8b5b",
		src:'/exemples/onomatopees/italien/Haha2.png',
		name:'Haha2',
		type:'onomatopee',
		lang:'italien',
	},
	"85928c50f02f4cda3f474a896af00cde": {
		id:"85928c50f02f4cda3f474a896af00cde",
		src:'/exemples/onomatopees/italien/Ahi3.png',
		name:'Ahi3',
		type:'onomatopee',
		lang:'italien',
	},
	"e9a3fde4c10c5f76afbbb33a03350b54": {
		id:"e9a3fde4c10c5f76afbbb33a03350b54",
		src:'/exemples/onomatopees/italien/Toctoc2.png',
		name:'Toctoc2',
		type:'onomatopee',
		lang:'italien',
	},
	"6f2966bb2c0da7df5ad7d0780f3a67b0": {
		id:"6f2966bb2c0da7df5ad7d0780f3a67b0",
		src:'/exemples/onomatopees/italien/Rrrr.png',
		name:'Rrrr',
		type:'onomatopee',
		lang:'italien',
	},
	"9dc3e64d37306bcbc730be15180ec44f": {
		id:"9dc3e64d37306bcbc730be15180ec44f",
		src:'/exemples/onomatopees/italien/Ehm1.png',
		name:'Ehm1',
		type:'onomatopee',
		lang:'italien',
	},
	"64f6d63c51b50eba051b3b70f655f1f6": {
		id:"64f6d63c51b50eba051b3b70f655f1f6",
		src:'/exemples/onomatopees/italien/Urrah3.png',
		name:'Urrah3',
		type:'onomatopee',
		lang:'italien',
	},
	"56879d50eeabda27181fee3ebdb7016b": {
		id:"56879d50eeabda27181fee3ebdb7016b",
		src:'/exemples/onomatopees/italien/Scio2.png',
		name:'Scio2',
		type:'onomatopee',
		lang:'italien',
	},
	"8c7c2cc9e86898a5489de20f68b7f248": {
		id:"8c7c2cc9e86898a5489de20f68b7f248",
		src:'/exemples/onomatopees/italien/Picpic2.png',
		name:'Picpic2',
		type:'onomatopee',
		lang:'italien',
	},
	"0eea1be67ff730a6ec77f37f94a7a5ae": {
		id:"0eea1be67ff730a6ec77f37f94a7a5ae",
		src:'/exemples/onomatopees/italien/Mmmm3.png',
		name:'Mmmm3',
		type:'onomatopee',
		lang:'italien',
	},
	"48e10d2b27ec550250b32f561a2c6964": {
		id:"48e10d2b27ec550250b32f561a2c6964",
		src:'/exemples/onomatopees/italien/Hihi3.png',
		name:'Hihi3',
		type:'onomatopee',
		lang:'italien',
	},
	"43ca8e67092245570d1ce5ec85622521": {
		id:"43ca8e67092245570d1ce5ec85622521",
		src:'/exemples/onomatopees/italien/Hey2.png',
		name:'Hey2',
		type:'onomatopee',
		lang:'italien',
	},
	"61b854aa91e5e1e579302691f83357e6": {
		id:"61b854aa91e5e1e579302691f83357e6",
		src:'/exemples/onomatopees/italien/Vroom2.png',
		name:'Vroom2',
		type:'onomatopee',
		lang:'italien',
	},
	"c511ca9a11caff2ff078531469761d86": {
		id:"c511ca9a11caff2ff078531469761d86",
		src:'/exemples/onomatopees/italien/Gluglu1.png',
		name:'Gluglu1',
		type:'onomatopee',
		lang:'italien',
	},
	"9c834747214d6d5fc1399239df74bf69": {
		id:"9c834747214d6d5fc1399239df74bf69",
		src:'/exemples/onomatopees/italien/Via2.png',
		name:'Via2',
		type:'onomatopee',
		lang:'italien',
	},
	"d00aec39f2e4c38c02c989254fc6a783": {
		id:"d00aec39f2e4c38c02c989254fc6a783",
		src:'/exemples/onomatopees/italien/Taptap1.png',
		name:'Taptap1',
		type:'onomatopee',
		lang:'italien',
	},
	"e1814ec1b085adb70421b4f49094e235": {
		id:"e1814ec1b085adb70421b4f49094e235",
		src:'/exemples/onomatopees/italien/Auch2.png',
		name:'Auch2',
		type:'onomatopee',
		lang:'italien',
	},  
	// "e1814ec1b085adb70421b4f49094e235": {
	// 	id:"e1814ec1b085adb70421b4f49094e235",
	// 	src:'/exemples/onomatopees/Auch2.png',
	// 	name:'Auch2',
	// 	type:'onomatopee',
	// 	lang:'italien',
	// },  
	/* eslint-disable */
	ids: [ 
		// Onomatopee SEIF
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
		// Onomatopee 29/09/2022
		23,24,25,
		// francais
		'bff92e2773b3aabcc919a56cc91a4e78','aebd770bce38161e2a273b89f665e969','730656b34d81d70610996bf267e4544e','8b7462d4a7e8101a492b8150ca0775b2','b1ba86ebcfb170b29d696c92e44e66a1','392ec1e33bc6daa988f3c114a5d97114','cbbff008999d05929ab5b3309e8b95b0','7c2fa452c2124201715bc3a12d955633','08bf204d0631d32d4668efeacb7fa84e','464a1461708aa293ca83ff2bb570f60c','e199180a989979d5439285fb9f4eb9c8','42e33af45b652d6aabede3dbbb923ff3','26fb065e79523f1a335ebd346105f7ab','40cbe87fa157f6808e93293ce644b6a4','5cb264ab67ffdb43f2e4dace9f494643','1993e0a806ac56929dec4a255e6bccda','996901fe4f510a55995662258162bcbf','c6a94730bea814f6407b708bd2950a98','95f18894e01c5017e53d2083fd1243ef','ffd1543bc06796af3b9b880a632a979b','e4d0ce8b78ff488bba8524a4365509b4','bb0951bfbbae7dc8c00c959237331dac','65868d4f7d7fc196a2c025cf43754513','ad32085963385e722526f473277273f8','583513720cd08112d4d24ebffef87543','781edb41700aad6e5ed160513b9536d1',
		// Ponctuation
		'6e060dfeb4c9345d911fcb7828e142ae','796205a43890789708dd8e95cc70e233','c00ed278f4f64cd9ddc30908548987bf','a9150f2664e55a69ab7d46ec296e8af6',
		// Italien
		'253ff8c63814e5ca3070af1c1429bf02','8f126ae9b97b24143fba93e50e8f412c','bbaa15c699bc63ad93f96b9f821f5ffe','fc1f04ea2d27e2c45b86047cddbcde74','6d481e2cf764ee5e2a3d146614b10636','4edd7597ea2c650007742ba71756f55e','4b2fb2c9caed90089ca0070305995214','54c0e3ed8d4be538fea30d21274129f2','89c54dd3c7d4e588b92be992b6aae0d2','1d36eee203b0d6ad210e58cb065357c3','195eb40761f4d2a78d2ac36a09b75c01','133fff19a940994f8646b809d0c028c7','f43e19fc81278ae0803a89135f5f8b5b','85928c50f02f4cda3f474a896af00cde','e9a3fde4c10c5f76afbbb33a03350b54','6f2966bb2c0da7df5ad7d0780f3a67b0','9dc3e64d37306bcbc730be15180ec44f','64f6d63c51b50eba051b3b70f655f1f6','56879d50eeabda27181fee3ebdb7016b','8c7c2cc9e86898a5489de20f68b7f248','0eea1be67ff730a6ec77f37f94a7a5ae','48e10d2b27ec550250b32f561a2c6964','43ca8e67092245570d1ce5ec85622521','61b854aa91e5e1e579302691f83357e6','c511ca9a11caff2ff078531469761d86','9c834747214d6d5fc1399239df74bf69','d00aec39f2e4c38c02c989254fc6a783','e1814ec1b085adb70421b4f49094e235', 
	],
	/* eslint-enable */

};


const _appendices = {
  1: {
    id: 1,
    src: "/exemples/bulles/appendices/appendice-nuage-gauche.png",
    name: "appendice-nuage-gauche",
    type: "appendice",
  },
  2: {
    id: 2,
    src: "/exemples/bulles/appendices/appendice-rond-droit.png",
    name: "appendice-rond-droit",
    type: "appendice",
  },
  3: {
    id: 3,
    src: "/exemples/bulles/appendices/appendice-rond-gauche.png",
    name: "appendice-rond-gauche",
    type: "appendice",
  },
  4: {
    id: 4,
    src: "/exemples/bulles/appendices/appendice-rond.png",
    name: "appendice-rond",
    type: "appendice",
  },
  5: {
    id: 5,
    src: "/exemples/bulles/appendices/appendice-droite-1.png",
    name: "appendice-droite-1",
    type: "appendice",
  },
  6: {
    id: 6,
    src: "/exemples/bulles/appendices/appendice-gauche-1.png",
    name: "appendice-gauche-1",
    type: "appendice",
  },
  7: {
    id: 7,
    src: "/exemples/bulles/appendices/appendice-nuage-droite.png",
    name: "appendice-nuage-droite",
    type: "appendice",
  },
  ids: [1, 7, 2, 3, 4, 5, 6],
};

const _layouts = {
  //TODO : add borders
  classic: {
    id: "classic",
    classes: ["classic"],
    style: {
      // gridArea: 1 / 1 / 1 / 1,
      // height: "100%",
      // width: "auto",
      // border: "2px solid black",
      // margin: "3px",
    },
    size: {
      rows: 3,
      cols: 3,
      nbItem: 9,
    },
  },
  topLeft: {
    id: "topLeft",
    classes: ["top-left", "double"],
    index: 0,
    style: {
      gridArea: "1 / 1 / 3 / 3",
    },
    size: {
      rows: 3,
      cols: 3,
      nbItem: 6,
    },
  },
  topRight: {
    id: "topRight",
    classes: ["top-right", "double"],
    index: 1,
    style: {
      gridArea: "1 / 2 / 3 / 4",
    },
    size: {
      rows: 3,
      cols: 3,
      nbItem: 6,
    },
  },
  bottomLeft: {
    id: "topLeft",
    classes: ["top-left", "double"],
    index: 3,
    style: {
      gridArea: "2 / 1 / 4 / 3",
    },
    size: {
      rows: 3,
      cols: 3,
      nbItem: 6,
    },
  },
  bottomRight: {
    id: "topRight",
    classes: ["top-right", "double"],
    index: 5,
    style: {
      gridArea: "2 / 2 / 4 / 4",
    },
    size: {
      rows: 3,
      cols: 3,
      nbItem: 6,
    },
  },
  ids: ["classic", "topLeft", "topRight", "bottomLeft", "bottomRight"],
};

const _fonts = [
  "Bebas Neue",
  "Hurricane",
  "Indie Flower",
  "Pacifico",
  "Permanent Marker",
  "Roboto",
  "Shadows Into Light",
  "Shalimar",
];

// const _fonts = [
//   "Helvetica",
//   "Times New Roman",
//   "Verdana",
//   "Courier New",
//   "Brush Script MT",
//   "Verdana",
//   "Impact",
//   "American Typewriter",
//   "Bradley Hand, cursive",
//   "Luminari",
//   "Comic Sans MS",
//   "fantasy",
// ];

function denormalize(obj) {
  if (obj?.ids === undefined) return [];
  return obj.ids.map((id) => obj[id]);
}
// function denormalize(obj) {
//   return obj.ids.map((id) => obj[id]);
// }

export default {
  _bubbles: () => _bubbles,
  _appendices: () => _appendices,
  _onomatopees: () => _onomatopees,
  _layouts: () => _layouts,
  loadBubbles(cb) {
    setTimeout(() => cb(_bubbles), 100);
  },
  loadAppendices(cb) {
    setTimeout(() => cb(_appendices), 10);
  },
  loadOnomatopees(cb) {
    setTimeout(() => cb(_onomatopees), 10);
  },
  loadFonts(cb) {
    setTimeout(() => cb(_fonts), 10);
  },
  loadLayouts(cb) {
    setTimeout(() => cb(_layouts), 10);
  },
  denormalize,
};
