import testBoxe from "../../api/testBox";
import Vue from "vue";
//import {loadComics, loadBoxes} from "../../api/comics"; //TODO : experimental
import comics from "../../api/comics";
// import resources from "../../api/resources-norm"; //TODO : experimental

// Gestion des id
import { customAlphabet } from "nanoid";
import { alphanumeric } from "nanoid-dictionary";
const nanoid = customAlphabet(alphanumeric, 10);

const debug = false;

const emptySelected = {
  type: "none",
  id: -1,
  id_box: -1,
  controls: {
    draggable: false,
    rotatable: false,
    resizable: false,
  },
  style: {
    fontFamily: "",
    fontStyle: "normal",
    textDecoration: "none",
    fontWeight: "normal",
  },
  position: {
    top: "0%",
    left: "0%",
    width: "0%",
    height: "0%",
  },
  aspect: {
    aspectRatio: 1,
    transform: "",
    zIndex: 0,
  },
  content: "",
};

//TODO : move to css and use a class !!!
// const highlightStyle= {
//   filter:  "drop-shadow(0 0 1.5em yellow)",
//   border: "1px solid black",
//   borderRadius: "10%",
//   boxShadow: "darkorange 2px 2px 2px 2px, darkorange -2px -2px 2px 2px",
// }

// initial state
const state = () => ({
  currentBoxId: -1,
  currentSelectedBoxItem: {
    id: -1,
    type: "none",
  },
  comics: { 1: { boxes: [] } },
  data: {
    boxes: { 1: { bubbles: [], onomatopees: [], appendices: [] } },
    bubbles: {},
    onomatopees: {},
    appendices: {},
  },
  currentSelectedComicId: -1,
  currentComicTitle: "",
  themes: {

  },
  currentSelectedThemeId: -1,
});

// getters
const getters = {

  selectedBoxItem: (state) => {
    if (state.currentSelectedBoxItem.type == "none") {
      return emptySelected;
    }
    let itemType = state.currentSelectedBoxItem.type + "s";
    return state.data[itemType][state.currentSelectedBoxItem.id] ?? {};
  },
  currentBoxId: (state) => {
    return state.currentBoxId;
  },
  currentBox: (state /*, getters, rootState*/) => {
    //Todo : remove ???
    if (state.currentBoxId == -1) {
      return null;
    }
    return {
      ...state.data.boxes[state.currentBoxId],
      //TODO : bug si n'a pas d'onomatopees, ou d'appendice ou de bulles. Gérer en item
      // onomatopees: state.data.boxes[state.currentBoxId].onomatopees?.map(
      //   (id) => {
      //     return {
      //       ...state.data.onomatopees[id],
      //       src: rootState.staticResources.onomatopees[
      //         state.data.onomatopees[id].id_reference
      //       ].src,
      //     };
      //   }
      // ),
      // bubbles: state.data.boxes[state.currentBoxId].bubbles?.map((id) => {
      //   return {
      //     ...state.data.bubbles[id],
      //     ...(({ src, textarea }) => ({ src, textarea }))(
      //       rootState.staticResources.bubbles[
      //         state.data.bubbles[id].id_reference
      //       ]
      //     ),
      //   };
      // }),
      // appendices: state.data.boxes[state.currentBoxId].appendices?.map((id) => {
      //   return {
      //     ...state.data.appendices[id],
      //     src: rootState.staticResources.appendices[
      //       state.data.appendices[id].id_reference
      //     ].src,
      //   };
      // }),
    };
  },
  /**
   * Liste tous les items de la case courante, sous forme de tableau, où chaque item à son type ()
   * @param {*} state
   * @returns
   */
  currentBoxItems: (state, getters) => {
    if (state.currentBoxId == -1) {
      return [];
    }
    //TODO : bug si n'a pas d'onomatopees, ou d'appendice ou de bulles. Gérer avec un tableau d'item vide à l'origine dans chaque box
    return getters.getBoxItems(state.currentBoxId);
  },
  getBoxItems: (state, getters, rootState) => (boxId) => {
    return state.data.boxes[boxId].bubbles
      .map((id) => {
        return {
          ...JSON.parse(JSON.stringify(state.data.bubbles[id])),
          ...(({ src, textarea }) => ({ src, textarea }))(
            rootState.staticResources.bubbles[
              state.data.bubbles[id].id_reference
            ]
          ),
          type: "bubble",
        };
      })
      .concat(
        state.data.boxes[boxId].appendices
          .map((id) => {
            return {
              ...JSON.parse(JSON.stringify(state.data.appendices[id])),
              src: rootState.staticResources.appendices[
                state.data.appendices[id].id_reference
              ].src,
              type: "appendice",
            };
          })
          .concat(
            state.data.boxes[boxId].onomatopees.map((id) => {
              return {
                ...JSON.parse(JSON.stringify(state.data.onomatopees[id])),
                src: rootState.staticResources.onomatopees[
                  state.data.onomatopees[id].id_reference
                ].src,
                type: "onomatopee",
              };
            })
          )
      );
  },
  currentBoxItemsSorted: (state, getters) => {
    return getters.currentBoxItems //(state, getters, rootState)
      .sort((a, b) => {
        return a.aspect.zIndex - b.aspect.zIndex;
      })
      .reverse(); // On inverse la liste pour pas avoir à le faire en CSS (cf ItemOrder.vue)
  },
  boxesSeif: (state, getters, rootState, rootGetters) => {
    //TODO clean this, its now for debug
    //TODO: experimental
    // let boxes = resources.denormalize(state.boxes);
    return rootGetters["staticResources/boxes"].filter(
      (e) => e.id_comic == state.currentSelectedComicId
    ); //.map((e) => e.src=comics.comics[e.id_comic].path+e.src)
  },
  currentComicId: (state) => {
    //TODO clean this, for debug
    return state.currentSelectedComicId;
  },
  currentComic: (state) => {
    return state.comics[state.currentSelectedComicId];
  },
  currentComicBoxes: (state, getters) => {
    return state.comics[state.currentSelectedComicId].boxes.map((id) => {
      return {
        ...JSON.parse(
          JSON.stringify({
            ...state.data.boxes[id],
            items: getters.getBoxItems(id),
          })
        ),
      };
    });
  },
  currentComicBoxesSorted: (state, getters) => {
    return getters.currentComicBoxes.sort((a, b) => {
      return a.position - b.position;
    });
  },
  currentComicLayout(state, getters, rootState) {
    const layouts = getters.currentComic.layouts
      ? getters.currentComic.layouts
      : getters.currentComic.defaultLayouts;
    // console.log(layouts);
    return layouts
      .map((id) => {
        return {
          ...JSON.parse(JSON.stringify(rootState.staticResources.layouts[id])),
        };
      })
      .map(function (e, i, a) {
        let start = a
          .slice(0, i)
          .map((e) => e.size.nbItem)
          .reduce((a, b) => a + b, 0);
        // console.log({ ...e, start: start, end: start + e.size.nbItem });
        return {
          ...e,
          start: start,
          end: start + e.size.nbItem,
          num: i,
          boxes: JSON.parse(
            JSON.stringify(
              getters.currentComicBoxesSorted.slice(
                start,
                start + e.size.nbItem
              )
            )
          ),
        };
      });
  },
  currentComicTitle(state) {
    return state.comics[state.currentSelectedComicId].title;
  },
  getComicById: (state) => (id) => {
    return state.comics[id];
  },
  getComicLayoutByComicId: (state, getters, rootState) => (id) => {
    const layouts = getters.getComicById(id).layouts
      ? getters.getComicById(id).layouts
      : getters.getComicById(id).defaultLayouts;
    // console.log(layouts);
    return layouts
      .map((id) => {
        return {
          ...JSON.parse(JSON.stringify(rootState.staticResources.layouts[id])),
        };
      })
      .map(function (e, i, a) {
        let start = a
          .slice(0, i)
          .map((e) => e.size.nbItem)
          .reduce((a, b) => a + b, 0);
        // console.log({ ...e, start: start, end: start + e.size.nbItem });
        return {
          ...e,
          start: start,
          end: start + e.size.nbItem,
          num: i,
          boxes: JSON.parse(
            JSON.stringify(
              getters
                .getComicBoxesSortedByComicId(id)
                .slice(start, start + e.size.nbItem)
            )
          ),
        };
      });
  },
  getComicBoxesByComicId: (state, getters) => (id) => {
    return state.comics[id].boxes.map((id) => {
      return {
        ...JSON.parse(
          JSON.stringify({
            ...state.data.boxes[id],
            items: getters.getBoxItems(id),
          })
        ),
      };
    });
  },
  getComicBoxesSortedByComicId: (state, getters) => (id) => {
    return getters.getComicBoxesByComicId(id).sort((a, b) => {
      return a.position - b.position;
    });
  },
  getCurrentThemeId: (state) => {
    return state.currentSelectedThemeId
  },
  getComicsByThemeId: (state, getters) => (id) => {
    return state.themes[id].comics.map((cId) => {
      return {
        ...getters.getComicById(cId),
      }
    })
  },
  currentThemeComics: (state, getters) => {
    return getters.getComicsByThemeId(state.currentSelectedThemeId);
  },
};

// actions
const actions = {
  loadTestData({ commit }) {
    testBoxe.loadTestData((data) => {
      commit("setTestData", data);
    });
  },
  setBubbleTextContent(context, content) {
    if (context.state.currentSelectedBoxItem.type == "bubble") {
      context.commit("SET_BUBBLE_TEXT_CONTENT", content);
    } else {
      // console.log("Impossible d'éditer un objet qui n'est pas une bulle");
    }
  },
  setCurrentBoxId({ commit }, id) {
    commit("SET_CURRENT_BOX_ID", id);
  },
  selectObject({ commit }, { id, type }) {
    // CLEAR CURRENT SELECTION, SET NEW SELECTION ?

    //commit("SET_CURRENT_SELECTED_OBJECT", { id, type });
    commit("CLEAR_HIGHLIGHT");

    commit("SET_CURRENT_SELECTED_OBJECT", { id, type });
    commit("HIGHLIGHT_ITEM", { id, type });
    // TODO : add Highlight here ?
  },
  /**
   *
   * @param {*} commit
   * @param {*} payload : style property name, value (fontWeight, bold),
   */
  setCurrentBubbleStyleProperty(context, payload) {
    if (context.state.currentSelectedBoxItem.type == "bubble") {
      context.commit("SET_CURRENT_BUBBLE_STYLE_PROPERTY", payload);
    }
    // else {
    //   console.log(
    //     "Impossible de changer la propriété d'un objet qui n'est pas une bulle"
    //   );
    // }
  },
  setCurrentSelectedPositionProperty({ commit }, payload) {
    commit("SET_CURRENT_SELECTED_POSITION_PROPERTY", payload);
  },
  setCurrentSelectedAspectProperty({ commit }, payload) {
    commit("SET_CURRENT_SELECTED_ASPECT_PROPERTY", payload);
  },
  setCurrentSelectedControlsProperty({ commit }, payload) {
    commit("SET_CURRENT_SELECTED_CONTROLS_PROPERTY", payload);
  },
  addNewItemToCurrentBox(context, payload) {
    //TODO : make it in mutation
    context.commit("CLEAR_HIGHLIGHT");
    context.commit("CLEAR_ITEM_SELECTION");
    // generate UUID
    const id = nanoid();
    // console.log(
    //   "Adding new item " + payload.type + payload.id + " with id : " + id
    // );
    // Create new object correponding to type

    //TODO : z-index for new item : must be > every item !
    const curBox = context.state.data.boxes[context.state.currentBoxId];
    let index =
      10 +
      (curBox.appendices.length +
        curBox.onomatopees.length +
        curBox.bubbles.length +
        1);
    const defaultItem = JSON.parse(
      JSON.stringify(
        context.rootGetters["staticResources/defaultsBoxItems"][payload.type]
      )
    );
    let newItem = {
      //TODO : use loadash.clone/deepclone or native function for more standard/reliable cloning
      ...defaultItem,
      id: id,
      id_reference: payload.id,
      id_box: context.state.currentBoxId,
      position: { ...defaultItem.position, ...payload.position },
    };
    // console.log(newItem)
    Vue.set(newItem.aspect, "zIndex", index);
    // append to list and current box
    context.state.data.boxes[context.state.currentBoxId][
      `${payload.type}s`
    ].push(id);
    Vue.set(context.state.data[`${payload.type}s`], id, newItem);

    context.commit("ADD_ITEM_TO_CURRENT_BOX", payload);
    context.commit("SET_CURRENT_SELECTED_OBJECT", {
      id: id,
      type: payload.type,
    });
    context.commit("HIGHLIGHT_ITEM", { id: id, type: payload.type });
  },
  deleteSelectedItem(context) {
    context.commit("CLEAR_HIGHLIGHT");
    context.commit("DELETE_SELECTED_ITEM");
    context.commit("CLEAR_ITEM_SELECTION");
  },
  updateCurrentBoxItemOrder(context, payload) {
    if (debug)
      console.log(
        "updateCurrentBoxItemOrder updated with ",
        context.state.data,
        payload
      );
    payload.forEach((item, index) => {
      const i = 10 + index;
      Vue.set(context.state.data[`${item.type}s`][item.id].aspect, "zIndex", i);
    });
  },
  updateCurrentComicBoxesOrder(context, payload) {
    if (debug)
      console.log(
        "updateBoxesOrder updated with ",
        context.state.data,
        payload
      );
    payload.forEach((box, index) => {
      Vue.set(context.state.data.boxes[box.id], "position", index);
    });
  },
  // shuffleCurrentComicBoxes(context, payload) {
  //   if (debug)
  //     console.log("updateBoxesOrder updated with ", context.state.data, payload);
  //   //Add random position to boxes

  // }
  // setBoxes(context) {
  //   if (debug) console.debug("setBoxes  : ");
  //   const boxes = context.rootGetters["staticResources/boxes"];
  //   console.log(boxes)
  //   //const boxes = context.rootState.staticResources.boxes;
  //   context.commit("SET_BOXES", boxes);
  // },
  // setComics(context) {
  //   if (debug) console.debug("setComics  : ");
  //   const comics = context.rootState.staticResources.comics;
  //   console.log(comics)
  //   context.commit("SET_COMICS", comics);
  // },
  loadBoxes({ commit }) {
    //TODO : experimental
    comics.loadBoxes((boxes) => {
      commit("SET_BOXES", boxes);
    });
  },
  loadComics({ commit }) {
    if (debug) console.debug("LoadComics ");
    comics.loadComics((comics) => {
      console.log(comics);
      commit("SET_COMICS", comics);
    });
    // console.log(commit);
  },
  loadThemes({commit}) {
    if (debug) console.debug("loadThemes ");
    comics.loadThemes((themes) => {
      console.log(themes);
      commit("SET_THEMES", themes);
    });
  }



};

// mutations
const mutations = {
  setTestData(state, data) {
    state.data = { ...data };
    // if (debug) console.debug("setTestData  ", state.data);
  },
  SET_CURRENT_SELECTED_COMIC_ID(state, value) {
    //TODO : remove this, for debug
    if (debug) console.debug("Select currentSelectedComicId " + value);
    Vue.set(state, "currentSelectedComicId", value);
  },
  SET_BUBBLE_TEXT_CONTENT(state, content) {
    if (debug) console.debug("setBubbleTextContent updated with ", content);
    // On récupère l'objet selectionné (une bulle normalement)
    if (state.currentSelectedBoxItem.type == "bubble") {
      Vue.set(
        state.data.bubbles[state.currentSelectedBoxItem.id],
        "content",
        content
      );
    } else {
      console.log(
        "Impossible de mettre à jour le texte d'un objet " +
          state.currentSelectedBoxItem.type
      );
    }
  },
  SET_CURRENT_BOX_ID(state, id) {
    if (debug) console.debug("SET_CURRENT_BOX_ID updated with ", id);
    state.currentBoxId = id;
  },
  SET_CURRENT_SELECTED_OBJECT(state, { id, type }) {
    if (debug)
      console.log("SET_CURRENT_SELECTED_OBJECT updated with ", id, type);
    state.currentSelectedBoxItem.id = id;
    state.currentSelectedBoxItem.type = type;
    // TODO : add customStyle for selection ???
  },
  SET_CURRENT_BUBBLE_STYLE_PROPERTY(state, payload) {
    if (debug)
      console.log("SET_CURRENT_BUBBLE_STYLE_PROPERTY updated with ", payload);
    let itemType = state.currentSelectedBoxItem.type + "s";
    let sel = state.data[itemType][state.currentSelectedBoxItem.id] ?? {};
    Vue.set(sel.style, payload.property, payload.value);
  },
  SET_CURRENT_SELECTED_POSITION_PROPERTY(state, payload) {
    if (debug)
      console.log(
        "SET_CURRENT_SELECTED_POSITION_PROPERTY " +
          state.currentSelectedBoxItem.id +
          " updated with ",
        payload
      );
    let itemType = state.currentSelectedBoxItem.type + "s";
    let sel = state.data[itemType][state.currentSelectedBoxItem.id] ?? {};
    Vue.set(sel.position, payload.property, payload.value);
  },
  SET_CURRENT_SELECTED_ASPECT_PROPERTY(state, payload) {
    if (debug)
      console.log(
        "SET_CURRENT_SELECTED_ASPECT_PROPERTY " +
          state.currentSelectedBoxItem.id +
          " updated with ",
        payload
      );
    let itemType = state.currentSelectedBoxItem.type + "s";
    let sel = state.data[itemType][state.currentSelectedBoxItem.id] ?? {};
    // console.log(sel);
    Vue.set(sel.aspect, payload.property, payload.value);
  },
  SET_CURRENT_SELECTED_CONTROLS_PROPERTY(state, payload) {
    if (debug)
      console.log(
        "SET_CURRENT_SELECTED_CONTROL_PROPERTY updated with ",
        payload
      );

    let itemType = state.currentSelectedBoxItem.type + "s";
    let sel = state.data[itemType][state.currentSelectedBoxItem.id] ?? {};
    Vue.set(sel.controls, payload.property, payload.value);
  },
  ADD_ITEM_TO_CURRENT_BOX(state, payload) {
    //TODO : décaler le contenu de l'action ici ??
    if (debug) console.debug("ADD_ITEM_TO_CURRENT_BOX  ", state, payload);
  },
  CLEAR_ITEM_SELECTION(state) {
    if (debug) console.debug("CLEAR_ITEM_SELECTION");
    Vue.set(state.currentSelectedBoxItem, "id", -1);
    Vue.set(state.currentSelectedBoxItem, "type", "none");
  },
  DELETE_SELECTED_ITEM(state) {
    if (debug)
      console.log(
        "DELETE_SELECTED_ITEM id: " +
          state.currentSelectedBoxItem.id +
          " type: " +
          state.currentSelectedBoxItem.type
      );

    let itemType = state.currentSelectedBoxItem.type + "s";
    Vue.delete(state.data[itemType], state.currentSelectedBoxItem.id);
    //Remove from box :
    let box = state.data.boxes[state.currentBoxId];
    Vue.delete(
      box[itemType],
      box[itemType].indexOf(state.currentSelectedBoxItem.id)
    );
  },
  HIGHLIGHT_ITEM(state, payload) {
    //TODO : merge highlight and selected
    let itemType = payload.type + "s";
    //Vue.set(state.data[itemType][payload.id], "customStyle", highlightStyle);
    if (!state.data[itemType][payload.id].classes) {
      Vue.set(state.data[itemType][payload.id], "classes", ["highlight"]); //TODO : use const for class name
    } else {
      state.data[itemType][payload.id].classes.push("highlight");
    }
  },
  CLEAR_HIGHLIGHT(state) {
    if (state.currentSelectedBoxItem.id != -1) {
      const itemType = state.currentSelectedBoxItem.type + "s";
      //Vue.delete(state.data[itemType][state.currentSelectedBoxItem.id], "customStyle")
      const classes =
        state.data[itemType][state.currentSelectedBoxItem.id].classes;
      //const index = state.data[itemType][state.currentSelectedBoxItem.id].classes.indexOf("highlight")
      if (classes != undefined)
        state.data[itemType][state.currentSelectedBoxItem.id].classes.splice(
          classes.indexOf("highlight"),
          1
        );
      // Vue.delete(state.data[itemType][state.currentSelectedBoxItem.id], "classes");
    }
  },
  // INIT_COMIC(state) {
  SET_BOXES(state, boxes) {
    //Vue.set(state.data, "boxes", boxes);
    state.data = { ...state.data, boxes };
    state.data.boxes.ids.forEach(
      (id) =>
        (state.data.boxes[id] = {
          ...state.data.boxes[id],
          bubbles: [],
          onomatopees: [],
          appendices: [],
        })
    );
    //MUTATE comics ? adding boxesId to comics
    //resources.denormalize(boxes).forEach((box) => state.comics[box.id_comic].push(box.id));
    // resources.denormalize(boxes).forEach((box) => {console.log("state.comics[box.id_comic].push(box.id));
  },
  SET_COMICS(state, comics) {
    Vue.set(state, "comics", comics);
  },
  updateCurrentComicTitle(state, title) {
    // Vue.set(state, "currentComicTitle", title);
    Vue.set(state.comics[state.currentSelectedComicId], "title", title);
  },
  LOCK_COMIC(state, comicId) {
    if (debug) console.debug("LOCK_COMIC");
    Vue.set(state.comics[comicId], "locked", true);
  },
  SET_THEMES(state, themes) {
    Vue.set(state, 'themes', themes);
  },
  SET_CURRENT_SELECTED_THEME_ID(state, value) {
    //TODO : remove this, for debug
    if (debug) console.debug("Select currentSelectedThemeId " + value);
    Vue.set(state, "currentSelectedThemeId", value);
  },
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
