const state = () => ({
  isLoggedIn: false,
  pseudo: "",
  role: "",

  selectedSessionName: "",
  selectedSessionCode: "",

  canPrint: false,
});

const getters = {
  isLoggedIn(state) {
    return state.isLoggedIn;
  },
  pseudo(state) {
    return state.pseudo;
  },
  role(state) {
    return state.role;
  },
  canPrint(state) {
    return state.canPrint;
  },
};

const mutations = {
  IS_LOGGED_IN(state, value) {
    state.isLoggedIn = value;
  },
  LOGIN(state, value) {
    state.pseudo = value;
  },
  ROLE(state, value) {
    state.role = value;
  },
  CANPRINT(state, value) {
    state.canPrint = value;
  },
};

const actions = {
  login(context, user) {
    context.commit("IS_LOGGED_IN", true);
    context.commit("LOGIN", user.login);
    context.commit("ROLE", user.role);
    context.commit("CANPRINT", user.canPrint);
  },
  join(context, sessionJoin) {
    context.commit("LOGIN", sessionJoin.login);
    context.commit("CANPRINT", sessionJoin.canPrint);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
