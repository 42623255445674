<template>
  <div id="app">
    <div id="nav">
      <div id="main-title">BD Kreator</div>
      <!-- <router-link class="link" to="/">BDKreator</router-link> -->
      <!-- <router-link
        v-if="this.$store.getters['editors/currentComicId'] !== -1 && this."
        class="link"
        :to="{
          name: 'print',
          params: { comicId: this.$store.getters['editors/currentComicId'] },
        }"
      >
        Print
      </router-link> -->

      <!-- <router-link class="link" to="/BoxEditor">Retour</router-link> -->
      <router-link
        v-for="(route, index) in getRoutes"
        :key="index"
        :to="route.to"
        >{{ route.text }}</router-link
      >
    </div>
    <router-view v-if="loaded" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  // TODO: le chargement du store bloque souvent au premier chargement, la solution ci-dessous est catastrophique mais OK.
  data: () => {
    return { dispatchedCount: 0, dispatchCount: 11, loaded: false };
  },
  watch: {
    dispatchedCount() {
      // console.log("coucou", this.dispatchedCount);
      if (this.dispatchedCount >= this.dispatchCount) {
        setTimeout(() => {
          this.loaded = true;
        }, 100);
      }
    },
  },
  created() {
    // Chargement des données du store (ce qui est fait n'est plus à faire)
    const self = this;
    this.dispatchedCount = 0;
    this.$store.dispatch("staticResources/loadOnomatopees").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("staticResources/loadBubbles").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("staticResources/loadAppendices").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("staticResources/loadComics").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("staticResources/loadThemes").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("staticResources/loadBoxes").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("staticResources/loadLayouts").then(() => {
      self.dispatchedCount++;
    });
    // this.$store
    //   .dispatch("editors/setCurrentBoxId", "edc94966f0167953571a3a75bb6d8517")
    //   .then(() => {
    //     self.dispatchedCount++;
    //   });
    this.$store.dispatch("editors/loadTestData").then(() => {
      self.dispatchedCount++;
    });
    // this.$store.dispatch("editors/setComics").then(() => { self.dispatchedCount++ ;
    // this.$store.dispatch("editors/setBoxes").then(() => { self.dispatchedCount++ ;
    this.$store.dispatch("editors/loadComics").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("editors/loadBoxes").then(() => {
      self.dispatchedCount++;
    });
    this.$store.dispatch("editors/loadThemes").then(() => {
      self.dispatchedCount++;
    });
  },
  computed: {
    ...mapGetters("applicationFlow", {
      role: "role",
      isLoggedIn: "isLoggedIn",
    }),
    ...mapGetters("editors", {
      currentComicId: "currentComicId",
    }),
    getRoutes() {
      //TODO : gérer le menu dynamique ailleurs ?
      const currentPage = this.$route.name;
      const routes = [];

      // let logOut  =  {to:{name: "logout"}, text:"Déconnexion"}
      switch (currentPage) {
        case "Home":
          break;
        case "UsersAdministration":
          routes.push({
            to: { name: "SessionsAdministration" },
            text: "Sessions",
          });
          break;
        case "SessionsAdministration":
          if (this.role === "admin") {
            routes.push({
              to: { name: "UsersAdministration" },
              text: "Animateurs",
            });
          }
          break;
        case "BoxEditor":
          routes.push({ to: { name: "ComicBoxesOrder" }, text: "Retour" });
          break;
        case "ComicBoxesOrder":
          routes.push({
            to: { 
              name: "ComicSelector",
              params: {lock: false},
              meta: {lock: false}, 
            },
            text: "Retour",

          });
          routes.push({
            to: { name: "ComicSelector" },
            text: "Terminer ma BD",
          });
          break;
        case "print":
          routes.push({
            to: { name: "ComicSelector" },
            text: "Retour",
          });
          break;
        case "ComicSelector":
          routes.push({
            to: { name: "ThemeSelector" },
            text: "Retour",
          });
          break;
      }
      // if(this.isLoggedIn) {
      //   routes.push(logOut)
      // }

      return routes; // TODO : à écrire
    },
  },
};
</script>
<style>
/* @import url("https://fonts.googleapis.com/css2?family=Petemoss&family=Road+Rage&family=Roboto:wght@100&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hurricane&family=Indie+Flower&family=Pacifico&family=Permanent+Marker&family=Roboto&family=Shadows+Into+Light&family=Shalimar&display=swap");

* {
  padding: 0;
  margin: 0;
  border: 0;
}
body {
  /* max-height: 100vh;
  max-width: 100vh;
  max-height: -web-kit-fill-available; */
  font-family: sans-serif;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  font-family: cursive;
  max-height: 100vh;
  padding-top: 68px;

  max-width: 100vw;
}

#nav {
  padding: 15px 30px;
  background: #25314e;
  color: #fff;
  position: fixed;
  top: 0;
  width: calc(100vw - 60px);
  z-index: 110;
  box-shadow: 0 0 10px #000000;
  min-height: 40px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

#nav a {
  /* position: absolute;  */
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  /* padding: 10px 60px 10px 10px; */
  padding: 0 20px;
  /*   top: 40%;  */
}

#nav #main-title {
  display: inline-block;
  /* margin: 0 auto; */
  max-width: 300px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -100px;
}

body {
  line-height: 1;
}

.select {
  background: #fff;
  color: #555;
  padding: 10px;
  min-width: 300px;
  border: 1px solid #555;
  border-radius: 3px;
}

.cta-button {
  padding: 10px 20px;
  background: #5db18b;
  color: #ffffff;
  position: fixed;
  top: 15px;
  /* right: 30px; */
  min-width: 27%;
  border-radius: 3px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 120;
  left: 30px;
}

.ok-button {
  background: #5db18b;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  padding: 10px 20px;
  min-width: 190px;
}

.danger-button {
  color: #ffffff;
  background: #ba4242;
  padding: 10px 20px;
  min-width: 80px;
  border-radius: 3px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.cta-button.disabled {
  opacity: 0.2;
  cursor: not-allowed;
  transition: opacity 0.3s ease;
}

.item {
  user-select: none;
}

.input {
  padding: 10px;
  border: 1px solid #bbb;
  border-radius: 3px;
  display: inline-block;
}
.button {
  padding: 11px;
  background: #694fea;
  cursor: pointer;
  color: #fff;
  border: 0;
  min-width: 100px;
  border-radius: 3px;
  box-shadow: 2px 5px 20px #ccc;
  display: inline-block;
}
.button:hover {
  opacity: 0.8;
}

.mt-1 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 20px;
}
.mt-4 {
  margin-top: 40px;
}

.mb-1 {
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 10px;
}
.mb-3 {
  margin-bottom: 20px;
}
.mb-4 {
  margin-bottom: 40px;
}

.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}
.ml-3 {
  margin-left: 20px;
}
.ml-4 {
  margin-left: 40px;
}

.mr-1 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 20px;
}
.mr-4 {
  margin-right: 40px;
}

.m-1 {
  margin: 5px;
}
.m-2 {
  margin: 10px;
}
.m-3 {
  margin: 20px;
}
.m-4 {
  margin: 40px;
}

@media print {
  #nav {
    display: none;
  }
  #app {
    padding-top: 0px;
  }
}
</style>
