const _data = {
  boxes: {
    1: {
      id: 1,
      id_board: 1,
      number: 9,
      width: 1423,
      height: 1414,
      pos_x: 2,
      pos_y: 2,
      size: 1,
      src: "./exemples/Vignette-test-fond.png",
      bubbles: [1, 2], // Les bulles effectives de la case
      onomatopees: [1],
      appendices: [1, 2],
    },
  },
  bubbles: {
    1: {
      id: 1,
      id_reference: 1,
      id_box: "edc94966f0167953571a3a75bb6d8517",
      position: {
        width: "20%",
        top: "8%",
        left: "30%",
        height: "10%",
        // aspectRatio: () => {return parseFloat(this.width.slice(0,-1)/this.height.slice(0,-1))}
        transform: "",
      },
      controls: {
        draggable: true,
        resizable: true,
        rotatable: false,
      },
      content: "Lache moi !",
      style: {
        fontStyle: "normal",
        fontWeight: "normal",
        textDecoration: "none",
        fontFamily: "Shadows Into Light",
        color: "#ff0000",
      },
      aspect: {
        aspectRatio: 2,
        zIndex: 1000,
      },
      type: "bubble",
    },
    2: {
      id: 2,
      id_reference: 2,
      id_box: "edc94966f0167953571a3a75bb6d8517",
      position: {
        width: "15%",
        top: "18%",
        left: "36%",
        height: "12%",
        transform: "",
        color: "#000000",
      },
      controls: {
        draggable: true,
        resizable: true,
        rotatable: false,
      },
      content: "NON",
      style: {
        fontStyle: "normal",
        fontWeight: "normal",
        textDecoration: "none",
        fontFamily: "Permanent Marker",
      },
      aspect: {
        aspectRatio: 15 / 12,
        zIndex: 1020,
      },
      type: "bubble",
    },
  },
  appendices: {
    1: {
      id: 1,
      id_reference: 4,
      id_box: 1,
      position: {
        width: "4.31%",
        top: "17%",
        left: "34.43%",
        height: "16.78%",
        transform: "rotate(15.1157deg)",
      },
      controls: {
        draggable: true,
        resizable: true,
        rotatable: true,
      },
      aspect: {
        aspectRatio: 4.31 / 16.78,
        zIndex: 1010,
      },
      type: "appendice",
    },
    2: {
      id: 2,
      id_reference: 4,
      id_box: 1,
      position: {
        width: "2.16%",
        top: "24.33%",
        left: "49.87%",
        height: "6.308%",
        transform: "rotate(-83.4461deg)",
      },
      controls: {
        draggable: true,
        resizable: true,
        rotatable: true,
      },
      aspect: {
        zIndex: 1030,
        aspectRatio: 2.16 / 6.308,
      },
      type: "appendice",
    },
  },
  onomatopees: {
    1: {
      id: 1,
      id_reference: 1,
      id_box: 1,
      position: {
        width: "12.8341%",
        top: "6.0145%",
        left: "7.41679%",
        height: "9.6087%",
        transform: "",
      },
      controls: {
        draggable: true,
        resizable: true,
        rotatable: false,
      },
      aspect: {
        aspectRatio: 12.8341 / 9.6087,
        zIndex: 1040,
      },
      type: "onomatopee",
    },
  },
};

export default {
  async loadTestData(cb) {
    setTimeout(() => cb(_data), 100);
  },
};
