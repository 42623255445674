<template>
  <div class="component">
    <h1 class="h1">Choisissez un thème</h1>
    <div id="theme-selector">
      <div
        v-for="(theme, index) in themes"
        :key="index"
        class="theme-preview"
        @click="selectTheme(theme.id)"
      >

        <p class="theme"> {{ theme.description }}</p>
        <button class="ok-button" v-if="!theme.locked" @click="selectTheme(theme.id)">Choisir</button>
      </div>
    </div>
    <div class="footer">
      <div class="text">
        Retrouvez la présentation et les outils du projet BDKREATOR : <a href="http://bdkreator.com/" target="_blank">http://bdkreator.com/</a>
      </div>
      <div class="text info">
        Pour retrouver l'accès à toutes les créations, <a href="/">relancer la session</a>
      </div>
    </div>
  </div>
</template>
<script>
// import box from "../components/Box.vue";
export default {
  components: {
    // box,
  },
  data() {
    return {};
  },
  created() {
    this.$store.commit("editors/SET_CURRENT_SELECTED_THEME_ID", -1);
  },
  methods: {
    selectTheme(id) {
      // On vérifie que la bd n'est pas vérrouillée :
      if (!this.themes.find((c) => c.id === id).locked) {
        this.$store.commit("editors/SET_CURRENT_SELECTED_THEME_ID", id);
        this.$router.push("ComicSelector").catch(() => {}); // TODO : enlever cette horreur
      }
    },
  },
  computed: {
    themes() {
      return this.$store.getters["staticResources/themes"];
    },
  },
};
</script>
<style scoped>
.component {
width: 100%;
/* display: flex;
flex-direction: column; */
}

h1 {
  text-align: center;
  margin: 10vh 0;
}
#theme-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  /* height: 35vh;  */
}

.theme-preview {
  /* max-height: 40%; */
  border: 3px solid transparent;
  margin: 5px;

  /*border: 3px solid black;*/
  padding: 30px 30px 15px 30px;
  background: #fff;
  box-shadow: 5px 5px 25px #00000044;
  /* height: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.theme {
  margin: 15px 5px;
  /* height: 2em; */
  /* height: 100%; */
}

#theme-selector {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
#theme-selector .action-buttons {
  text-align: center;
}

#theme-selector .action-buttons > .ok-button {
  display: block;
  /* margin: 15px; */
  margin: 5px auto;
  text-decoration: none;
  width: 80%;
}

.theme-preview {
  max-width: 220px;
}

.box {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}
.text {
  width: fit-content;
  text-align: left;
  margin: 10px auto;
  
}

.invisible {
  visibility: hidden;
}

.ok-button {
  display: block;
}
</style>
