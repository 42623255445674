<template>
  <div class="main-container">
    <div class="" v-for="(typedItem, indice) in items" :key="indice">
      <div class="type-title">{{ typedItem.description }}</div>
      <div class="item-selector">
      <div
        class="item-container"
        :class="item.type"
        v-for="item in typedItem.items"
        :key="`${item.type}${item.id}`"
        
        @dragstart="dragStart($event, { id: item.id, type: item.type })"
        @touchstart="dragStart($event, { id: item.id, type: item.type })"
        @dblclick="$emit('addNewItem', { id: item.id, type: item.type })"
        draggable="true"
      >
        <component
          :is="item.type"
          @mousedown="selectItem($event, item.id, item.type)"
          @touchstart="dragStart($event, { id: item.id, type: item.type })"
          :item="item"
          :style="[item.style, item.customStyle]"
          :class="[isSelected(item) ? 'active' : '', item.type]"
          class="item"
          @dragstart="dragStart($event, { id: item.id, type: item.type })"
          draggable="true"
        ></component>
      </div>
      <!-- <button
        class="cta-button"
        v-if="selected.id != -1"
        @click="addNewItem(selected)"
      >
        ajouter
      </button> -->
    </div>
  </div>
  </div>
</template>
<script>
import Bubble from "./Bubble.vue";
import Appendice from "./Appendice.vue";
import Onomatopee from "./Onomatopee.vue";

import Vue from "vue";

export default {
  components: {
    Bubble,
    Appendice,
    Onomatopee,
  },
  name: "ItemSelector",
  data() {
    return {
      selected: { id: -1, type: "none" },
    };
  },
  props: ["items", "active"],
  methods: {
    selectItem(event, id, type) {
      console.debug("In Select Item ", this.selected);
      Vue.set(this.selected, "id", id);
      Vue.set(this.selected, "type", type);
      this.$emit("itemSelected", id, type);
    },
    // addNewItem() {
    //   console.log("Add clicked");
    //   this.$emit("addNewItem", {
    //     id: this.selected.id,
    //     type: this.selected.type,
    //   });
    //   this.clearSelection();
    // },
    clearSelection() {
      this.selected = null;
    },

    dragStart(e, payload) {
      // TODO : use real dataType for setData, maybe application/json
      e.dataTransfer.setData("action", "createNewItem");
      e.dataTransfer.setData("type", payload.type);
      e.dataTransfer.setData("id", payload.id);

      // Ajout du décalage souris/top/left de l'élément
      const deltaX = e.target.getClientRects()[0].x - e.clientX;
      const deltaY = e.target.getClientRects()[0].y - e.clientY;
      e.dataTransfer.setData("deltaX", deltaX);
      e.dataTransfer.setData("deltaY", deltaY);
    },
  },
  computed: {
    addActive() {
      return this.active && this.selected != null;
    },
    isSelected() {
      return (item) =>
        this.selected.id == item.id && this.selected.type == item.type;
    },
    maxHeight() {
      return { height: 100 / this.items.length + "%" };
    },
  },
};
</script>
<style scoped>
.item-selector {
  display: flex;
  aflex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  /* width: 100px; /* TODO: Pour l'instant */
  /* height est géré par l'éditeur qui le contient */
  /* height: 3rem; */ /*TODO : pour l'instant */
  justify-content: space-evenly;
}
.item-container {
  width: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
  align-items: center;
  justify-content: center;
  /* flex-grow: 1;
  height: 100%; */
  height: 2rem;;
}

.item-container.bubble .item {
  /* height: 80%; */
}

.item-container.appendice {
  /* height: 100%; */
  width: auto;
}

.item-container.appendice .item {
  background-position: center;
  background-size: auto 100%;
  /* height: 80%; */
  aspect-ratio: 1;
}

.item-container.onomatopee {
  /* width:100%; */
}

.item-container.onomatopee .item {
  /* width:100%; */
  /* height: 80%; */
}
.item {
  height: inherit;
  width: inherit;
  /* height: 200%; */
}
.item.active {
  filter: drop-shadow(0 0 1.5em yellow);
  border: 1px solid black;
  border-radius: 10%;
  box-shadow: darkorange 2px 2px 2px 2px, darkorange -2px -2px 2px 2px;
}
.type-title {
  padding: 1rem;
  border-top: 1px solid black;
}

</style>
<style>
#item-selector{
    overflow: scroll;
}
</style>