<template>
  <div class="component">
    <h1 class="h1">Gestion des sessions</h1>
    <table>
      <tr>
        <td>
          <input
            class="input"
            type="text"
            placeholder="Nom de la sesion"
            v-model="newSession.name"
          />
        </td>
        <td>
          <input
            class="input"
            type="text"
            placeholder="Clé de connexion"
            v-model="newSession.cle"
          />
        </td>
      </tr>
      <tr>
        <td>
          Début :
          <input class="input" v-model="newSession.beginDate" type="date" />
        </td>
        <td>
          &nbsp;<br />
          <input class="input" v-model="newSession.beginHour" type="time" />
        </td>
      </tr>
      <tr>
        <td>
          Fin : <input class="input" v-model="newSession.endDate" type="date" />
        </td>
        <td>
          &nbsp;<br />
          <input class="input" v-model="newSession.endHour" type="time" />
        </td>
      </tr>
      <tr>
        <td>
          Possibilité d'imprimer
          <input class="input" v-model="newSession.printable" type="checkbox" />
        </td>
        <td>
          <button class="button" @click="createSession">Créer</button>
        </td>
      </tr>
    </table>
    <table>
      <thead>
        <tr>
          <th>Session</th>
          <th>Clé</th>
          <th>Début</th>
          <th>Fin</th>
          <th>Imprimable</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="session in sessions" :key="session.id">
          <td>{{ session.name }}</td>
          <td>{{ session.cle }}</td>
          <td>{{ session.begin }}</td>
          <td>{{ session.end }}</td>
          <td>{{ Boolean(parseInt(session.printable)) ? "oui" : "non" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import API from "@/api/api";
export default {
  name: "SessionsAdministration",
  data() {
    return {
      newSession: {
        name: "",
        cle: "",
        beginDate: new Date().toISOString().split("T")[0],
        beginHour: "00:00:00",
        endDate: new Date().toISOString().split("T")[0],
        endHour: "00:00:00",
        printable: true,
      },
      sessions: [],
    };
  },
  created() {
    this.getSessions();
  },
  methods: {
    createSession() {
      API.createSession(this.newSession).then((payload) => {
        console.log(payload);
        this.getSessions();
      });
    },
    getSessions() {
      API.getSessions().then((payload) => {
        this.sessions.splice(0, this.sessions.length, ...payload);
      });
    },
  },
};
</script>

<style scoped>
.component {
  color: #333;
  font-family: "Roboto Light", Arial, sans-serif;
  margin: 20px;
}
.component .h1 {
  margin: 20px 0;
}
table {
  min-width: 800px;
  width: 90%;
  border-spacing: 0;
}
table th {
  text-align: left;
  background: #ddd;
  color: #555;
  padding: 10px;
}
table td {
  text-align: left;
  color: #555;
  padding: 10px;
}
table tr .input {
  margin: 0;
}
</style>
