// function denormalize(obj) {
//     if (obj?.ids === undefined) return [];
//     return obj.ids.map((id) => obj[id]);
//   }

const _themes = {
 1: {
  id: 1,
  key: "rascime",
  description: "Créez votre récit sur les questions de société que soulèvent les différentes formes de violence au quotidien du racisme ordinaire aux phénomenes de radicalisation et d'exclusion de l'autre", 
  comics: [1, 2, 4, 5, ], 

 },
 2: {
  id: 2,
  key: "violence",
  description: "Créez votre récit sur la question des violences sexistes et sexuelles au travail", 
  comics: [6, 7, 8, ], 
 }, 
 ids: [1,2],

}

const _comics = {
  1: {
    id: 1,
    type: "simple",
    // title: "À la ville, à la plage",
    author: "Ismael MEZIANE",
    path: "/planches/ISMAEL/simple/wrk/",
    original: "/planches/ISMAEL/BD_MEZIANE.pdf",
    aspectRatio: 1005 / 1475,
    // default_box_id: 1,
    template: {
      src: "/planches/ISMAEL/simple/wrk/transparent.png",
      name: "template",
      aspectRatio: 1005 / 1475,
    },
    theme: 1,
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "f87362e5ff4c5634c1f51149a48e116f",
      "0d328a42bc8c72dd9477dde693747b72",
      "d4d1631e30da0262e5e1762652fa45fe",
      "2992d85f6fc839fa148cbd05c395cb5e",
      "aeb167df8b99e52fd1c64cf5ddc25cc3",
      "7a3d4f67e8e45775da0d2b054139ffc7",
      "d4d20e2cfca4da0d94a7aedaddc73358",
      "dfb212c11cd2567d7e251954fc54308c",
      "ec93b2a1cffc7a9ce53237ae5bbaefe9",
      "ecd964036ff7db6078a63d369d541d4a",
      "8b0dbb548905f17e5f26a719d69559eb",
      "247221ff171d4476966e448de4dffff5",
      "082d03ff2976b202637202caf17f9b25",
      "a1cbe333257c9e5eca82490b4becc77e",
      "03d21bdf37cb4de67760d1573fe43755",
      "79e38dc8e5f01c6a50aa6d0a4ad2941d",
      "407becd55e52feb9654117ac63cc048f",
      "ef0c65ecc72ad4c4ab2f3928acd54116",
      "58011a29e2f23f64d97583021bcc4f67",
      "e8635b1a3bc185f0fc3ffab8da71b395",
      "1699c0874687b5c8eb71861207d6a2be",
      "ddcd30a003c484fa2110a72f0b8e16d0",
      "5dee75a652008831bd518201ace57e31",
      "f3bced202a198f327534d7f01b11d96c",
      "3a635f591f3268598e12f6cbac2444ac",
      "7b4d5a530fd52d4871bd46b677d4bff4",
      "67bb777e04d941bc7cac6019afc24be0",
    ],
  },
  2: {
    id: 2,
    type: "simple",
    author: "Seif Eddine NECHI",
    // title: "l'élection",
    path: "/planches/SEIF/simple/wrk/",
    original: "/planches/SEIF/Seif.pdf",
    aspectRatio: 3508 / 2480,
    // default_box_id: 2,
    template: {
      src: "/planches/SEIF/simple/wrk/transparent.png",
      name: "template",
      aspectRatio: 3508 / 2480,
    },
    theme: 1,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "ab9262f066992c1d7d192195b8b39081",
      "32b9794a924938f5bb9cd0173b6a7da4",
      "90895c550ea320aa4b295d11e6e280e6",
      "6ef490537ae4b2c607a7dce5c071d2ee",
      "ab594105a64268064ae1243b79628483",
      "ceca59b44018ca67878ac61e04d9489a",
      "2922f7ad6f9bbbffc2683505f2f7f901",
      "5e94a45d33ced279e3b0d3a1432f4852",
      "1ed8a9f5beb5bd666edaffbe688cab9a",
      "6354ca5357a177758fa0c3c1e699e256",
      "1ed2329ed1f7203cb495ba455f7252c1",
      "20b03d63d5f7d14804af9c3644e0784c",
      "ff71356ed0cf19a7126d64c3a73df598",
      "f62f6794663def2e91ee13c77367731a",
      "908fea2c0bdfe7fa74f58f2c2093032c",
      "6b9bd09d5863dd64d96a2549d5cc615b",
      "d132e74ca3fda2d21b4d991274eba54e",
      "8239cb50bc588b09fdcdf4d2b782a38f",
      "b5cb29e48f06381856b3ff96a78591e8",
      "3f4bb9c5f4a8115ea987518f6237b897",
      "8c4c488982fc10ee719eec7831ebbebc",
      "6fc38194dd67ae27a91f104eaae50199",
      "edc94966f0167953571a3a75bb6d8517",
      "ceb439fb444966057958d1421895825a",
      "c740237af7311cd095a37a9151a4cc51",
      "1fcd8c0fc358807dddf0a0f473c6a71d",
      "ec0534be29540e523cada43fec12db26",
      "61dd14130bbb962dd6dd3447accbc292",
      "d82adf66f9d7985dd5cbb97ce4c93ec6",
    ],
  },
  3: {
    id: 3,
    type: "text",
    author: "Seif Eddine Nechi",
    // title: "l'élection",
    path: "/planches/SEIF/text/",
    aspectRatio: 3508 / 2480,
    // default_box_id: 2,
    template: {
      src: "/planches/SEIF/text/transparent.png",
      name: "template",
      aspectRatio: 3508 / 2480,
    },
    theme: 1,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "1b24d783a1c7ee1a37460fa9ebcddfc7",
      "9b0afe8b2c15a0a75e2ab85095d1b0e6",
      "90c601ea0548d3c8d1da984cd692aca3",
      "c243c6996ef32f83a5535e9fe726e3c0",
      "c9773ea4676e336b06e5fd541c862f2d",
      "93768a5bd4a4ac62c482fcecd7c13dc0",
      "2904f3c31622953c8dd7534694d2efcd",
      "b43ee0123bba9469637f7ad10fa4afb3",
      "5ef428090e35416fea7d55b3a6dbfffa",
      "d6187bfb593ccc9392a036ad1beb1a08",
      "83637393ce6a450b686a7127a4125b11",
      "894f4f7d505d0ccb9a5dd7438d1c9aff",
      "3edff47c9fe84de2d1d90c7d91940cfb",
      "ffb20ded5104544ca79dc99000524716",
      "2059e951b74185248ed0479ca5e410d0",
      "8c5062df357685d7a72da13d6559fb6c",
      "a808cee599907cdb330939e55f5ad831",
      "14aa89360f0842add0c0f609ef10b105",
      "b6ab306d2a543af8917be5d6b43f3738",
      "b35852ea1c9a138b6705de1ec1748ca5",
      "0c3beecae811139d70ef3854318012d4",
      "66dee92bb55121df1c58746b5be8d531",
      "d19032ac96c3e8504480b09d4cbb14c7",
      "11b5f1d60ec4c0000513dcd3ef925b49",
      "1b5bd70f76e9987db8358041450c91d4",
      "2cae280bfd23e92c240df489644d5e27",
      "c74293dc2c4b9883e9d1236413b36883", // V27
      // "2aec53b448a42f5b682ea55ee7459333", // V28
      // "7e3fdd9a64991c96fa931e26718f9383", // V29
    ],
  },

  4: {
    id: 4,
    type: "simple",
    author: "The French Baguette (ESDAC, Marseille)",
    // title: "l'élection",
    path: "/planches/CHAT/simple/wrk/",
    original: "/planches/CHAT/BD-chat.pdf",
    aspectRatio: 1754 / 1240,
    // default_box_id: 2,
    template: {
      //src: "/planches/SEIF/text/transparent.png",
      name: "template",
      aspectRatio: 1754 / 1240,
    },
    theme: 1,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "d2d6045ae08361dcc17e35969c2d11c1",
      // "b7f8306c1872716431d6d01ff28cc4ec",
      "7f1fe990e4773756d010f6ce2ea2123d",
      "1ec151acad25a9d274b4232648bb6fbf",
      "f97d966e16286e4f73120a1d35bfcae6",
      "0b248bbb658e93e116802fb7d460477f",
      "87c4ec7ad438d7782cf13448751c485b",
      "dc9b6611c95cabec984803264cb9f78d",
      "e986ef3a84452acccd3c99abeb4af63d",
      "05ac5e253935da5bbd0d08e283e7eed0",
      "ae602ee6452020dc0f761a97609f7c06",
      "09af76e487492a5230e58df5c5d1b677",
      "022ef90c0e7d3ab8486861a4234d9389",
      "7e713e04f5e8763235d2b3b0ad6e0455",
      "95a6e6ea15955c95c50432cec9c2e02d",
      "7f688f8ecf647c0b481a89476436cb1d",
      "d5d4da5c36a0a671947ce343929f1ef2",
      "11413de409fd61c335a144372a4fb898",
      "14a90e00b2653c1f057694005cfe14df",
      "b38239e8e75ea1f8c5ba6546f273b7a2",
      "94f077dcbeba8872167acff9bfc4622b",
      "907fd713b6cda111fa72a60575b1e1ae",
      "d399c46d3f12826361b3f603acda6e3b",
      "19468659d5bc1b4c87ffe1bc20bc9612",
      "acd053cc6852544f09cc8d29e94c79e9",
      "fc526bb7533044fc8d57a1e8251bc39e",
      "a3ef9973d340a72c6449d3b1c7ea3fe9",
      "efc33e20d1305bde735578445ac60f82",
    ],
  },
  5: {
    id: 5,
    type: "simple",
    author: "la Scuola Italiana di Comix (Naples)",
    // title: "l'élection",
    path: "/planches/ECOLE/simple/wrk/",
    original: "/planches/ECOLE/BD-ecole.pdf",
    aspectRatio: 1754 / 1240,
    // default_box_id: 2,
    template: {
      //src: "/planches/SEIF/text/transparent.png",
      name: "template",
      aspectRatio: 1754 / 1240,
    },
    theme: 1,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "cf0e26bb1332543a5c0f72b0cf96a518",
      "939903b4f17210903ce7c2029d29fe9f",
      "8051d54ec9218f57199b5ec30135e7a3",
      "72a3b76cf4f55a95d4fbc2257b77c02b",
      "27cea807d75646eecea35a777a98460b",
      "bfdec1fb7cfe57991aec3462d9d4632c",
      "750583921880e484403a1a46f48c6bc5",
      "6797c9d70e133e59ca61806110376b70",
      "f2f6ab63855b11e032a9f118ae7f3a70",
      "e5813163101cd18bb6a1234a1834421f",
      "f41ad893ba1db638d4df972c55f2687a",
      "e9c94c680ba9c62c5754c4e537705f10",
      "b51ba621b87b08a5f324e14de570e0a7",
      "1e02fe2d0911e377ad50057378a6a8ac",
      "4a0755ee7412cae5cf6305cbcfa5dc90",
      "34c69c3562482d8605139428d9763f87",
      "7f7d74138966a52014424f8f53cd09e6",
      "a1cdb65efc45aa3ac9a7e7599b147b17",
      "8112beb2b3b4198a4392ff00787529fb",
      "fc340b1b6d21a20853012a8c2f08b69a",
      "06f926dd1d656524efa8ff6ca728abe5",
      "c27aa4ee4a1dab8a454683eb182cd67b",
      "5a8a153513184f32f1d490ca171ba1f4",
      "46bcb09f0e622c4ab7dcff92a47dcc34",
      "17803aee98e2327175e21edc37551ffb",
      "93234558744c37969e70a2008d604cda",
      "70e7b21e29e12906ee04dd5cba84977c",
    ],
  },
  6: {
    id: 6,
    type: "simple",
    author: "Iris Pouy",
    // title: "l'élection",
    path: "/planches/FANTOME/simple/wrk/",
    original: "/planches/FANTOME/fantome-Iris_Pouy.pdf",
    aspectRatio: 1240 / 1754,
    // default_box_id: 2,
    template: {
      //src: "/planches/SEIF/text/transparent.png",
      name: "template",
      aspectRatio: 1240 / 1754,
    },
    theme: 2,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "5d852f587023493a099ef530660a753c","73b1ee6608391c873f3045cbcf5f14ba","4fb51ac003dc69c358fbd2fb23ec7b11","067e4a94414bbb979054b63ec2e2e9e2","fc7a4e81accf63d5ae038374581f2742","62a9b2958d83538b6011b9e4604da89b","7c6567d846d64298b413bc022fd3a64b","0ae48b09d45223557b1234ab71208a33","dd788d8769f4a19072ec7107326093c8","11328e69d4dfc82efab9c9b2332cd02f","b04fdf07f6df7c4294b7e0e3486f14aa","827fe27066ed107fdbd7bafeaa8e968c","7ee771d310aec95d2fdbd847a11352e3","d6e981fcc77039b9f1a4bc06c7064c1e","60d0169b22633a3430ee9bbf02e9fdc2","1cacea58bbb5dd7bd59fcbe46563c262","2cdf096b6241e8405df7f914d8400ea4","e9f8f24185022f1eb062cdcc2280e67c","8a00cf7695b66f3f252a31092b184ba0","7c7dad5cf534f2095f4d1d9bb0bbf854","9c155c44232e6824f4708f6a3ef9bf9f","d47b6d4009e5d3949b1ecfa39e473a53","2ed88897aa4d4399017e2c55a632fc97","1d5d74bf45a779564534ac69ee4e9d72","d6f88ad0c94c927360351653e9c5a83a","41ae29eb3d6c6b0ccb2bde5bb5ee21fa","daa5f86f0618cd4a0b7d7c0c03ac76a3",
    ],

  },
  7: {
    id: 7,
    type: "simple",
    author: "Anne Boudart",
    // title: "l'élection",
    path: "/planches/ANNE/simple/wrk/",
    original: "/planches/ANNE/Projet-Anne.pdf",
    aspectRatio: 1240 / 1754,
    // default_box_id: 2,
    template: {
      //src: "/planches/SEIF/text/transparent.png",
      name: "template",
      aspectRatio: 1240 / 1754,
    },
    theme: 2,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "4efd0099bb022b0eaf592c9e78c9ddb8","5d6c27aff1d76a9740009da543e801e8","3a5a96196fa94339e12331ef7f87b3aa","c6566fcaad28451085e3acdd9d402e37","9d1a577b02a4e1a2cc39174576328581","a6fecb1fdae0815bd0b47cad0b3c2740","efe0457a666f700fe02968c7eb9269be","38f43f4a3815013e5ec7b64899f7d672","b431b44fe9419817320039fc011a4a89","c9574c5ddc9e029aa0f8af41bbd84452","172c839138d6bd4925539edb16441304","5e91c58f535053b13288a39f33857c39","6934fadba4aa0f6fd9fe57461a526ce0","c6467ec84d8dd76a9f5650889d9337af","c0c7ef1ebd81991c693aaeb8cef85c8d","aa5b9e60451f89e03bfad3304cb9862a","08a46f44f46857475b91a25237e0977d","7be10e9f74294c53593e8db363af2e54","614282a11b20b647045ad9eea48a2b74","ff8a29b6dcb5c61db42b65d5a421ce41","aea6e5bbf3607a473f93b21ce3878157","43077a7dd3600f9e5a13b97bf8f75541","f362d661b45b022905e3714c4961f318","fd769739efc9126453f0c5a32a90f8d9","8ef8778acec75b7172b8672c872f0955","6bb15c6ff9735ede7adc6e43bea45b95","6e5ae42d66b80316fc396a024c0f00ca", 
    ],
  },
  8: {
    id: 8,
    type: "simple",
    author: "Célia Rébillard",
    // title: "l'élection",
    path: "/planches/CELIA/simple/wrk/",
    original: "/planches/CELIA/Celia_stagiaire.pdf",
    aspectRatio: 1005 / 1475,
    // default_box_id: 2,
    template: {
      //src: "/planches/SEIF/text/transparent.png",
      name: "template",
      aspectRatio: 1005 / 1475,
    },
    theme: 2,
    // defaultLayouts: ["topLeft", "classic", "bottomLeft", "bottomRight"],
    defaultLayouts: ["classic", "classic", "classic"],
    boxes: [
      "7c47f33edd8933f658629a556402296e","4f96bb4bfb2ba10026a0d6ad13356880","e6ec9bc4638864b05011252443e08b64","6f88b5c26e85c4e916ea25f064858e1a","a19b07d1b684b9a54d5fb78e49b53aae","6f2b97d02ec9e502957c129cf8502ec4","9721d251f2780ab41e0426cff8ca6d32","247efc096c3146d6919ea9a778b7fe4a","af849439a1bc14a701eab5a5525e8239","f7f054f5c833832ed5644119e94002ab","fe286e31e11d73cc1695fe37a33ec6c9","869ee5716bccc27abef040ed4e05c959","4b014a1ac4fa99f5fe6c5c04c8816187","7c98b3135e9827a3c492b2a71ff90c3c","58ab387d4f84c31bf85170b9e881338d","3876b64457835472ff260655ee3903c3","bef5ac3cd3bd00e19745a14f6efa0f9b","34b9ad51fcd150d32e94f58ef6b372d8","047f89a7e7b47b9e8c4b1c9b29a9e0e9","3545e166530e62b6edfc214b45ffe087","f5303d2d3ae5baf9e03b47dd297ca789","f9f06dcb8120ea8f72a99f865af5e094","907195c0c82b690224d2f276b0eaf2d5","731d3ca85ba333c8b57b6c01ac2846b4","85dfffac012b037f6542ddaff889a4a2","a085bc3776d237f2cdf68fba5b36a8a1","274365258d54025d76f5c52a5fc61a80",
    ],
  },
  ids: [1, 2, /* 3, */ 4, 5, 6, 7, 9],
};
const _boxes = {
  e8635b1a3bc185f0fc3ffab8da71b395: {
    id: "e8635b1a3bc185f0fc3ffab8da71b395",
    name: "case10.png",
    original_position: 10,
    src: "/planches/ISMAEL/simple/wrk/e8635b1a3bc185f0fc3ffab8da71b395.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "79e38dc8e5f01c6a50aa6d0a4ad2941d": {
    id: "79e38dc8e5f01c6a50aa6d0a4ad2941d",
    name: "case11.png",
    original_position: 11,
    src: "/planches/ISMAEL/simple/wrk/79e38dc8e5f01c6a50aa6d0a4ad2941d.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  ddcd30a003c484fa2110a72f0b8e16d0: {
    id: "ddcd30a003c484fa2110a72f0b8e16d0",
    name: "case12.png",
    original_position: 12,
    src: "/planches/ISMAEL/simple/wrk/ddcd30a003c484fa2110a72f0b8e16d0.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "8b0dbb548905f17e5f26a719d69559eb": {
    id: "8b0dbb548905f17e5f26a719d69559eb",
    name: "case13.png",
    original_position: 13,
    src: "/planches/ISMAEL/simple/wrk/8b0dbb548905f17e5f26a719d69559eb.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "2992d85f6fc839fa148cbd05c395cb5e": {
    id: "2992d85f6fc839fa148cbd05c395cb5e",
    name: "case14.png",
    original_position: 14,
    src: "/planches/ISMAEL/simple/wrk/2992d85f6fc839fa148cbd05c395cb5e.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "3a635f591f3268598e12f6cbac2444ac": {
    id: "3a635f591f3268598e12f6cbac2444ac",
    name: "case15.png",
    original_position: 15,
    src: "/planches/ISMAEL/simple/wrk/3a635f591f3268598e12f6cbac2444ac.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  ef0c65ecc72ad4c4ab2f3928acd54116: {
    id: "ef0c65ecc72ad4c4ab2f3928acd54116",
    name: "case16.png",
    original_position: 16,
    src: "/planches/ISMAEL/simple/wrk/ef0c65ecc72ad4c4ab2f3928acd54116.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "1699c0874687b5c8eb71861207d6a2be": {
    id: "1699c0874687b5c8eb71861207d6a2be",
    name: "case17.png",
    original_position: 17,
    src: "/planches/ISMAEL/simple/wrk/1699c0874687b5c8eb71861207d6a2be.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "082d03ff2976b202637202caf17f9b25": {
    id: "082d03ff2976b202637202caf17f9b25",
    name: "case18.png",
    original_position: 18,
    src: "/planches/ISMAEL/simple/wrk/082d03ff2976b202637202caf17f9b25.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "7a3d4f67e8e45775da0d2b054139ffc7": {
    id: "7a3d4f67e8e45775da0d2b054139ffc7",
    name: "case19.png",
    original_position: 19,
    src: "/planches/ISMAEL/simple/wrk/7a3d4f67e8e45775da0d2b054139ffc7.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "5dee75a652008831bd518201ace57e31": {
    id: "5dee75a652008831bd518201ace57e31",
    name: "case1.png",
    original_position: 1,
    src: "/planches/ISMAEL/simple/wrk/5dee75a652008831bd518201ace57e31.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  f3bced202a198f327534d7f01b11d96c: {
    id: "f3bced202a198f327534d7f01b11d96c",
    name: "case20.png",
    original_position: 20,
    src: "/planches/ISMAEL/simple/wrk/f3bced202a198f327534d7f01b11d96c.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  d4d1631e30da0262e5e1762652fa45fe: {
    id: "d4d1631e30da0262e5e1762652fa45fe",
    name: "case21.png",
    original_position: 21,
    src: "/planches/ISMAEL/simple/wrk/d4d1631e30da0262e5e1762652fa45fe.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "7b4d5a530fd52d4871bd46b677d4bff4": {
    id: "7b4d5a530fd52d4871bd46b677d4bff4",
    name: "case22.png",
    original_position: 22,
    src: "/planches/ISMAEL/simple/wrk/7b4d5a530fd52d4871bd46b677d4bff4.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "407becd55e52feb9654117ac63cc048f": {
    id: "407becd55e52feb9654117ac63cc048f",
    name: "case23.png",
    original_position: 23,
    src: "/planches/ISMAEL/simple/wrk/407becd55e52feb9654117ac63cc048f.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "58011a29e2f23f64d97583021bcc4f67": {
    id: "58011a29e2f23f64d97583021bcc4f67",
    name: "case24.png",
    original_position: 24,
    src: "/planches/ISMAEL/simple/wrk/58011a29e2f23f64d97583021bcc4f67.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  f87362e5ff4c5634c1f51149a48e116f: {
    id: "f87362e5ff4c5634c1f51149a48e116f",
    name: "case25.png",
    original_position: 25,
    src: "/planches/ISMAEL/simple/wrk/f87362e5ff4c5634c1f51149a48e116f.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "67bb777e04d941bc7cac6019afc24be0": {
    id: "67bb777e04d941bc7cac6019afc24be0",
    name: "case26.png",
    original_position: 26,
    src: "/planches/ISMAEL/simple/wrk/67bb777e04d941bc7cac6019afc24be0.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "247221ff171d4476966e448de4dffff5": {
    id: "247221ff171d4476966e448de4dffff5",
    name: "case27.png",
    original_position: 27,
    src: "/planches/ISMAEL/simple/wrk/247221ff171d4476966e448de4dffff5.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  a1cbe333257c9e5eca82490b4becc77e: {
    id: "a1cbe333257c9e5eca82490b4becc77e",
    name: "case2.png",
    original_position: 2,
    src: "/planches/ISMAEL/simple/wrk/a1cbe333257c9e5eca82490b4becc77e.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  dfb212c11cd2567d7e251954fc54308c: {
    id: "dfb212c11cd2567d7e251954fc54308c",
    name: "case3.png",
    original_position: 3,
    src: "/planches/ISMAEL/simple/wrk/dfb212c11cd2567d7e251954fc54308c.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "0d328a42bc8c72dd9477dde693747b72": {
    id: "0d328a42bc8c72dd9477dde693747b72",
    name: "case4.png",
    original_position: 4,
    src: "/planches/ISMAEL/simple/wrk/0d328a42bc8c72dd9477dde693747b72.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  d4d20e2cfca4da0d94a7aedaddc73358: {
    id: "d4d20e2cfca4da0d94a7aedaddc73358",
    name: "case5.png",
    original_position: 5,
    src: "/planches/ISMAEL/simple/wrk/d4d20e2cfca4da0d94a7aedaddc73358.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  ecd964036ff7db6078a63d369d541d4a: {
    id: "ecd964036ff7db6078a63d369d541d4a",
    name: "case6.png",
    original_position: 6,
    src: "/planches/ISMAEL/simple/wrk/ecd964036ff7db6078a63d369d541d4a.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  "03d21bdf37cb4de67760d1573fe43755": {
    id: "03d21bdf37cb4de67760d1573fe43755",
    name: "case7.png",
    original_position: 7,
    src: "/planches/ISMAEL/simple/wrk/03d21bdf37cb4de67760d1573fe43755.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  ec93b2a1cffc7a9ce53237ae5bbaefe9: {
    id: "ec93b2a1cffc7a9ce53237ae5bbaefe9",
    name: "case8.png",
    original_position: 8,
    src: "/planches/ISMAEL/simple/wrk/ec93b2a1cffc7a9ce53237ae5bbaefe9.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  aeb167df8b99e52fd1c64cf5ddc25cc3: {
    id: "aeb167df8b99e52fd1c64cf5ddc25cc3",
    name: "case9.png",
    original_position: 9,
    src: "/planches/ISMAEL/simple/wrk/aeb167df8b99e52fd1c64cf5ddc25cc3.png",
    id_comic: 1,
    aspectRatio: 1005 / 1475,
    type: "simple",
  },
  /**** SEIF****/
  "6ef490537ae4b2c607a7dce5c071d2ee": {
    id: "6ef490537ae4b2c607a7dce5c071d2ee",
    name: "happyend.png",
    original_position: 27,
    src: "/planches/SEIF/simple/wrk/6ef490537ae4b2c607a7dce5c071d2ee.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "6354ca5357a177758fa0c3c1e699e256": {
    id: "6354ca5357a177758fa0c3c1e699e256",
    name: "unhappyen02.png",
    original_position: 27,
    src: "/planches/SEIF/simple/wrk/6354ca5357a177758fa0c3c1e699e256.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "8c4c488982fc10ee719eec7831ebbebc": {
    id: "8c4c488982fc10ee719eec7831ebbebc",
    name: "unhappyend01.png",
    original_position: 28,
    src: "/planches/SEIF/simple/wrk/8c4c488982fc10ee719eec7831ebbebc.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "90895c550ea320aa4b295d11e6e280e6": {
    id: "90895c550ea320aa4b295d11e6e280e6",
    name: "V01.png",
    original_position: 1,
    src: "/planches/SEIF/simple/wrk/90895c550ea320aa4b295d11e6e280e6.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "5e94a45d33ced279e3b0d3a1432f4852": {
    id: "5e94a45d33ced279e3b0d3a1432f4852",
    name: "V02.png",
    original_position: 2,
    src: "/planches/SEIF/simple/wrk/5e94a45d33ced279e3b0d3a1432f4852.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  ff71356ed0cf19a7126d64c3a73df598: {
    id: "ff71356ed0cf19a7126d64c3a73df598",
    name: "V03.png",
    original_position: 3,
    src: "/planches/SEIF/simple/wrk/ff71356ed0cf19a7126d64c3a73df598.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  ceca59b44018ca67878ac61e04d9489a: {
    id: "ceca59b44018ca67878ac61e04d9489a",
    name: "V04.png",
    original_position: 4,
    src: "/planches/SEIF/simple/wrk/ceca59b44018ca67878ac61e04d9489a.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  ab594105a64268064ae1243b79628483: {
    id: "ab594105a64268064ae1243b79628483",
    name: "V05.png",
    original_position: 5,
    src: "/planches/SEIF/simple/wrk/ab594105a64268064ae1243b79628483.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "908fea2c0bdfe7fa74f58f2c2093032c": {
    id: "908fea2c0bdfe7fa74f58f2c2093032c",
    name: "V06.png",
    original_position: 6,
    src: "/planches/SEIF/simple/wrk/908fea2c0bdfe7fa74f58f2c2093032c.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "1ed2329ed1f7203cb495ba455f7252c1": {
    id: "1ed2329ed1f7203cb495ba455f7252c1",
    name: "V07.png",
    original_position: 7,
    src: "/planches/SEIF/simple/wrk/1ed2329ed1f7203cb495ba455f7252c1.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "1ed8a9f5beb5bd666edaffbe688cab9a": {
    id: "1ed8a9f5beb5bd666edaffbe688cab9a",
    name: "V08.png",
    original_position: 8,
    src: "/planches/SEIF/simple/wrk/1ed8a9f5beb5bd666edaffbe688cab9a.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "32b9794a924938f5bb9cd0173b6a7da4": {
    id: "32b9794a924938f5bb9cd0173b6a7da4",
    name: "V09.png",
    original_position: 9,
    src: "/planches/SEIF/simple/wrk/32b9794a924938f5bb9cd0173b6a7da4.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  ceb439fb444966057958d1421895825a: {
    id: "ceb439fb444966057958d1421895825a",
    name: "V10.png",
    original_position: 10,
    src: "/planches/SEIF/simple/wrk/ceb439fb444966057958d1421895825a.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "6fc38194dd67ae27a91f104eaae50199": {
    id: "6fc38194dd67ae27a91f104eaae50199",
    name: "V11.png",
    original_position: 11,
    src: "/planches/SEIF/simple/wrk/6fc38194dd67ae27a91f104eaae50199.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  d82adf66f9d7985dd5cbb97ce4c93ec6: {
    id: "d82adf66f9d7985dd5cbb97ce4c93ec6",
    name: "V12.png",
    original_position: 12,
    src: "/planches/SEIF/simple/wrk/d82adf66f9d7985dd5cbb97ce4c93ec6.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  f62f6794663def2e91ee13c77367731a: {
    id: "f62f6794663def2e91ee13c77367731a",
    name: "V13.png",
    original_position: 13,
    src: "/planches/SEIF/simple/wrk/f62f6794663def2e91ee13c77367731a.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  d132e74ca3fda2d21b4d991274eba54e: {
    id: "d132e74ca3fda2d21b4d991274eba54e",
    name: "V14.png",
    original_position: 14,
    src: "/planches/SEIF/simple/wrk/d132e74ca3fda2d21b4d991274eba54e.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "3f4bb9c5f4a8115ea987518f6237b897": {
    id: "3f4bb9c5f4a8115ea987518f6237b897",
    name: "V15.png",
    original_position: 15,
    src: "/planches/SEIF/simple/wrk/3f4bb9c5f4a8115ea987518f6237b897.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "6b9bd09d5863dd64d96a2549d5cc615b": {
    id: "6b9bd09d5863dd64d96a2549d5cc615b",
    name: "V16.png",
    original_position: 16,
    src: "/planches/SEIF/simple/wrk/6b9bd09d5863dd64d96a2549d5cc615b.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  ec0534be29540e523cada43fec12db26: {
    id: "ec0534be29540e523cada43fec12db26",
    name: "V17.png",
    original_position: 17,
    src: "/planches/SEIF/simple/wrk/ec0534be29540e523cada43fec12db26.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "1fcd8c0fc358807dddf0a0f473c6a71d": {
    id: "1fcd8c0fc358807dddf0a0f473c6a71d",
    name: "V18.png",
    original_position: 18,
    src: "/planches/SEIF/simple/wrk/1fcd8c0fc358807dddf0a0f473c6a71d.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  c740237af7311cd095a37a9151a4cc51: {
    id: "c740237af7311cd095a37a9151a4cc51",
    name: "V19.png",
    original_position: 19,
    src: "/planches/SEIF/simple/wrk/c740237af7311cd095a37a9151a4cc51.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "8239cb50bc588b09fdcdf4d2b782a38f": {
    id: "8239cb50bc588b09fdcdf4d2b782a38f",
    name: "V20.png",
    original_position: 20,
    src: "/planches/SEIF/simple/wrk/8239cb50bc588b09fdcdf4d2b782a38f.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "2922f7ad6f9bbbffc2683505f2f7f901": {
    id: "2922f7ad6f9bbbffc2683505f2f7f901",
    name: "V21.png",
    original_position: 21,
    src: "/planches/SEIF/simple/wrk/2922f7ad6f9bbbffc2683505f2f7f901.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "20b03d63d5f7d14804af9c3644e0784c": {
    id: "20b03d63d5f7d14804af9c3644e0784c",
    name: "V22.png",
    original_position: 22,
    src: "/planches/SEIF/simple/wrk/20b03d63d5f7d14804af9c3644e0784c.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  edc94966f0167953571a3a75bb6d8517: {
    id: "edc94966f0167953571a3a75bb6d8517",
    name: "V23.png",
    original_position: 23,
    src: "/planches/SEIF/simple/wrk/edc94966f0167953571a3a75bb6d8517.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  ab9262f066992c1d7d192195b8b39081: {
    id: "ab9262f066992c1d7d192195b8b39081",
    name: "V24.png",
    original_position: 24,
    src: "/planches/SEIF/simple/wrk/ab9262f066992c1d7d192195b8b39081.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  b5cb29e48f06381856b3ff96a78591e8: {
    id: "b5cb29e48f06381856b3ff96a78591e8",
    name: "V25.png",
    original_position: 25,
    src: "/planches/SEIF/simple/wrk/b5cb29e48f06381856b3ff96a78591e8.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },
  "61dd14130bbb962dd6dd3447accbc292": {
    id: "61dd14130bbb962dd6dd3447accbc292",
    name: "V26.png",
    original_position: 26,
    src: "/planches/SEIF/simple/wrk/61dd14130bbb962dd6dd3447accbc292.jpg",
    id_comic: 2,
    aspectRatio: 3508 / 2480,
    type: "simple",
  },

  // SEIF original
  "1b24d783a1c7ee1a37460fa9ebcddfc7": {
    id: "1b24d783a1c7ee1a37460fa9ebcddfc7",
    name: "V01texte.png",
    original_position: 1,
    src: "/planches/SEIF/text/V01texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "9b0afe8b2c15a0a75e2ab85095d1b0e6": {
    id: "9b0afe8b2c15a0a75e2ab85095d1b0e6",
    name: "V02texte.png",
    original_position: 2,
    src: "/planches/SEIF/text/V02texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "90c601ea0548d3c8d1da984cd692aca3": {
    id: "90c601ea0548d3c8d1da984cd692aca3",
    name: "V03texte.png",
    original_position: 3,
    src: "/planches/SEIF/text/V03texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  c243c6996ef32f83a5535e9fe726e3c0: {
    id: "c243c6996ef32f83a5535e9fe726e3c0",
    name: "V04texte.png",
    original_position: 4,
    src: "/planches/SEIF/text/V04texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  c9773ea4676e336b06e5fd541c862f2d: {
    id: "c9773ea4676e336b06e5fd541c862f2d",
    name: "V05texte.png",
    original_position: 5,
    src: "/planches/SEIF/text/V05texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "93768a5bd4a4ac62c482fcecd7c13dc0": {
    id: "93768a5bd4a4ac62c482fcecd7c13dc0",
    name: "V06texte.png",
    original_position: 6,
    src: "/planches/SEIF/text/V06texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "2904f3c31622953c8dd7534694d2efcd": {
    id: "2904f3c31622953c8dd7534694d2efcd",
    name: "V07texte.png",
    original_position: 7,
    src: "/planches/SEIF/text/V07texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  b43ee0123bba9469637f7ad10fa4afb3: {
    id: "b43ee0123bba9469637f7ad10fa4afb3",
    name: "V08texte.png",
    original_position: 8,
    src: "/planches/SEIF/text/V08texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "5ef428090e35416fea7d55b3a6dbfffa": {
    id: "5ef428090e35416fea7d55b3a6dbfffa",
    name: "V09texte.png",
    original_position: 9,
    src: "/planches/SEIF/text/V09texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  d6187bfb593ccc9392a036ad1beb1a08: {
    id: "d6187bfb593ccc9392a036ad1beb1a08",
    name: "V10texte.png",
    original_position: 10,
    src: "/planches/SEIF/text/V10texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "83637393ce6a450b686a7127a4125b11": {
    id: "83637393ce6a450b686a7127a4125b11",
    name: "V11texte.png",
    original_position: 12,
    src: "/planches/SEIF/text/V11texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "894f4f7d505d0ccb9a5dd7438d1c9aff": {
    id: "894f4f7d505d0ccb9a5dd7438d1c9aff",
    name: "V12texte.png",
    original_position: 12,
    src: "/planches/SEIF/text/V12texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "3edff47c9fe84de2d1d90c7d91940cfb": {
    id: "3edff47c9fe84de2d1d90c7d91940cfb",
    name: "V13texte.png",
    original_position: 13,
    src: "/planches/SEIF/text/V13texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  ffb20ded5104544ca79dc99000524716: {
    id: "ffb20ded5104544ca79dc99000524716",
    name: "V14texte.png",
    original_position: 14,
    src: "/planches/SEIF/text/V14texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "2059e951b74185248ed0479ca5e410d0": {
    id: "2059e951b74185248ed0479ca5e410d0",
    name: "V15texte.png",
    original_position: 15,
    src: "/planches/SEIF/text/V15texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "8c5062df357685d7a72da13d6559fb6c": {
    id: "8c5062df357685d7a72da13d6559fb6c",
    name: "V16texte.png",
    original_position: 16,
    src: "/planches/SEIF/text/V16texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  a808cee599907cdb330939e55f5ad831: {
    id: "a808cee599907cdb330939e55f5ad831",
    name: "V17texte.png",
    original_position: 17,
    src: "/planches/SEIF/text/V17texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "14aa89360f0842add0c0f609ef10b105": {
    id: "14aa89360f0842add0c0f609ef10b105",
    name: "V18texte.png",
    original_position: 18,
    src: "/planches/SEIF/text/V18texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  b6ab306d2a543af8917be5d6b43f3738: {
    id: "b6ab306d2a543af8917be5d6b43f3738",
    name: "V19texte.png",
    original_position: 19,
    src: "/planches/SEIF/text/V19texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  b35852ea1c9a138b6705de1ec1748ca5: {
    id: "b35852ea1c9a138b6705de1ec1748ca5",
    name: "V20texte.png",
    original_position: 20,
    src: "/planches/SEIF/text/V20texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "0c3beecae811139d70ef3854318012d4": {
    id: "0c3beecae811139d70ef3854318012d4",
    name: "V21texte.png",
    original_position: 21,
    src: "/planches/SEIF/text/V21texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "66dee92bb55121df1c58746b5be8d531": {
    id: "66dee92bb55121df1c58746b5be8d531",
    name: "V22texte.png",
    original_position: 22,
    src: "/planches/SEIF/text/V22texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  d19032ac96c3e8504480b09d4cbb14c7: {
    id: "d19032ac96c3e8504480b09d4cbb14c7",
    name: "V23texte.png",
    original_position: 23,
    src: "/planches/SEIF/text/V23texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "11b5f1d60ec4c0000513dcd3ef925b49": {
    id: "11b5f1d60ec4c0000513dcd3ef925b49",
    name: "V24texte.png",
    original_position: 24,
    src: "/planches/SEIF/text/V24texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "1b5bd70f76e9987db8358041450c91d4": {
    id: "1b5bd70f76e9987db8358041450c91d4",
    name: "V25texte.png",
    original_position: 25,
    src: "/planches/SEIF/text/V25texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "2cae280bfd23e92c240df489644d5e27": {
    id: "2cae280bfd23e92c240df489644d5e27",
    name: "V26texte.png",
    original_position: 26,
    src: "/planches/SEIF/text/V26texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  c74293dc2c4b9883e9d1236413b36883: {
    id: "c74293dc2c4b9883e9d1236413b36883",
    name: "happyendtexte",
    original_position: 27,
    src: "/planches/SEIF/text/V27texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "2aec53b448a42f5b682ea55ee7459333": {
    id: "2aec53b448a42f5b682ea55ee7459333",
    name: "unhappyend01text",
    original_position: 28,
    src: "/planches/SEIF/text/V28texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  "7e3fdd9a64991c96fa931e26718f9383": {
    id: "7e3fdd9a64991c96fa931e26718f9383",
    name: "unhappyend02text",
    original_position: 29,
    src: "/planches/SEIF/text/V29texte.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },
  V26: {
    id: "V26",
    name: "3FinsPossibles",
    original_position: 29,
    src: "/planches/SEIF/text/V26-1.png",
    id_comic: 3,
    aspectRatio: 3508 / 2480,
    type: "text",
  },

  /*BD CHAT*/
  d2d6045ae08361dcc17e35969c2d11c1: {
    id: "d2d6045ae08361dcc17e35969c2d11c1",
    name: "10.png",
    original_position: 10,
    src: "/planches/CHAT/simple/wrk/d2d6045ae08361dcc17e35969c2d11c1.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  // b7f8306c1872716431d6d01ff28cc4ec: {
  //   id: "b7f8306c1872716431d6d01ff28cc4ec",
  //   name: "11.png",
  //   original_position: 11,
  //   src: "/planches/CHAT/simple/wrk/b7f8306c1872716431d6d01ff28cc4ec.png",
  //   id_comic: 4,
  //   aspectRatio: 1754 / 1240,
  //   type: "simple",
  // },
  "7f1fe990e4773756d010f6ce2ea2123d": {
    id: "7f1fe990e4773756d010f6ce2ea2123d",
    name: "12.png",
    original_position: 12,
    src: "/planches/CHAT/simple/wrk/7f1fe990e4773756d010f6ce2ea2123d.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "1ec151acad25a9d274b4232648bb6fbf": {
    id: "1ec151acad25a9d274b4232648bb6fbf",
    name: "13.png",
    original_position: 13,
    src: "/planches/CHAT/simple/wrk/1ec151acad25a9d274b4232648bb6fbf.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  f97d966e16286e4f73120a1d35bfcae6: {
    id: "f97d966e16286e4f73120a1d35bfcae6",
    name: "14.png",
    original_position: 14,
    src: "/planches/CHAT/simple/wrk/f97d966e16286e4f73120a1d35bfcae6.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "0b248bbb658e93e116802fb7d460477f": {
    id: "0b248bbb658e93e116802fb7d460477f",
    name: "15.png",
    original_position: 15,
    src: "/planches/CHAT/simple/wrk/0b248bbb658e93e116802fb7d460477f.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "87c4ec7ad438d7782cf13448751c485b": {
    id: "87c4ec7ad438d7782cf13448751c485b",
    name: "16.png",
    original_position: 16,
    src: "/planches/CHAT/simple/wrk/87c4ec7ad438d7782cf13448751c485b.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  dc9b6611c95cabec984803264cb9f78d: {
    id: "dc9b6611c95cabec984803264cb9f78d",
    name: "17.png",
    original_position: 17,
    src: "/planches/CHAT/simple/wrk/dc9b6611c95cabec984803264cb9f78d.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  e986ef3a84452acccd3c99abeb4af63d: {
    id: "e986ef3a84452acccd3c99abeb4af63d",
    name: "18.png",
    original_position: 18,
    src: "/planches/CHAT/simple/wrk/e986ef3a84452acccd3c99abeb4af63d.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "05ac5e253935da5bbd0d08e283e7eed0": {
    id: "05ac5e253935da5bbd0d08e283e7eed0",
    name: "19.png",
    original_position: 19,
    src: "/planches/CHAT/simple/wrk/05ac5e253935da5bbd0d08e283e7eed0.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  ae602ee6452020dc0f761a97609f7c06: {
    id: "ae602ee6452020dc0f761a97609f7c06",
    name: "1.png",
    original_position: 1,
    src: "/planches/CHAT/simple/wrk/ae602ee6452020dc0f761a97609f7c06.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "09af76e487492a5230e58df5c5d1b677": {
    id: "09af76e487492a5230e58df5c5d1b677",
    name: "20.png",
    original_position: 20,
    src: "/planches/CHAT/simple/wrk/09af76e487492a5230e58df5c5d1b677.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "022ef90c0e7d3ab8486861a4234d9389": {
    id: "022ef90c0e7d3ab8486861a4234d9389",
    name: "21.png",
    original_position: 21,
    src: "/planches/CHAT/simple/wrk/022ef90c0e7d3ab8486861a4234d9389.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "7e713e04f5e8763235d2b3b0ad6e0455": {
    id: "7e713e04f5e8763235d2b3b0ad6e0455",
    name: "22.png",
    original_position: 22,
    src: "/planches/CHAT/simple/wrk/7e713e04f5e8763235d2b3b0ad6e0455.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "95a6e6ea15955c95c50432cec9c2e02d": {
    id: "95a6e6ea15955c95c50432cec9c2e02d",
    name: "23.png",
    original_position: 23,
    src: "/planches/CHAT/simple/wrk/95a6e6ea15955c95c50432cec9c2e02d.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "7f688f8ecf647c0b481a89476436cb1d": {
    id: "7f688f8ecf647c0b481a89476436cb1d",
    name: "24.png",
    original_position: 24,
    src: "/planches/CHAT/simple/wrk/7f688f8ecf647c0b481a89476436cb1d.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  d5d4da5c36a0a671947ce343929f1ef2: {
    id: "d5d4da5c36a0a671947ce343929f1ef2",
    name: "25.png",
    original_position: 25,
    src: "/planches/CHAT/simple/wrk/d5d4da5c36a0a671947ce343929f1ef2.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "11413de409fd61c335a144372a4fb898": {
    id: "11413de409fd61c335a144372a4fb898",
    name: "26.png",
    original_position: 26,
    src: "/planches/CHAT/simple/wrk/11413de409fd61c335a144372a4fb898.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "14a90e00b2653c1f057694005cfe14df": {
    id: "14a90e00b2653c1f057694005cfe14df",
    name: "27.png",
    original_position: 27,
    src: "/planches/CHAT/simple/wrk/14a90e00b2653c1f057694005cfe14df.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  b38239e8e75ea1f8c5ba6546f273b7a2: {
    id: "b38239e8e75ea1f8c5ba6546f273b7a2",
    name: "28.png",
    original_position: 28,
    src: "/planches/CHAT/simple/wrk/b38239e8e75ea1f8c5ba6546f273b7a2.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "94f077dcbeba8872167acff9bfc4622b": {
    id: "94f077dcbeba8872167acff9bfc4622b",
    name: "2.png",
    original_position: 2,
    src: "/planches/CHAT/simple/wrk/94f077dcbeba8872167acff9bfc4622b.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "907fd713b6cda111fa72a60575b1e1ae": {
    id: "907fd713b6cda111fa72a60575b1e1ae",
    name: "3.png",
    original_position: 3,
    src: "/planches/CHAT/simple/wrk/907fd713b6cda111fa72a60575b1e1ae.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  d399c46d3f12826361b3f603acda6e3b: {
    id: "d399c46d3f12826361b3f603acda6e3b",
    name: "4.png",
    original_position: 4,
    src: "/planches/CHAT/simple/wrk/d399c46d3f12826361b3f603acda6e3b.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "19468659d5bc1b4c87ffe1bc20bc9612": {
    id: "19468659d5bc1b4c87ffe1bc20bc9612",
    name: "5.png",
    original_position: 5,
    src: "/planches/CHAT/simple/wrk/19468659d5bc1b4c87ffe1bc20bc9612.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  acd053cc6852544f09cc8d29e94c79e9: {
    id: "acd053cc6852544f09cc8d29e94c79e9",
    name: "6.png",
    original_position: 6,
    src: "/planches/CHAT/simple/wrk/acd053cc6852544f09cc8d29e94c79e9.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  fc526bb7533044fc8d57a1e8251bc39e: {
    id: "fc526bb7533044fc8d57a1e8251bc39e",
    name: "7.png",
    original_position: 7,
    src: "/planches/CHAT/simple/wrk/fc526bb7533044fc8d57a1e8251bc39e.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  a3ef9973d340a72c6449d3b1c7ea3fe9: {
    id: "a3ef9973d340a72c6449d3b1c7ea3fe9",
    name: "8.png",
    original_position: 8,
    src: "/planches/CHAT/simple/wrk/a3ef9973d340a72c6449d3b1c7ea3fe9.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  efc33e20d1305bde735578445ac60f82: {
    id: "efc33e20d1305bde735578445ac60f82",
    name: "9.png",
    original_position: 9,
    src: "/planches/CHAT/simple/wrk/efc33e20d1305bde735578445ac60f82.png",
    id_comic: 4,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },

  // BD ecole
  cf0e26bb1332543a5c0f72b0cf96a518: {
    id: "cf0e26bb1332543a5c0f72b0cf96a518",
    name: "10.png",
    original_position: 10,
    src: "/planches/ECOLE/simple/wrk/cf0e26bb1332543a5c0f72b0cf96a518.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "939903b4f17210903ce7c2029d29fe9f": {
    id: "939903b4f17210903ce7c2029d29fe9f",
    name: "11.png",
    original_position: 11,
    src: "/planches/ECOLE/simple/wrk/939903b4f17210903ce7c2029d29fe9f.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "8051d54ec9218f57199b5ec30135e7a3": {
    id: "8051d54ec9218f57199b5ec30135e7a3",
    name: "12.png",
    original_position: 12,
    src: "/planches/ECOLE/simple/wrk/8051d54ec9218f57199b5ec30135e7a3.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "72a3b76cf4f55a95d4fbc2257b77c02b": {
    id: "72a3b76cf4f55a95d4fbc2257b77c02b",
    name: "13.png",
    original_position: 13,
    src: "/planches/ECOLE/simple/wrk/72a3b76cf4f55a95d4fbc2257b77c02b.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "27cea807d75646eecea35a777a98460b": {
    id: "27cea807d75646eecea35a777a98460b",
    name: "14.png",
    original_position: 14,
    src: "/planches/ECOLE/simple/wrk/27cea807d75646eecea35a777a98460b.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  bfdec1fb7cfe57991aec3462d9d4632c: {
    id: "bfdec1fb7cfe57991aec3462d9d4632c",
    name: "15.png",
    original_position: 15,
    src: "/planches/ECOLE/simple/wrk/bfdec1fb7cfe57991aec3462d9d4632c.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "750583921880e484403a1a46f48c6bc5": {
    id: "750583921880e484403a1a46f48c6bc5",
    name: "16.png",
    original_position: 16,
    src: "/planches/ECOLE/simple/wrk/750583921880e484403a1a46f48c6bc5.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "6797c9d70e133e59ca61806110376b70": {
    id: "6797c9d70e133e59ca61806110376b70",
    name: "17.png",
    original_position: 17,
    src: "/planches/ECOLE/simple/wrk/6797c9d70e133e59ca61806110376b70.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  f2f6ab63855b11e032a9f118ae7f3a70: {
    id: "f2f6ab63855b11e032a9f118ae7f3a70",
    name: "18.png",
    original_position: 18,
    src: "/planches/ECOLE/simple/wrk/f2f6ab63855b11e032a9f118ae7f3a70.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  e5813163101cd18bb6a1234a1834421f: {
    id: "e5813163101cd18bb6a1234a1834421f",
    name: "19.png",
    original_position: 19,
    src: "/planches/ECOLE/simple/wrk/e5813163101cd18bb6a1234a1834421f.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  f41ad893ba1db638d4df972c55f2687a: {
    id: "f41ad893ba1db638d4df972c55f2687a",
    name: "1.png",
    original_position: 1,
    src: "/planches/ECOLE/simple/wrk/f41ad893ba1db638d4df972c55f2687a.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  e9c94c680ba9c62c5754c4e537705f10: {
    id: "e9c94c680ba9c62c5754c4e537705f10",
    name: "20.png",
    original_position: 20,
    src: "/planches/ECOLE/simple/wrk/e9c94c680ba9c62c5754c4e537705f10.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  b51ba621b87b08a5f324e14de570e0a7: {
    id: "b51ba621b87b08a5f324e14de570e0a7",
    name: "21.png",
    original_position: 21,
    src: "/planches/ECOLE/simple/wrk/b51ba621b87b08a5f324e14de570e0a7.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "1e02fe2d0911e377ad50057378a6a8ac": {
    id: "1e02fe2d0911e377ad50057378a6a8ac",
    name: "22.png",
    original_position: 22,
    src: "/planches/ECOLE/simple/wrk/1e02fe2d0911e377ad50057378a6a8ac.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "4a0755ee7412cae5cf6305cbcfa5dc90": {
    id: "4a0755ee7412cae5cf6305cbcfa5dc90",
    name: "23.png",
    original_position: 23,
    src: "/planches/ECOLE/simple/wrk/4a0755ee7412cae5cf6305cbcfa5dc90.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "34c69c3562482d8605139428d9763f87": {
    id: "34c69c3562482d8605139428d9763f87",
    name: "24.png",
    original_position: 24,
    src: "/planches/ECOLE/simple/wrk/34c69c3562482d8605139428d9763f87.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "7f7d74138966a52014424f8f53cd09e6": {
    id: "7f7d74138966a52014424f8f53cd09e6",
    name: "25.png",
    original_position: 25,
    src: "/planches/ECOLE/simple/wrk/7f7d74138966a52014424f8f53cd09e6.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  a1cdb65efc45aa3ac9a7e7599b147b17: {
    id: "a1cdb65efc45aa3ac9a7e7599b147b17",
    name: "26.png",
    original_position: 26,
    src: "/planches/ECOLE/simple/wrk/a1cdb65efc45aa3ac9a7e7599b147b17.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "8112beb2b3b4198a4392ff00787529fb": {
    id: "8112beb2b3b4198a4392ff00787529fb",
    name: "27.png",
    original_position: 27,
    src: "/planches/ECOLE/simple/wrk/8112beb2b3b4198a4392ff00787529fb.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  fc340b1b6d21a20853012a8c2f08b69a: {
    id: "fc340b1b6d21a20853012a8c2f08b69a",
    name: "2.png",
    original_position: 2,
    src: "/planches/ECOLE/simple/wrk/fc340b1b6d21a20853012a8c2f08b69a.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "06f926dd1d656524efa8ff6ca728abe5": {
    id: "06f926dd1d656524efa8ff6ca728abe5",
    name: "3.png",
    original_position: 3,
    src: "/planches/ECOLE/simple/wrk/06f926dd1d656524efa8ff6ca728abe5.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  c27aa4ee4a1dab8a454683eb182cd67b: {
    id: "c27aa4ee4a1dab8a454683eb182cd67b",
    name: "4.png",
    original_position: 4,
    src: "/planches/ECOLE/simple/wrk/c27aa4ee4a1dab8a454683eb182cd67b.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "5a8a153513184f32f1d490ca171ba1f4": {
    id: "5a8a153513184f32f1d490ca171ba1f4",
    name: "5.png",
    original_position: 5,
    src: "/planches/ECOLE/simple/wrk/5a8a153513184f32f1d490ca171ba1f4.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "46bcb09f0e622c4ab7dcff92a47dcc34": {
    id: "46bcb09f0e622c4ab7dcff92a47dcc34",
    name: "6.png",
    original_position: 6,
    src: "/planches/ECOLE/simple/wrk/46bcb09f0e622c4ab7dcff92a47dcc34.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "17803aee98e2327175e21edc37551ffb": {
    id: "17803aee98e2327175e21edc37551ffb",
    name: "new7.png",
    original_position: 7,
    src: "/planches/ECOLE/simple/wrk/17803aee98e2327175e21edc37551ffb.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "93234558744c37969e70a2008d604cda": {
    id: "93234558744c37969e70a2008d604cda",
    name: "8.png",
    original_position: 8,
    src: "/planches/ECOLE/simple/wrk/93234558744c37969e70a2008d604cda.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  "70e7b21e29e12906ee04dd5cba84977c": {
    id: "70e7b21e29e12906ee04dd5cba84977c",
    name: "9.png",
    original_position: 9,
    src: "/planches/ECOLE/simple/wrk/70e7b21e29e12906ee04dd5cba84977c.jpg",
    id_comic: 5,
    aspectRatio: 1754 / 1240,
    type: "simple",
  },
  /* Iris  : Fantôme */
  "5d852f587023493a099ef530660a753c" : {
    id:"5d852f587023493a099ef530660a753c",
    name:"Case10.png",
    original_position:10,
    src:"/planches/FANTOME/simple/wrk/5d852f587023493a099ef530660a753c.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "73b1ee6608391c873f3045cbcf5f14ba" : {
    id:"73b1ee6608391c873f3045cbcf5f14ba",
    name:"Case11.png",
    original_position:11,
    src:"/planches/FANTOME/simple/wrk/73b1ee6608391c873f3045cbcf5f14ba.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "4fb51ac003dc69c358fbd2fb23ec7b11" : {
    id:"4fb51ac003dc69c358fbd2fb23ec7b11",
    name:"Case12.png",
    original_position:12,
    src:"/planches/FANTOME/simple/wrk/4fb51ac003dc69c358fbd2fb23ec7b11.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "067e4a94414bbb979054b63ec2e2e9e2" : {
    id:"067e4a94414bbb979054b63ec2e2e9e2",
    name:"Case13.png",
    original_position:13,
    src:"/planches/FANTOME/simple/wrk/067e4a94414bbb979054b63ec2e2e9e2.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "fc7a4e81accf63d5ae038374581f2742" : {
    id:"fc7a4e81accf63d5ae038374581f2742",
    name:"Case14.png",
    original_position:14,
    src:"/planches/FANTOME/simple/wrk/fc7a4e81accf63d5ae038374581f2742.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "62a9b2958d83538b6011b9e4604da89b" : {
    id:"62a9b2958d83538b6011b9e4604da89b",
    name:"Case15.png",
    original_position:15,
    src:"/planches/FANTOME/simple/wrk/62a9b2958d83538b6011b9e4604da89b.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "7c6567d846d64298b413bc022fd3a64b" : {
    id:"7c6567d846d64298b413bc022fd3a64b",
    name:"Case16.png",
    original_position:16,
    src:"/planches/FANTOME/simple/wrk/7c6567d846d64298b413bc022fd3a64b.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "0ae48b09d45223557b1234ab71208a33" : {
    id:"0ae48b09d45223557b1234ab71208a33",
    name:"Case17.png",
    original_position:17,
    src:"/planches/FANTOME/simple/wrk/0ae48b09d45223557b1234ab71208a33.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "dd788d8769f4a19072ec7107326093c8" : {
    id:"dd788d8769f4a19072ec7107326093c8",
    name:"Case18.png",
    original_position:18,
    src:"/planches/FANTOME/simple/wrk/dd788d8769f4a19072ec7107326093c8.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "11328e69d4dfc82efab9c9b2332cd02f" : {
    id:"11328e69d4dfc82efab9c9b2332cd02f",
    name:"Case19.png",
    original_position:19,
    src:"/planches/FANTOME/simple/wrk/11328e69d4dfc82efab9c9b2332cd02f.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "b04fdf07f6df7c4294b7e0e3486f14aa" : {
    id:"b04fdf07f6df7c4294b7e0e3486f14aa",
    name:"Case1.png",
    original_position:1,
    src:"/planches/FANTOME/simple/wrk/b04fdf07f6df7c4294b7e0e3486f14aa.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "827fe27066ed107fdbd7bafeaa8e968c" : {
    id:"827fe27066ed107fdbd7bafeaa8e968c",
    name:"Case20.png",
    original_position:20,
    src:"/planches/FANTOME/simple/wrk/827fe27066ed107fdbd7bafeaa8e968c.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "7ee771d310aec95d2fdbd847a11352e3" : {
    id:"7ee771d310aec95d2fdbd847a11352e3",
    name:"Case21.png",
    original_position:21,
    src:"/planches/FANTOME/simple/wrk/7ee771d310aec95d2fdbd847a11352e3.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "d6e981fcc77039b9f1a4bc06c7064c1e" : {
    id:"d6e981fcc77039b9f1a4bc06c7064c1e",
    name:"Case22.png",
    original_position:22,
    src:"/planches/FANTOME/simple/wrk/d6e981fcc77039b9f1a4bc06c7064c1e.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "60d0169b22633a3430ee9bbf02e9fdc2" : {
    id:"60d0169b22633a3430ee9bbf02e9fdc2",
    name:"Case23.png",
    original_position:23,
    src:"/planches/FANTOME/simple/wrk/60d0169b22633a3430ee9bbf02e9fdc2.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "1cacea58bbb5dd7bd59fcbe46563c262" : {
    id:"1cacea58bbb5dd7bd59fcbe46563c262",
    name:"Case24.png",
    original_position:24,
    src:"/planches/FANTOME/simple/wrk/1cacea58bbb5dd7bd59fcbe46563c262.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "2cdf096b6241e8405df7f914d8400ea4" : {
    id:"2cdf096b6241e8405df7f914d8400ea4",
    name:"Case25.png",
    original_position:25,
    src:"/planches/FANTOME/simple/wrk/2cdf096b6241e8405df7f914d8400ea4.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "e9f8f24185022f1eb062cdcc2280e67c" : {
    id:"e9f8f24185022f1eb062cdcc2280e67c",
    name:"Case26.png",
    original_position:26,
    src:"/planches/FANTOME/simple/wrk/e9f8f24185022f1eb062cdcc2280e67c.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "8a00cf7695b66f3f252a31092b184ba0" : {
    id:"8a00cf7695b66f3f252a31092b184ba0",
    name:"Case27.png",
    original_position:27,
    src:"/planches/FANTOME/simple/wrk/8a00cf7695b66f3f252a31092b184ba0.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "7c7dad5cf534f2095f4d1d9bb0bbf854" : {
    id:"7c7dad5cf534f2095f4d1d9bb0bbf854",
    name:"Case2.png",
    original_position:2,
    src:"/planches/FANTOME/simple/wrk/7c7dad5cf534f2095f4d1d9bb0bbf854.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "9c155c44232e6824f4708f6a3ef9bf9f" : {
    id:"9c155c44232e6824f4708f6a3ef9bf9f",
    name:"Case3.png",
    original_position:3,
    src:"/planches/FANTOME/simple/wrk/9c155c44232e6824f4708f6a3ef9bf9f.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "d47b6d4009e5d3949b1ecfa39e473a53" : {
    id:"d47b6d4009e5d3949b1ecfa39e473a53",
    name:"Case4.png",
    original_position:4,
    src:"/planches/FANTOME/simple/wrk/d47b6d4009e5d3949b1ecfa39e473a53.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "2ed88897aa4d4399017e2c55a632fc97" : {
    id:"2ed88897aa4d4399017e2c55a632fc97",
    name:"Case5.png",
    original_position:5,
    src:"/planches/FANTOME/simple/wrk/2ed88897aa4d4399017e2c55a632fc97.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "1d5d74bf45a779564534ac69ee4e9d72" : {
    id:"1d5d74bf45a779564534ac69ee4e9d72",
    name:"Case6.png",
    original_position:6,
    src:"/planches/FANTOME/simple/wrk/1d5d74bf45a779564534ac69ee4e9d72.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "d6f88ad0c94c927360351653e9c5a83a" : {
    id:"d6f88ad0c94c927360351653e9c5a83a",
    name:"Case7.png",
    original_position:7,
    src:"/planches/FANTOME/simple/wrk/d6f88ad0c94c927360351653e9c5a83a.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "41ae29eb3d6c6b0ccb2bde5bb5ee21fa" : {
    id:"41ae29eb3d6c6b0ccb2bde5bb5ee21fa",
    name:"Case8.png",
    original_position:8,
    src:"/planches/FANTOME/simple/wrk/41ae29eb3d6c6b0ccb2bde5bb5ee21fa.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },
    "daa5f86f0618cd4a0b7d7c0c03ac76a3" : {
    id:"daa5f86f0618cd4a0b7d7c0c03ac76a3",
    name:"Case9.png",
    original_position:9,
    src:"/planches/FANTOME/simple/wrk/daa5f86f0618cd4a0b7d7c0c03ac76a3.jpg",
    id_comic:6,
    aspectRatio: 1240 / 1754,
    type:"simple",
  },

  /* Anne :  */
  "4efd0099bb022b0eaf592c9e78c9ddb8" : {
    id:"4efd0099bb022b0eaf592c9e78c9ddb8",
    name:"./case-10.png",
    original_position:10,
    src:"/planches/ANNE/simple/wrk/4efd0099bb022b0eaf592c9e78c9ddb8.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "5d6c27aff1d76a9740009da543e801e8" : {
    id:"5d6c27aff1d76a9740009da543e801e8",
    name:"./case-11.png",
    original_position:11,
    src:"/planches/ANNE/simple/wrk/5d6c27aff1d76a9740009da543e801e8.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "3a5a96196fa94339e12331ef7f87b3aa" : {
    id:"3a5a96196fa94339e12331ef7f87b3aa",
    name:"./case-12.png",
    original_position:12,
    src:"/planches/ANNE/simple/wrk/3a5a96196fa94339e12331ef7f87b3aa.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "c6566fcaad28451085e3acdd9d402e37" : {
    id:"c6566fcaad28451085e3acdd9d402e37",
    name:"./case-13.png",
    original_position:13,
    src:"/planches/ANNE/simple/wrk/c6566fcaad28451085e3acdd9d402e37.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "9d1a577b02a4e1a2cc39174576328581" : {
    id:"9d1a577b02a4e1a2cc39174576328581",
    name:"./case-14.png",
    original_position:14,
    src:"/planches/ANNE/simple/wrk/9d1a577b02a4e1a2cc39174576328581.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "a6fecb1fdae0815bd0b47cad0b3c2740" : {
    id:"a6fecb1fdae0815bd0b47cad0b3c2740",
    name:"./case-15.png",
    original_position:15,
    src:"/planches/ANNE/simple/wrk/a6fecb1fdae0815bd0b47cad0b3c2740.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "efe0457a666f700fe02968c7eb9269be" : {
    id:"efe0457a666f700fe02968c7eb9269be",
    name:"./case-16.png",
    original_position:16,
    src:"/planches/ANNE/simple/wrk/efe0457a666f700fe02968c7eb9269be.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "38f43f4a3815013e5ec7b64899f7d672" : {
    id:"38f43f4a3815013e5ec7b64899f7d672",
    name:"./case-17.png",
    original_position:17,
    src:"/planches/ANNE/simple/wrk/38f43f4a3815013e5ec7b64899f7d672.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "b431b44fe9419817320039fc011a4a89" : {
    id:"b431b44fe9419817320039fc011a4a89",
    name:"./case-18.png",
    original_position:18,
    src:"/planches/ANNE/simple/wrk/b431b44fe9419817320039fc011a4a89.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "c9574c5ddc9e029aa0f8af41bbd84452" : {
    id:"c9574c5ddc9e029aa0f8af41bbd84452",
    name:"./case-19.png",
    original_position:19,
    src:"/planches/ANNE/simple/wrk/c9574c5ddc9e029aa0f8af41bbd84452.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "172c839138d6bd4925539edb16441304" : {
    id:"172c839138d6bd4925539edb16441304",
    name:"./case-1.png",
    original_position:1,
    src:"/planches/ANNE/simple/wrk/172c839138d6bd4925539edb16441304.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "5e91c58f535053b13288a39f33857c39" : {
    id:"5e91c58f535053b13288a39f33857c39",
    name:"./case-20.png",
    original_position:20,
    src:"/planches/ANNE/simple/wrk/5e91c58f535053b13288a39f33857c39.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "6934fadba4aa0f6fd9fe57461a526ce0" : {
    id:"6934fadba4aa0f6fd9fe57461a526ce0",
    name:"./case-21.png",
    original_position:21,
    src:"/planches/ANNE/simple/wrk/6934fadba4aa0f6fd9fe57461a526ce0.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "c6467ec84d8dd76a9f5650889d9337af" : {
    id:"c6467ec84d8dd76a9f5650889d9337af",
    name:"./case-22.png",
    original_position:22,
    src:"/planches/ANNE/simple/wrk/c6467ec84d8dd76a9f5650889d9337af.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "c0c7ef1ebd81991c693aaeb8cef85c8d" : {
    id:"c0c7ef1ebd81991c693aaeb8cef85c8d",
    name:"./case-23.png",
    original_position:23,
    src:"/planches/ANNE/simple/wrk/c0c7ef1ebd81991c693aaeb8cef85c8d.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "aa5b9e60451f89e03bfad3304cb9862a" : {
    id:"aa5b9e60451f89e03bfad3304cb9862a",
    name:"./case-24.png",
    original_position:24,
    src:"/planches/ANNE/simple/wrk/aa5b9e60451f89e03bfad3304cb9862a.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "08a46f44f46857475b91a25237e0977d" : {
    id:"08a46f44f46857475b91a25237e0977d",
    name:"./case-25.png",
    original_position:25,
    src:"/planches/ANNE/simple/wrk/08a46f44f46857475b91a25237e0977d.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "7be10e9f74294c53593e8db363af2e54" : {
    id:"7be10e9f74294c53593e8db363af2e54",
    name:"./case-26.png",
    original_position:26,
    src:"/planches/ANNE/simple/wrk/7be10e9f74294c53593e8db363af2e54.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "614282a11b20b647045ad9eea48a2b74" : {
    id:"614282a11b20b647045ad9eea48a2b74",
    name:"./case-27.png",
    original_position:27,
    src:"/planches/ANNE/simple/wrk/614282a11b20b647045ad9eea48a2b74.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "ff8a29b6dcb5c61db42b65d5a421ce41" : {
    id:"ff8a29b6dcb5c61db42b65d5a421ce41",
    name:"./case-2.png",
    original_position:2,
    src:"/planches/ANNE/simple/wrk/ff8a29b6dcb5c61db42b65d5a421ce41.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "aea6e5bbf3607a473f93b21ce3878157" : {
    id:"aea6e5bbf3607a473f93b21ce3878157",
    name:"./case-3.png",
    original_position:3,
    src:"/planches/ANNE/simple/wrk/aea6e5bbf3607a473f93b21ce3878157.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "43077a7dd3600f9e5a13b97bf8f75541" : {
    id:"43077a7dd3600f9e5a13b97bf8f75541",
    name:"./case-4.png",
    original_position:4,
    src:"/planches/ANNE/simple/wrk/43077a7dd3600f9e5a13b97bf8f75541.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "f362d661b45b022905e3714c4961f318" : {
    id:"f362d661b45b022905e3714c4961f318",
    name:"./case-5.png",
    original_position:5,
    src:"/planches/ANNE/simple/wrk/f362d661b45b022905e3714c4961f318.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "fd769739efc9126453f0c5a32a90f8d9" : {
    id:"fd769739efc9126453f0c5a32a90f8d9",
    name:"./case-6.png",
    original_position:6,
    src:"/planches/ANNE/simple/wrk/fd769739efc9126453f0c5a32a90f8d9.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "8ef8778acec75b7172b8672c872f0955" : {
    id:"8ef8778acec75b7172b8672c872f0955",
    name:"./case-7.png",
    original_position:7,
    src:"/planches/ANNE/simple/wrk/8ef8778acec75b7172b8672c872f0955.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "6bb15c6ff9735ede7adc6e43bea45b95" : {
    id:"6bb15c6ff9735ede7adc6e43bea45b95",
    name:"./case-8.png",
    original_position:8,
    src:"/planches/ANNE/simple/wrk/6bb15c6ff9735ede7adc6e43bea45b95.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },
    "6e5ae42d66b80316fc396a024c0f00ca" : {
    id:"6e5ae42d66b80316fc396a024c0f00ca",
    name:"./case-9.png",
    original_position:9,
    src:"/planches/ANNE/simple/wrk/6e5ae42d66b80316fc396a024c0f00ca.jpg",
    id_comic:7,
    aspectRatio:1240 / 1754,
    type:"simple",
  },

  // Célia
  "7c47f33edd8933f658629a556402296e" : {
    id:"7c47f33edd8933f658629a556402296e",
    name:"CASE_10.png",
    original_position:10,
    src:"/planches/CELIA/simple/wrk/7c47f33edd8933f658629a556402296e.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "4f96bb4bfb2ba10026a0d6ad13356880" : {
    id:"4f96bb4bfb2ba10026a0d6ad13356880",
    name:"CASE_11.png",
    original_position:11,
    src:"/planches/CELIA/simple/wrk/4f96bb4bfb2ba10026a0d6ad13356880.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "e6ec9bc4638864b05011252443e08b64" : {
    id:"e6ec9bc4638864b05011252443e08b64",
    name:"CASE_12.png",
    original_position:12,
    src:"/planches/CELIA/simple/wrk/e6ec9bc4638864b05011252443e08b64.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "6f88b5c26e85c4e916ea25f064858e1a" : {
    id:"6f88b5c26e85c4e916ea25f064858e1a",
    name:"CASE_13.png",
    original_position:13,
    src:"/planches/CELIA/simple/wrk/6f88b5c26e85c4e916ea25f064858e1a.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "a19b07d1b684b9a54d5fb78e49b53aae" : {
    id:"a19b07d1b684b9a54d5fb78e49b53aae",
    name:"CASE_14.png",
    original_position:14,
    src:"/planches/CELIA/simple/wrk/a19b07d1b684b9a54d5fb78e49b53aae.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "6f2b97d02ec9e502957c129cf8502ec4" : {
    id:"6f2b97d02ec9e502957c129cf8502ec4",
    name:"CASE_15.png",
    original_position:15,
    src:"/planches/CELIA/simple/wrk/6f2b97d02ec9e502957c129cf8502ec4.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "9721d251f2780ab41e0426cff8ca6d32" : {
    id:"9721d251f2780ab41e0426cff8ca6d32",
    name:"CASE_16.png",
    original_position:16,
    src:"/planches/CELIA/simple/wrk/9721d251f2780ab41e0426cff8ca6d32.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "247efc096c3146d6919ea9a778b7fe4a" : {
    id:"247efc096c3146d6919ea9a778b7fe4a",
    name:"CASE_17.png",
    original_position:17,
    src:"/planches/CELIA/simple/wrk/247efc096c3146d6919ea9a778b7fe4a.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "af849439a1bc14a701eab5a5525e8239" : {
    id:"af849439a1bc14a701eab5a5525e8239",
    name:"CASE_18.png",
    original_position:18,
    src:"/planches/CELIA/simple/wrk/af849439a1bc14a701eab5a5525e8239.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "f7f054f5c833832ed5644119e94002ab" : {
    id:"f7f054f5c833832ed5644119e94002ab",
    name:"CASE_19.png",
    original_position:19,
    src:"/planches/CELIA/simple/wrk/f7f054f5c833832ed5644119e94002ab.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "fe286e31e11d73cc1695fe37a33ec6c9" : {
    id:"fe286e31e11d73cc1695fe37a33ec6c9",
    name:"CASE_1.png",
    original_position:1,
    src:"/planches/CELIA/simple/wrk/fe286e31e11d73cc1695fe37a33ec6c9.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "869ee5716bccc27abef040ed4e05c959" : {
    id:"869ee5716bccc27abef040ed4e05c959",
    name:"CASE_20.png",
    original_position:20,
    src:"/planches/CELIA/simple/wrk/869ee5716bccc27abef040ed4e05c959.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "4b014a1ac4fa99f5fe6c5c04c8816187" : {
    id:"4b014a1ac4fa99f5fe6c5c04c8816187",
    name:"CASE_21.png",
    original_position:21,
    src:"/planches/CELIA/simple/wrk/4b014a1ac4fa99f5fe6c5c04c8816187.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "7c98b3135e9827a3c492b2a71ff90c3c" : {
    id:"7c98b3135e9827a3c492b2a71ff90c3c",
    name:"CASE_22.png",
    original_position:22,
    src:"/planches/CELIA/simple/wrk/7c98b3135e9827a3c492b2a71ff90c3c.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "58ab387d4f84c31bf85170b9e881338d" : {
    id:"58ab387d4f84c31bf85170b9e881338d",
    name:"CASE_23.png",
    original_position:23,
    src:"/planches/CELIA/simple/wrk/58ab387d4f84c31bf85170b9e881338d.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "3876b64457835472ff260655ee3903c3" : {
    id:"3876b64457835472ff260655ee3903c3",
    name:"CASE_24.png",
    original_position:24,
    src:"/planches/CELIA/simple/wrk/3876b64457835472ff260655ee3903c3.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "bef5ac3cd3bd00e19745a14f6efa0f9b" : {
    id:"bef5ac3cd3bd00e19745a14f6efa0f9b",
    name:"CASE_25.png",
    original_position:25,
    src:"/planches/CELIA/simple/wrk/bef5ac3cd3bd00e19745a14f6efa0f9b.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "34b9ad51fcd150d32e94f58ef6b372d8" : {
    id:"34b9ad51fcd150d32e94f58ef6b372d8",
    name:"CASE_26.png",
    original_position:26,
    src:"/planches/CELIA/simple/wrk/34b9ad51fcd150d32e94f58ef6b372d8.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "047f89a7e7b47b9e8c4b1c9b29a9e0e9" : {
    id:"047f89a7e7b47b9e8c4b1c9b29a9e0e9",
    name:"CASE_27.png",
    original_position:27,
    src:"/planches/CELIA/simple/wrk/047f89a7e7b47b9e8c4b1c9b29a9e0e9.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "3545e166530e62b6edfc214b45ffe087" : {
    id:"3545e166530e62b6edfc214b45ffe087",
    name:"CASE_2.png",
    original_position:2,
    src:"/planches/CELIA/simple/wrk/3545e166530e62b6edfc214b45ffe087.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "f5303d2d3ae5baf9e03b47dd297ca789" : {
    id:"f5303d2d3ae5baf9e03b47dd297ca789",
    name:"CASE_3.png",
    original_position:3,
    src:"/planches/CELIA/simple/wrk/f5303d2d3ae5baf9e03b47dd297ca789.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "f9f06dcb8120ea8f72a99f865af5e094" : {
    id:"f9f06dcb8120ea8f72a99f865af5e094",
    name:"CASE_4.png",
    original_position:4,
    src:"/planches/CELIA/simple/wrk/f9f06dcb8120ea8f72a99f865af5e094.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "907195c0c82b690224d2f276b0eaf2d5" : {
    id:"907195c0c82b690224d2f276b0eaf2d5",
    name:"CASE_5.png",
    original_position:5,
    src:"/planches/CELIA/simple/wrk/907195c0c82b690224d2f276b0eaf2d5.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "731d3ca85ba333c8b57b6c01ac2846b4" : {
    id:"731d3ca85ba333c8b57b6c01ac2846b4",
    name:"CASE_6.png",
    original_position:6,
    src:"/planches/CELIA/simple/wrk/731d3ca85ba333c8b57b6c01ac2846b4.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "85dfffac012b037f6542ddaff889a4a2" : {
    id:"85dfffac012b037f6542ddaff889a4a2",
    name:"CASE_7.png",
    original_position:7,
    src:"/planches/CELIA/simple/wrk/85dfffac012b037f6542ddaff889a4a2.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "a085bc3776d237f2cdf68fba5b36a8a1" : {
    id:"a085bc3776d237f2cdf68fba5b36a8a1",
    name:"CASE_8.png",
    original_position:8,
    src:"/planches/CELIA/simple/wrk/a085bc3776d237f2cdf68fba5b36a8a1.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },
  "274365258d54025d76f5c52a5fc61a80" : {
    id:"274365258d54025d76f5c52a5fc61a80",
    name:"CASE_9.png",
    original_position:9,
    src:"/planches/CELIA/simple/wrk/274365258d54025d76f5c52a5fc61a80.png",
    id_comic:8,
    aspectRatio:1005/1475 ,
    type:"simple"
  },



  /* eslint-disable */
  ids: [
    //ISmail
    "e8635b1a3bc185f0fc3ffab8da71b395", "79e38dc8e5f01c6a50aa6d0a4ad2941d", "ddcd30a003c484fa2110a72f0b8e16d0", "8b0dbb548905f17e5f26a719d69559eb", "2992d85f6fc839fa148cbd05c395cb5e", "3a635f591f3268598e12f6cbac2444ac", "ef0c65ecc72ad4c4ab2f3928acd54116", "1699c0874687b5c8eb71861207d6a2be", "082d03ff2976b202637202caf17f9b25", "7a3d4f67e8e45775da0d2b054139ffc7", "5dee75a652008831bd518201ace57e31", "f3bced202a198f327534d7f01b11d96c", "d4d1631e30da0262e5e1762652fa45fe", "7b4d5a530fd52d4871bd46b677d4bff4", "407becd55e52feb9654117ac63cc048f", "58011a29e2f23f64d97583021bcc4f67", "f87362e5ff4c5634c1f51149a48e116f", "67bb777e04d941bc7cac6019afc24be0", "247221ff171d4476966e448de4dffff5", "a1cbe333257c9e5eca82490b4becc77e", "dfb212c11cd2567d7e251954fc54308c", "0d328a42bc8c72dd9477dde693747b72", "d4d20e2cfca4da0d94a7aedaddc73358", "ecd964036ff7db6078a63d369d541d4a", "03d21bdf37cb4de67760d1573fe43755", "ec93b2a1cffc7a9ce53237ae5bbaefe9", "aeb167df8b99e52fd1c64cf5ddc25cc3",
    //SEIF
    "6ef490537ae4b2c607a7dce5c071d2ee","6354ca5357a177758fa0c3c1e699e256","8c4c488982fc10ee719eec7831ebbebc","90895c550ea320aa4b295d11e6e280e6","5e94a45d33ced279e3b0d3a1432f4852","ff71356ed0cf19a7126d64c3a73df598","ceca59b44018ca67878ac61e04d9489a","ab594105a64268064ae1243b79628483","908fea2c0bdfe7fa74f58f2c2093032c","1ed2329ed1f7203cb495ba455f7252c1","1ed8a9f5beb5bd666edaffbe688cab9a","32b9794a924938f5bb9cd0173b6a7da4","ceb439fb444966057958d1421895825a","6fc38194dd67ae27a91f104eaae50199","d82adf66f9d7985dd5cbb97ce4c93ec6","f62f6794663def2e91ee13c77367731a","d132e74ca3fda2d21b4d991274eba54e","3f4bb9c5f4a8115ea987518f6237b897","6b9bd09d5863dd64d96a2549d5cc615b","ec0534be29540e523cada43fec12db26","1fcd8c0fc358807dddf0a0f473c6a71d","c740237af7311cd095a37a9151a4cc51","8239cb50bc588b09fdcdf4d2b782a38f","2922f7ad6f9bbbffc2683505f2f7f901","20b03d63d5f7d14804af9c3644e0784c","edc94966f0167953571a3a75bb6d8517","ab9262f066992c1d7d192195b8b39081","b5cb29e48f06381856b3ff96a78591e8","61dd14130bbb962dd6dd3447accbc292",
    //SEIF Original
    "1b24d783a1c7ee1a37460fa9ebcddfc7","9b0afe8b2c15a0a75e2ab85095d1b0e6","90c601ea0548d3c8d1da984cd692aca3","c243c6996ef32f83a5535e9fe726e3c0","c9773ea4676e336b06e5fd541c862f2d","93768a5bd4a4ac62c482fcecd7c13dc0","2904f3c31622953c8dd7534694d2efcd","b43ee0123bba9469637f7ad10fa4afb3","5ef428090e35416fea7d55b3a6dbfffa","d6187bfb593ccc9392a036ad1beb1a08","83637393ce6a450b686a7127a4125b11", "894f4f7d505d0ccb9a5dd7438d1c9aff","3edff47c9fe84de2d1d90c7d91940cfb","ffb20ded5104544ca79dc99000524716","2059e951b74185248ed0479ca5e410d0","8c5062df357685d7a72da13d6559fb6c","a808cee599907cdb330939e55f5ad831","14aa89360f0842add0c0f609ef10b105","b6ab306d2a543af8917be5d6b43f3738","b35852ea1c9a138b6705de1ec1748ca5","0c3beecae811139d70ef3854318012d4","66dee92bb55121df1c58746b5be8d531","d19032ac96c3e8504480b09d4cbb14c7","11b5f1d60ec4c0000513dcd3ef925b49","1b5bd70f76e9987db8358041450c91d4","2cae280bfd23e92c240df489644d5e27",
    "c74293dc2c4b9883e9d1236413b36883", // V27
    "2aec53b448a42f5b682ea55ee7459333", // V28
    "7e3fdd9a64991c96fa931e26718f9383", // V29
    "V26",
    //CHAT 
    "d2d6045ae08361dcc17e35969c2d11c1",/*"b7f8306c1872716431d6d01ff28cc4ec"*/,"7f1fe990e4773756d010f6ce2ea2123d","1ec151acad25a9d274b4232648bb6fbf","f97d966e16286e4f73120a1d35bfcae6","0b248bbb658e93e116802fb7d460477f","87c4ec7ad438d7782cf13448751c485b","dc9b6611c95cabec984803264cb9f78d","e986ef3a84452acccd3c99abeb4af63d","05ac5e253935da5bbd0d08e283e7eed0","ae602ee6452020dc0f761a97609f7c06","09af76e487492a5230e58df5c5d1b677","022ef90c0e7d3ab8486861a4234d9389","7e713e04f5e8763235d2b3b0ad6e0455","95a6e6ea15955c95c50432cec9c2e02d","7f688f8ecf647c0b481a89476436cb1d","d5d4da5c36a0a671947ce343929f1ef2","11413de409fd61c335a144372a4fb898","14a90e00b2653c1f057694005cfe14df","b38239e8e75ea1f8c5ba6546f273b7a2","94f077dcbeba8872167acff9bfc4622b","907fd713b6cda111fa72a60575b1e1ae","d399c46d3f12826361b3f603acda6e3b","19468659d5bc1b4c87ffe1bc20bc9612","acd053cc6852544f09cc8d29e94c79e9","fc526bb7533044fc8d57a1e8251bc39e","a3ef9973d340a72c6449d3b1c7ea3fe9","efc33e20d1305bde735578445ac60f82", 
    //ECOLE
    "cf0e26bb1332543a5c0f72b0cf96a518","939903b4f17210903ce7c2029d29fe9f","8051d54ec9218f57199b5ec30135e7a3","72a3b76cf4f55a95d4fbc2257b77c02b","27cea807d75646eecea35a777a98460b","bfdec1fb7cfe57991aec3462d9d4632c","750583921880e484403a1a46f48c6bc5","6797c9d70e133e59ca61806110376b70","f2f6ab63855b11e032a9f118ae7f3a70","e5813163101cd18bb6a1234a1834421f","f41ad893ba1db638d4df972c55f2687a","e9c94c680ba9c62c5754c4e537705f10","b51ba621b87b08a5f324e14de570e0a7","1e02fe2d0911e377ad50057378a6a8ac","4a0755ee7412cae5cf6305cbcfa5dc90","34c69c3562482d8605139428d9763f87","7f7d74138966a52014424f8f53cd09e6","a1cdb65efc45aa3ac9a7e7599b147b17","8112beb2b3b4198a4392ff00787529fb","fc340b1b6d21a20853012a8c2f08b69a","06f926dd1d656524efa8ff6ca728abe5","c27aa4ee4a1dab8a454683eb182cd67b","5a8a153513184f32f1d490ca171ba1f4","46bcb09f0e622c4ab7dcff92a47dcc34","17803aee98e2327175e21edc37551ffb","93234558744c37969e70a2008d604cda","70e7b21e29e12906ee04dd5cba84977c",
    //FANTOME
    "5d852f587023493a099ef530660a753c","73b1ee6608391c873f3045cbcf5f14ba","4fb51ac003dc69c358fbd2fb23ec7b11","067e4a94414bbb979054b63ec2e2e9e2","fc7a4e81accf63d5ae038374581f2742","62a9b2958d83538b6011b9e4604da89b","7c6567d846d64298b413bc022fd3a64b","0ae48b09d45223557b1234ab71208a33","dd788d8769f4a19072ec7107326093c8","11328e69d4dfc82efab9c9b2332cd02f","b04fdf07f6df7c4294b7e0e3486f14aa","827fe27066ed107fdbd7bafeaa8e968c","7ee771d310aec95d2fdbd847a11352e3","d6e981fcc77039b9f1a4bc06c7064c1e","60d0169b22633a3430ee9bbf02e9fdc2","1cacea58bbb5dd7bd59fcbe46563c262","2cdf096b6241e8405df7f914d8400ea4","e9f8f24185022f1eb062cdcc2280e67c","8a00cf7695b66f3f252a31092b184ba0","7c7dad5cf534f2095f4d1d9bb0bbf854","9c155c44232e6824f4708f6a3ef9bf9f","d47b6d4009e5d3949b1ecfa39e473a53","2ed88897aa4d4399017e2c55a632fc97","1d5d74bf45a779564534ac69ee4e9d72","d6f88ad0c94c927360351653e9c5a83a","41ae29eb3d6c6b0ccb2bde5bb5ee21fa","daa5f86f0618cd4a0b7d7c0c03ac76a3",
    //ANNE 
    "4efd0099bb022b0eaf592c9e78c9ddb8","5d6c27aff1d76a9740009da543e801e8","3a5a96196fa94339e12331ef7f87b3aa","c6566fcaad28451085e3acdd9d402e37","9d1a577b02a4e1a2cc39174576328581","a6fecb1fdae0815bd0b47cad0b3c2740","efe0457a666f700fe02968c7eb9269be","38f43f4a3815013e5ec7b64899f7d672","b431b44fe9419817320039fc011a4a89","c9574c5ddc9e029aa0f8af41bbd84452","172c839138d6bd4925539edb16441304","5e91c58f535053b13288a39f33857c39","6934fadba4aa0f6fd9fe57461a526ce0","c6467ec84d8dd76a9f5650889d9337af","c0c7ef1ebd81991c693aaeb8cef85c8d","aa5b9e60451f89e03bfad3304cb9862a","08a46f44f46857475b91a25237e0977d","7be10e9f74294c53593e8db363af2e54","614282a11b20b647045ad9eea48a2b74","ff8a29b6dcb5c61db42b65d5a421ce41","aea6e5bbf3607a473f93b21ce3878157","43077a7dd3600f9e5a13b97bf8f75541","f362d661b45b022905e3714c4961f318","fd769739efc9126453f0c5a32a90f8d9","8ef8778acec75b7172b8672c872f0955","6bb15c6ff9735ede7adc6e43bea45b95","6e5ae42d66b80316fc396a024c0f00ca", 
    // Célia
    "7c47f33edd8933f658629a556402296e","4f96bb4bfb2ba10026a0d6ad13356880","e6ec9bc4638864b05011252443e08b64","6f88b5c26e85c4e916ea25f064858e1a","a19b07d1b684b9a54d5fb78e49b53aae","6f2b97d02ec9e502957c129cf8502ec4","9721d251f2780ab41e0426cff8ca6d32","247efc096c3146d6919ea9a778b7fe4a","af849439a1bc14a701eab5a5525e8239","f7f054f5c833832ed5644119e94002ab","fe286e31e11d73cc1695fe37a33ec6c9","869ee5716bccc27abef040ed4e05c959","4b014a1ac4fa99f5fe6c5c04c8816187","7c98b3135e9827a3c492b2a71ff90c3c","58ab387d4f84c31bf85170b9e881338d","3876b64457835472ff260655ee3903c3","bef5ac3cd3bd00e19745a14f6efa0f9b","34b9ad51fcd150d32e94f58ef6b372d8","047f89a7e7b47b9e8c4b1c9b29a9e0e9","3545e166530e62b6edfc214b45ffe087","f5303d2d3ae5baf9e03b47dd297ca789","f9f06dcb8120ea8f72a99f865af5e094","907195c0c82b690224d2f276b0eaf2d5","731d3ca85ba333c8b57b6c01ac2846b4","85dfffac012b037f6542ddaff889a4a2","a085bc3776d237f2cdf68fba5b36a8a1","274365258d54025d76f5c52a5fc61a80",
  ],
  /* eslint-enable */
};

// const boxesSeif = {
//     ...denormalize(_boxes).find(e => e.id_comic==2).map((e) => e.src=_comics[e.id_comic].path+e.src)
// };

export default {
  _boxes: () => _boxes,
  _comics: () => _comics,
  _themes: ()  => _themes,
  // boxesSeif: () => boxesSeif,
  loadBoxes(cb) {
    setTimeout(() => cb(_boxes), 100);
  },
  loadComics(cb) {
    setTimeout(() => cb(_comics), 10);
  },
  loadThemes(cb) {
    setTimeout(() => cb(_themes), 10);
  },
  // loadBoxesSeif(cb) {
  //     setTimeout(() => cb(boxesSeif), 10);
  // },
  // denormalize,
};
