<template>
  <div class="editor">
    <fieldset class="controls">
      <input type="checkbox" id="draggable" v-model="draggable" />
      <label for="draggable">Déplaçable</label>
      <br />
      <input type="checkbox" id="resizable" v-model="resizable" />
      <label for="resizable">Redimensionnable</label>
      <br />
      <input type="checkbox" id="rotatable" v-model="rotatable" />
      <label for="resizable">Orientable</label>
      <br />
    </fieldset>
    <fieldset v-if="isBubble">
      <select
        class="select"
        v-model="fontFamily"
        :style="{ fontFamily: fontFamily }"
      >
        <option
          v-for="(font, index) in fonts"
          :key="index"
          :style="{ fontFamily: font }"
          :value="font"
        >
          {{ font }}
        </option>
      </select>
      <textarea
        v-model="content"
        class="textarea"
        name=""
        id=""
        placeholder="Texte de votre bulle"
        :style="{ fontFamily: fontFamily }"
      ></textarea>

      <!--
      <select v-model="fontFamily">
         <option
          v-for="(font, index) in fonts"
          :key="index"
          :value="font"
        >{{ font }}</option>
      </select>
      -->
      <br />
      <input type="color" id="color" v-model="color" />
      <!-- <label for="color">Couleur</label> -->
      <!-- <input type="checkbox" id="bold" v-model="bold" />
      <label for="bold">Gras</label>
      <input type="checkbox" id="italic" v-model="italic" />
      <label for="italic">Italique</label>
      <input type="checkbox" id="underline" v-model="underline" />
      <label for="underline">Souligné</label> -->
    </fieldset>
    <button class="ok-button" @click="unselectItem">Valider</button>
    <button class="danger-button" @click="deleteItem">Supprimer</button>
  </div>
</template>
<script>
export default {
  //name:"BubbleEditor",
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("staticResources/loadFonts");
  },
  methods: {
    deleteItem() {
      this.$store.dispatch("editors/deleteSelectedItem");
    },
    unselectItem() {
      this.$store.commit("editors/CLEAR_HIGHLIGHT");
      this.$store.commit("editors/CLEAR_ITEM_SELECTION");
    },
  },
  computed: {
    draggable: {
      get() {
        return this.selectedItem.controls.draggable;
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentSelectedControlsProperty", {
          property: "draggable",
          value: value,
        });
      },
    },
    resizable: {
      get() {
        return this.selectedItem.controls.resizable;
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentSelectedControlsProperty", {
          property: "resizable",
          value: value,
        });
      },
    },
    rotatable: {
      get() {
        return this.selectedItem.controls.rotatable;
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentSelectedControlsProperty", {
          property: "rotatable",
          value: value,
        });
      },
    },
    isBubble: {
      get() {
        return (
          this.$store.state.editors.currentSelectedBoxItem.type == "bubble"
        );
      },
    },
    fonts: {
      get() {
        return this.$store.state.staticResources.fonts;
      },
    },
    selectedItem: {
      get() {
        return this.$store.getters["editors/selectedBoxItem"];
      },
    },
    content: {
      get() {
        return this.selectedItem.content || "";
      },
      set(value) {
        this.$store.dispatch("editors/setBubbleTextContent", value);
      },
    },
    fontFamily: {
      get() {
        return this.selectedItem?.style.fontFamily ?? this.fonts[0];
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentBubbleStyleProperty", {
          property: "fontFamily",
          value: value,
        });
      },
    },
    bold: {
      get() {
        return this.selectedItem.style.fontWeight == "bold";
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentBubbleStyleProperty", {
          property: "fontWeight",
          value: value ? "bold" : "normal",
        });
      },
    },
    italic: {
      get() {
        return this.selectedItem.style.fontStyle == "italic";
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentBubbleStyleProperty", {
          property: "fontStyle",
          value: value ? "italic" : "normal",
        });
      },
    },
    underline: {
      get() {
        return this.selectedItem.style.textDecoration == "underline";
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentBubbleStyleProperty", {
          property: "textDecoration",
          value: value ? "underline" : "none",
        });
      },
    },
    color: {
      get() {
        return this.selectedItem.style.color;
      },
      set(value) {
        this.$store.dispatch("editors/setCurrentBubbleStyleProperty", {
          property: "color",
          value: value,
        });
      },
    },
  },
};
</script>
<style scoped>
.editor {
  padding: 40px;
}
.editor label,
.editor input {
  margin: 8px 10px 8px 0;
  font-size: 1em;
}
fieldset {
  width: initial;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.textarea {
  width: 95%;
  resize: none;
  max-height: 100px;
  border: 1px solid #888;
  padding: 3px;
  border-top: transparent;
}
.select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  background: #ddd;
  min-width: none;
}
.select option {
  background: #fff;
  line-height: 1.5em;
}
.controls,
label {
  afont-size: x-small;
}
select,
select option {
  afont-size: smaller;
  width: 95%;
}
</style>
