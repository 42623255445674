<template>
  <div class="component">
    <h1 class="h1">Choisissez une BD</h1>
    <div id="comic-selector">
      <div
        v-for="(comic, index) in comics"
        :key="index"
        class="comic-preview"
        @click="selectComic(comic.id)"
      >
        <box
          :box="
            boxes[comic.boxes[Math.floor(Math.random() * comic.boxes.length)]]
          "
          class="box"
          @click="selectComic(comic.id)"
        >
        </box>
        <p class="author">Dessin de {{ comic.author }}</p>
        <!-- <div class="action-buttons" v-if="comic.locked"> -->
          <div class="action-buttons" >
            <!-- <router-link :to="{name: 'print',params: { comicId: comic.id },}" class="ok-button">pdf</router-link> -->
            <br />
            <!-- <a class="ok-button" v-if="comic.locked" :href="comic.original" target="_blank"
              >Voir l'original</a
              >
          <button class="ok-button" v-else @click="selectComic(comic.id)">Éditez</button> -->

          <button class="ok-button" v-if="!comic.locked" @click="selectComic(comic.id)">Éditez</button>
          <button
            class="ok-button"
            @click="printComic(comic.id)"
            v-if="printable  && comic.title?.length > 0"
            :class="{'invisible' : !printable}"
          >
            Imprimer
          </button>
          <a class="ok-button" v-if="comic.locked" :href="comic.original" target="_blank"
          >Voir l'original</a
          >
        </div>
      </div>
    </div>
    <div class="text">
      Retrouvez la présentation et les outils du projet BDKREATOR : <a href="http://bdkreator.com/" target="_blank">http://bdkreator.com/</a>
    </div>
    <div class="text info">
      Pour retrouver l'accès à toutes les créations, <a href="/">relancer la session</a>
    </div>
  </div>
</template>
<script>
import box from "../components/Box.vue";
export default {
  components: {
    box,
  },
  data() {
    return {};
  },
  created() {
    this.$store.commit("editors/SET_CURRENT_SELECTED_COMIC_ID", -1);
  },
  methods: {
    selectComic(id) {
      // On vérifie que la bd n'est pas vérrouillée :
      if (!this.comics.find((c) => c.id === id).locked) {
        this.$store.commit("editors/SET_CURRENT_SELECTED_COMIC_ID", id);
        this.$router.push("Comic").catch(() => {}); // TODO : enlever cette horreur
      }
    },
    printComic(id) {
      this.$store.commit("editors/SET_CURRENT_SELECTED_COMIC_ID", id);

      this.$router.push({
        name: "print",
        params: { comicId: id },
      });
    },
  },
  computed: {
    comics() {
      return this.$store.getters["editors/currentThemeComics"];
    },
    boxes() {
      return this.$store.state.staticResources.boxes;
    },
    printable() {
      return this.$store.getters["applicationFlow/canPrint"];
    },
  },
};
</script>
<style scoped>
.component {
width: 100%;
}

h1 {
  text-align: center;
  margin: 10vh 0;
}
#comic-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.comic-preview {
  max-height: 40%;
  border: 3px solid transparent;
  margin: 5px;

  /*border: 3px solid black;*/
  padding: 30px 30px 15px 30px;
  background: #fff;
  box-shadow: 5px 5px 25px #00000044;
}
.author {
  margin: 15px 5px;
  height: 2em;
}

#comic-selector {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
#comic-selector .action-buttons {
  text-align: center;
}

#comic-selector .action-buttons > .ok-button {
  display: block;
  /* margin: 15px; */
  margin: 5px auto;
  text-decoration: none;
  width: 80%;
}

.comic-preview {
  max-width: 220px;
}

.box {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}
.text {
  width: fit-content;
  text-align: left;
  margin: 10px auto;
  
}

.invisible {
  visibility: hidden;
}
</style>
