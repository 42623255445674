<template>
  <div class="planche" ref="container">
    <div class="grid-container">
      <!-- TODO: add mouse and touch event -->
      <box
        v-for="(box, index) in boxes"
        :key="index"
        :box="box"
        :items="box.items"
        class="box"
        :style="[customStyle(index)]"
        v-on="$listeners"
      ></box>
      <span class="page-number">
        {{ layout.num + 1 }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Box from "./Box.vue";

/* S'ajuste en fonction de la taille du container */
export default {
  components: { Box },
  props: {
    boxes: {
      type: Array,
      default: () => new Array(),
    },
    layout: {
      type: Object,
      default: () => {
        return { size: { row: 0, cols: 0, nbItem: 0 }, index: 0, style: {} };
      },
    },
  },
  data() {
    return {
      // layouts: this.layouts,
      parentSize: {
        width: 0,
        height: 0,
      },
      defaultBoxSize: {
        width: 0,
        height: 0,
        property: "height",
      },
    };
  },

  created() {},
  mounted() {
    // TODO : debounce
    this.resizeObserver = new ResizeObserver(this.setContainerSize);
    this.resizeObserver.observe(this.$refs.container);

    this.$nextTick(() => {
      this.setContainerSize();
    });
  },
  methods: {
    isResizing(oldWidth, oldHeight, newWidth, newHeight, threshold = 5) {
      return (
        oldWidth - newWidth > threshold ||
        oldHeight - newHeight > threshold ||
        newWidth - oldWidth > threshold ||
        newHeight - oldHeight > threshold
      );
    },
    setContainerSize: function () {
      // TODO : put threshold (5) in var somewhere
      const threshold = 1;
      // console.debug("In setContainerSize");
      if (
        this.isResizing(
          this.parentSize.width,
          this.parentSize.height,
          this.$refs.container.clientWidth,
          this.$refs.container.clientHeight,
          threshold
        )
      ) {
        let isOverflowingWidth = () =>
          this.$refs.container.scrollWidth - this.$refs.container.clientWidth >
          1;

        this.parentSize.width = this.$refs.container.clientWidth;
        this.parentSize.height = this.$refs.container.clientHeight;
        let wOverFlow =
          this.$refs.container.scrollWidth - this.$refs.container.clientWidth;
        let hOverFlow =
          this.$refs.container.scrollHeight - this.$refs.container.clientHeight;
        if (isOverflowingWidth() && wOverFlow - hOverFlow > 1) {
          this.defaultBoxSize.width =
            this.parentSize.width / this.layout.size.cols;
          this.defaultBoxSize.property = "width";
        } else {
          this.defaultBoxSize.height =
            this.parentSize.height / this.layout.size.rows;
          this.defaultBoxSize.property = "height";
        }
      }
    },

    customStyle: function (boxIndex) {
      let data = {};
      if (this.layout.index == boxIndex) {
        data[this.defaultBoxSize.property] =
          this.defaultBoxSize[this.defaultBoxSize.property] * 2 + "px";
        Object.assign(data, this.layout.style);
      } else {
        data[this.defaultBoxSize.property] =
          this.defaultBoxSize[this.defaultBoxSize.property] + "px";
      }
      return data;
    },
  },

  computed: {
    // TODO : Move to props ---- BEGIN
    ...mapState("staticResources", {
      layouts: (state) => state.layouts,
    }),
    // TODO : Move to props ---- END
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  unmounted() {
    this.resizeObserver.disconnect();
  },
};
</script>
<style scoped>
.page-number {
  display: block;
  text-align: center;
  margin: 15px auto 0 auto;
  width: 100%;
  font-size: 0.6em;
  font-weight: bold;

  grid-column-end: span 3;
}
.grid-container {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr; */
  grid-template-columns: min-content min-content min-content;
  grid-template-rows: min-content min-content min-content;
  gap: 1px;
}
.planche {
  max-height: 100%;
  width: calc(100% - 10px);
  min-width: calc(100% - 10px);
  max-width: calc(100% - 10px);
}
</style>
