<template>
  <div>
    <section>
      <button
        :disabled="isGenerating"
        :class="{ disabled: isGenerating, 'cta-button': true }"
        @click="downloadPdf()"
      >
        {{ isGenerating ? "Chargement..." : "Imprimer" }}
      </button>

      <section class="progress-container">
        <div class="progress-bar">
          <div class="progress" :style="`width: ${progress}%;`" />
        </div>

        <section class="generating-label" v-if="progress !== 0">
          <span class="label-title">
            {{ progress === 100 ? "PDF généré" : "Génération du PDF" }}
          </span>

          <span class="label-progress">
            {{ `${progress} %` }}
          </span>
        </section>
      </section>
    </section>
    <section id="impression">
      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :filename="filename"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-content-width="contentWidth"
        :pdf-orientation="comic.aspectRatio > 1 ? 'landscape' : 'portrait'"
        @progress="onProgress($event)"
        @hasDownloaded="onGenerationEnd"
        ref="html2Pdf"
      >
        <!-- @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)" -->
        <section id="pages" slot="pdf-content">
          <section
            class="comic-print html2pdf__page-break"
            :class="{
              landscape: comic.aspectRatio > 1,
              portrait: comic.aspectRatio <= 1,
            }"
            v-for="(page, index) in comicLayout"
            :key="index"
          >
            <div id="title" v-if="index == 0">{{ comic.title }}</div>
            <BoxesGridMulti :boxes="page.boxes" :layout="page" class="page">
            </BoxesGridMulti>
            <div id="author" v-if="index == comicLayout.length - 1">
              {{ author }}
            </div>
          </section>
        </section>
      </vue-html2pdf>
    </section>
  </div>
</template>
<script>
import BoxesGridMulti from "../components/BoxesGridMulti.vue";
import { mapGetters } from "vuex";

// plugins/vue-html2pdf.js
import Vue from "vue";
import VueHtml2pdf from "vue-html2pdf";
Vue.use(VueHtml2pdf);

export default {
  name: "Print",
  components: {
    BoxesGridMulti,
  },
  data() {
    return {
      progress: 0,
    };
  },
  props: ["comicId"],
  created() {},
  mounted() {
    //this.$nextTick(this.downloadPdf);
  },
  computed: {
    // ...mapGetters("editors", {
    //   currentComicLayout: "currentComicLayout",
    //   currentComic: "currentComic",
    //   currentBoxId: "currentBoxId",
    //   currentComicTitle: "currentComicTitle",
    // }),
    ...mapGetters("editors", {
      comicLayout: "currentComicLayout",
      comic: "currentComic",
    //   currentBoxId: "currentBoxId",
    //   currentComicTitle: "currentComicTitle",
    }),

    ...mapGetters("applicationFlow", {
      author: "pseudo",
    }),
    // comic() {
    //   return this.$store.getters["editors/getComicById"](this.comicId);
    // },
    // comicLayout() {
    //   return this.$store.getters["editors/getComicLayoutByComicId"](
    //     this.comicId
    //   );
    // },
    isGenerating() {
      return this.progress !== 0 && this.progress !== 100;
    },
    contentWidth() {
      return this.comic.aspectRatio > 1 ? "257mm" : "170mm";
    },
    filename() {
      return this.comic.title != "" && this.comic.title != undefined
        ? this.comic.title
        : "bd";
    },
  },
  methods: {
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`);
    },
    onGenerationEnd() {
      this.$router.push({ name: "ComicSelector" });
    },
  },
};
</script>
<style>
html,
body,
.comic-print {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.comic-print #title {
  position: absolute;
  width: inherit;
  text-align: center;
  top: 0.5cm;
}

#author {
  /* position: absolute; */
  width: 100%;
  text-align: right;
  margin: -0.6cm;
}

.comic-print.landscape {
  margin: 1.5cm auto auto calc(2cm);
}

.comic-print.portrait {
  margin: 2.5cm auto auto calc(2cm);
}

#pages {
  width: 100%;
  height: 100%;
}

#impression {
  width: 297mm;
}

.planche {
  width: 100%;
  height: 100%;
  position: relative;
}

.planche .grid-container {
  position: relative;
}

#impression .layout-container .planche .grid-container {
  left: 0px;
  padding: 0px;
}
</style>
<style scoped>
/* */
.progress-container {
  width: 100%;
}
.progress-container .progress-bar {
  width: 100%;
  height: 10px;
  border: 1px solid #585858;
  border-radius: 5px;
  margin-bottom: 5px;
}
.progress-container .progress-bar .progress {
  width: 0%;
  height: 100%;
  transition: 0.3s;
  background: #2c3e50;
}
.progress-container .generating-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
