import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

import UsersAdministration from "@/views/UsersAdministration";
import SessionsAdministration from "@/views/SessionsAdministration";

import ComicBoxesOrder from "../views/ComicBoxesOrder";
import BoxEditor from "../views/BoxEditor";

import BoxesGridMulti from "../components/BoxesGridMulti";
import Box from "../components/Box";
import ComicSelector from "../views/ComicSelector";
import ThemeSelector from "../views/ThemeSelector";
// import PrintComic from "../views/PrintComic";
import Print from "../views/Print";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/UsersAdministration",
    name: "UsersAdministration",
    component: UsersAdministration,
  },
  {
    path: "/SessionsAdministration",
    name: "SessionsAdministration",
    component: SessionsAdministration,
  },
  {
    path: "/ComicSelector",
    name: "ComicSelector",
    component: ComicSelector,
  },
  {
    path: "/ThemeSelector",
    name: "ThemeSelector",
    component: ThemeSelector,
  },
  {
    path: "/Comic",
    name: "ComicBoxesOrder",
    component: ComicBoxesOrder,
    beforeEnter: (to, from, next) => {
      const isComicSelected =
        router.app.$store.getters["editors/currentComicId"] != -1;
      if (!isComicSelected) {
        next({ name: "ComicSelector" });
      } else {
        next();
      }
    },
  },
  {
    path: "/BoxEditor",
    name: "BoxEditor",
    component: BoxEditor,
    //TODO : move inside component using befroreRouteEnter
    beforeEnter: (to, from, next) => {
      const isBoxSelected =
        router.app.$store.getters["editors/currentBoxId"] != -1;
      if (!isBoxSelected) {
        next({ name: "ComicBoxesOrder" });
      } else {
        next();
      }
    },
  },
  {
    path: "/box",
    name: "Box",
    component: Box,
  },
  {
    path: "/boxesGridMultli",
    name: "BoxesGridMulti",
    component: BoxesGridMulti,
  },
  {
    path: "/print/:comicId",
    // path: "/print",
    name: "print",
    component: Print,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Gestion de redirection en cas de "non connexion"

router.beforeEach((to, from, next) => {
  const isAuthenticated =
    router.app.$store.getters["applicationFlow/isLoggedIn"];
  const printable = router.app.$store.getters["applicationFlow/canPrint"];
  // const isAuthenticated = false;
  if (to.name !== "Home" && !isAuthenticated) next({ name: "Home" });
  if (to.name === "Home" && isAuthenticated) {
    const role = router.app.$store.getters["applicationFlow/role"];
    switch (role) {
      case "participant":
        next({ name: "ThemeSelector" });
        break;
      case "animateur":
        next({ name: "SessionsAdministration" });
        break;
      case "admin":
        next({ name: "UsersAdministration" });
        break;
      default:
        next();
    }
  }
  if (from.name == "ComicBoxesOrder" && to.name == "ComicSelector") { //TODO : refactor cette partie
    // console.log(from)
    // console.log(to);
    if(to.params.lock === false) { // On ne verrouille pas la BD => on autorise à revenir à la sélection, mais on ne montre pas le résultat final, et on ne peut pas imprimer
      next();
    }else {
      // console.debug("Hello here");
      const title = router.app.$store.getters["editors/currentComic"].title;
      // PAsser la BD à l'état terminée
      let msg = "Voulez vraiment terminer l'activité ? ";
      if (printable) {
        msg +=
          "Vous pourrez télécharger un pdf de votre BD, mais ne pourrez plus la modifier. ";
      }
      msg += "Vous aurez accès à la BD originale.";
      let ok = confirm(msg);
      // Modif pour Martine : en cas d'édition : titre obligatoire (31/05/2023)
      // if (
      //   ok &&
      //   printable &&
      //   (!title || title.lenght <= 0) &&
      //   !confirm(
      //     "Le titre de votre BD n'est pas renseigné, vous pouvez annuler pour le renseigner avant de terminer l'édition."
      //   )
      // ) {
      //   return;
      // }
      if (
        ok &&
        printable &&
        (!title || title.lenght <= 0) 
        ) {
        alert(
          "Le titre de votre BD n'est pas renseigné, vous devez le renseigner avant de terminer l'édition."
        )
        return;
      }
      if (ok) {
        const id = router.app.$store.getters["editors/currentComicId"];
        router.app.$store.commit("editors/LOCK_COMIC", id);
        // console.debug("Fin d'activité ");
        if (printable) {
          next({
            name: "print",
            params: { comicId: id },
          });
        } else {
          //next({ name: "ComicSelector" });
          next();
        }
      } else {
        return; // On reste sur la page.
      }

    }
    // console.debug("Activité terminé");
  }
  if (to.name === "print" && !printable) {
    return;
  }
  // if( to.path !==  from.path)
  next();
});

export default router;
