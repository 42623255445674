import Vue from "vue";
import Vuex from "vuex";
import editors from "./modules/editors";
import staticResources from "./modules/staticResources";
import applicationFlow from "./modules/applicationFlow";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    editors,
    staticResources,
    applicationFlow,
  },
});
