<template>
  <div class="component">
    <div v-if="uiCurrentState === 'participate'" id="participate">
      <div class="presentation">
        Bienvenue dans l'atelier de création de BD! <br />
        Nous vous proposons de tester l'atelier ou de l'utiliser pour votre classe, votre
        équipe, votre groupe. <br />
        Pour simplement tester l'application, choisissez la session DEMO. <br />
        Pour créer un compte et disposer de fonctionnalités plus avancées :
        <a href="http://bdkreator.com/" target="_blank">contactez-nous</a><br />
        Si vous avez déjà créé un compte, vous n'avez plus qu'à vous connecter en vous
        identifiant.
      </div>

      <h2 class="h2">Rejoindre une session</h2>
      <select name="" class="input" v-model="selectedSession">
        <option value="">-- Sélectionnez votre session --</option>
        <option :value="session.id" :key="session.id" v-for="session in openedSessions">
          {{ session.name }}
        </option>
      </select>
      <input
        class="input ml-1 m-2"
        type="text"
        v-model="sessionCode"
        placeholder="Clé de la session"
      />
      <input
        class="input ml-1 m-2"
        type="text"
        v-model="login"
        placeholder="Choisissez un pseudo"
        required
        @change="manageError"
      />
      <div class="error" v-if="errors.login && login.length == 0">
        Veuillez-saisir un pseudo
      </div>
      <button @click="join" class="button">Rejoindre !</button>
      <button @click="uiCurrentState = 'sigin'" class="text button">Se connecter</button>
    </div>

    <form v-else @submit.prevent="signin" action="" id="login">
      <h2 class="h2">Se connecter</h2>
      <input class="input mb-2" type="text" v-model="login" placeholder="Identifiant" />
      <input
        class="input mb-2"
        type="password"
        v-model="password"
        placeholder="Mot de passe"
      />
      <button class="button">Se connecter</button>
      <button @click.stop.prevent="uiCurrentState = 'participate'" class="text button">
        Je n'ai pas de compte, je veux juste participer.
      </button>
    </form>
  </div>
</template>

<script>
import API from "../api/api.js";
// import { mapGetters, mapActions } from "vuex";
export default {
  name: "LoginScreen",
  data: () => {
    return {
      selectedSession: "",
      sessionCode: "",
      login: "",
      password: "",
      uiCurrentState: "participate",
      openedSessions: [],
      errors: {
        login: false,
      },
    };
  },
  computed: {
    // ...mapGetters([""]),
  },
  created() {
    API.getCurrentSessions().then((sessions) => {
      this.openedSessions = sessions;
    });
  },
  methods: {
    // ...mapActions(["applicationFlow"]),
    join() {
      if (this.login.length == 0) {
        this.errors.login = true;
      } else {
        API.joinSession(this.selectedSession, this.sessionCode, this.login).then(
          (response) => {
            if (response.connected) {
              // console.log(response);
              this.$store.dispatch("applicationFlow/login", {
                login: this.login,
                role: response.role,
                canPrint: response.canPrint,
              });
              this.$router.push("ThemeSelector");
            }
          }
        );
      }
    },
    signin() {
      API.signin(this.login, this.password)
        .then((response) => {
          if (response.connected) {
            this.$store.dispatch("applicationFlow/login", {
              login: this.login,
              role: response.role,
            });
            if (response.role === "admin") {
              this.$router.push("UsersAdministration");
            }
            if (response.role === "animateur") {
              this.$router.push("SessionsAdministration");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    manageError() {
      if (this.login.length > 0) {
        this.errors.login = false;
      }
    },
  },
};
</script>

<style>
.component {
  margin: 10vh auto 0 auto;
  width: 600px;
}
.input {
  width: calc(100% - 40px);
  margin: 20px 0;
}
.text.button {
  background: transparent;
  margin: 0 40px;
  border: 0;
  box-shadow: none;
  color: #694fea;
  text-decoration: none;
}
.presentation {
  text-align: center;
  margin: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.error {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid red;
  margin-top: -10px;
  margin-bottom: 5px;
  max-width: fit-content;
  font-size: x-small;
}
</style>
