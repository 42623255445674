<template>
  <div id="comic-boxes">
    <div class="boxes-order" ref="boxes">
      <button
        :class="{ disabled: currentBoxId === -1, 'cta-button': true }"
        :disabled="currentBoxId === -1"
        @click="gotoBoxEditor()"
      >
        Modifier cette case
      </button>
      <span style="clear: both;"></span>
      <h2 class="titre2">Votre espace de travail</h2>
      <div id="comic-title-editor">
        <label for="comic-title-input">Titre de votre BD : </label>
        <input
          type="text"
          name="title"
          id="comic-title-input"
          v-model="title"
          placeholder="Entrez le titre de votre BD"
          size="50"
        />
      </div>
      <div class="user-info">Choisissez la case à modifier : déplacer en la faisant glisser, modifier d'un double clic/double tap</div>
      <draggable v-model="comicBoxes" class="boxes-list" chosenClass="">
        <box
          v-for="(box, index) in comicBoxes"
          :key="box.position"
          :box="box"
          :items="box.items"
          :customStyle="boxWidth(box.id)"
          :customClass="greyed(index)"
          @click="toogleBoxSelection(box.id)"
          @touchstart="toogleBoxSelection(box.id)"
          @dblclick="selectBoxAndGotoBoxEditor(box.id)"
          :class="{ highlight: box.id === currentBoxId }"
        ></box>
      </draggable>
    </div>
    <div class="layout-container" id="comic-preview">
      <div class="user-info">Voici votre résultat mis en planches</div>
      <div id="title" v-if="hasTitle">{{ title }}</div>
        <BoxesGridMulti
          v-for="(page, index) in currentComicLayout"
          :key="index"
          :boxes="page.boxes"
          :layout="page"
          class="page"
          :class="{
            landscape: currentComic.aspectRatio > 1,
            portrait: currentComic.aspectRatio <= 1,
          }"
          @click="cleanBoxSelection()"
        >
        </BoxesGridMulti>
        <div id="author">
            {{ author }}
        </div>
    </div>
    <div class=".clear"></div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import box from "../components/Box";
import BoxesGridMulti from "../components/BoxesGridMulti.vue";
import { mapGetters } from "vuex";
export default {
  name: "ComicBoxesOrder",
  components: {
    draggable,
    box,
    BoxesGridMulti,
  },
  data() {
    return {
      nbEltLine: 0,
      boxOrderWidth: 0,
      boxOrderHeight: 0,
    };
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.debug("Hello here")
  //   if(to.name === "ComicSelector") {
  //     // PAsser la BD à l'état terminée
  //     let ok = confirm("Voulez vraiment terminer l'activité ? Vous pourrez télécharger un pdf de votre BD, mais ne pourrez plus la modifier. Vous aurez accès à la BD originale.")
  //     if(ok) {
  //       console.debug("Fin d'activité ")
  //       next({
  //         name: "print",
  //         params: { comicId: this.$store.getters['editors/currentComicId'] },
  //       })
  //     } else {
  //       return ; // On reste sur la page.
  //     }
  //     console.debug("Activité terminé")
  //   }
  //   next();
  // },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.computeGrid);
    this.resizeObserver.observe(this.$refs.boxes);
    this.nbEltLine = this.nbCase;
    this.$nextTick(() => {
      this.computeGrid();
    });
  },
  computed: {
    comicBoxes: {
      get() {
        return this.$store.getters["editors/currentComicBoxesSorted"];
      },
      set(value) {
        // console.log("***** DragEnd value : ", value);
        this.$store.dispatch("editors/updateCurrentComicBoxesOrder", value);
      },
    },
    ...mapGetters("editors", {
      currentComicLayout: "currentComicLayout",
      currentComic: "currentComic",
      currentBoxId: "currentBoxId",
    }),
    ...mapGetters("applicationFlow", {
      author: "pseudo",
    }),
    nbCase() {
      return this.currentComicLayout
        .map((e) => e.size.nbItem)
        .reduce((a, b) => a + b, 0);
    },
    greyed() {
      return (index) => {
        const outOfBounds = index >= this.nbCase; // TODO : récupérer depuis le layout
        return outOfBounds ? "greyed" : "";
      };
    },
    boxWidth() {
      return (id) => {
        if (id === this.currentBoxId) {
          return { width: "calc(" + 85 / this.nbEltLine + "% + 60px)" };
        } else {
          return { width: 85 / this.nbEltLine + "%" };
        }
      };
    },
    title: {
      get() {
        return this.$store.getters["editors/currentComicTitle"];
      },
      set(value) {
        this.$store.commit("editors/updateCurrentComicTitle", value);
      },
    },
    hasTitle() {
      return this.title?.length > 0
    },
  },
  methods: {
    toogleBoxSelection(id) {
      if (id === this.currentBoxId) {
        this.selectBox(-1);
      } else {
        this.selectBox(id);
      }
    },
    selectBox(id) {
      this.$store.dispatch("editors/setCurrentBoxId", id);
    },
    gotoBoxEditor() {
      if (this.currentBoxId !== -1) {
        this.$router.push("BoxEditor");
      }
    },
    selectBoxAndGotoBoxEditor(id) {
      this.selectBox(id);
      this.$router.push("BoxEditor");
    },
    cleanBoxSelection() {
      this.$store.dispatch("editors/setCurrentBoxId", -1);
    },
    getBoxOrderSize() {
      const navHeight = 70; // TODO : prendre en compte la zone de saisie du titre de la bd
      this.boxOrderWidth = this.$refs.boxes.clientWidth;
      // check for padding and margin to remove ?
      const pTop = parseFloat(
        window
          .getComputedStyle(this.$refs.boxes)
          .getPropertyValue("padding-top")
          .slice(0, -2)
      );
      const titleZoneHeight = this.$refs.boxes.firstElementChild.offsetHeight;
      this.boxOrderHeight =
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) -
        navHeight -
        titleZoneHeight -
        pTop;
    },
    computeGrid() {
      this.getBoxOrderSize();
      const viewRatio = this.boxOrderWidth / this.boxOrderHeight;
      const nbCases = this.currentComic.boxes.length;
      let nbEltLine = 0;
      let totalHeight = 0;
      let eltWidth = 90 / nbEltLine;
      // console.debug(
      //   "title zone height: ",
      //   this.$refs.boxes.firstElementChild.clientHeight
      // );
      do {
        nbEltLine++;
        eltWidth = 90 / nbEltLine;
        let eltHeight = eltWidth / this.currentComic.aspectRatio;
        let nbLines = Math.ceil(nbCases / nbEltLine);
        totalHeight = nbLines * eltHeight;
      } while (totalHeight > 100 / viewRatio);
      this.nbEltLine = nbEltLine;
    },
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  unmounted() {
    this.resizeObserver.disconnect();
  },
};
</script>
<style>
.box.highlight {
  /*filter: drop-shadow(0 0 1.5em yellow);*/
  /*border-radius: 10%;*/
  box-shadow: -10px 10px 55px #00000099, -1px 1px 20px #000000ff;
  position: relative;
  z-index: 9;
  margin: -30px;
  transition: all 0.3s ease;
  border: 1px solid #000;
  transform: rotate(-5deg);
  /*width: calc(18% + 30px)*/
}
.box {
  transition: all 0.1s ease;
}
#comic-boxes {
  display: flex;
  justify-content: space-between;
}
.boxes-order {
  /* width: 70%; */
  width: calc(70% - 100px);
  /* height: 80vh; */
  height: fit-content;
  display: inline-block;
  padding: 30px;
  position: fixed;
}
.boxes-list {
  max-width: 100%;
  width: 100%;
  /* flex-direction: row;  */
  display: flex;
  flex-wrap: wrap;
  /* float: left; */
  justify-content: space-evenly;
  gap: 5px;
}
.boxes-list .box {
  /*     
    min-width: 8%;
    max-width: 10%; */
}

#comic-preview {
  position: absolute;
  right: 0px;
}

.layout-container {
  max-height: 100%;
  width: 25%;
  /* width: 40vw; */
  /* height: 60vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 0 20px 0 65px;
  min-height: calc(100vh - 80px);
  /* overflow-y: scroll; */
  /* position: fixed; */
  /* right: 0px; */
}
.page {
  width: min-content;
  height: min-content;
  width: 100%;
}

.layout-container .planche .grid-container {
  border: 3px solid transparent;
  width: 100%;
  margin: 5px;
  padding: 30px 30px 15px 30px;
  background: #fff;
  box-shadow: 5px 5px 25px #00000044;
  margin: 5px;
  margin-bottom: 25px;
  position: relative;
}

#comic-preview {
  float: right;
}

#comic-preview.layout-container .planche .grid-container {
  left: -60px;
}
.greyed {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

#comic-title-editor {
  padding: 5px 10px 5px 10px;
  atext-align: center;
}

.user-info {
  padding: 10px;
}

#comic-preview .user-info {
  text-align: center;
}
.titre2 {
  text-align: center;
  margin: 3px;
}

.boxes-order .cta-button {
  position: relative !important;
  float: right;
  top: 0px; 
  left: 0px;
}

#comic-preview #title {
  /* position: absolute;
  top: 47px; 
  position: relative !important;
  */
  margin-bottom: -25px;
  width: 80%;
  text-align: center;
  z-index: 10
}

#comic-preview #author {
  /* position: absolute; */
  width: 100%;
  text-align: right;
  margin: -0.6cm;
  margin-top: -47px;
  z-index: 10;
}


</style>
